import { connect } from "react-redux"
import Routes from './Routes'
import { authRequest } from '../redux/actions/auth'
import { logoutRequest, resetSessionExpired } from '../redux/actions/login'

const mapStateToProps = state => ({
  auth: state.auth.auth,
  account: state.auth.account,
  session_expired: state.login.session_expired
  // authentication: state.login.authentication,
  // loading: state.login.loading,
})

const mapDispatchToProps = {
  authRequest,
  logoutRequest,
  resetSessionExpired
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
