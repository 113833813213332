import React, { PureComponent } from "react";
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  OverlayTrigger,
  Tooltip,
  Card,
  Image,
  Spinner,
} from "react-bootstrap";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import Preregistration from "../Preregistration";
import logo from "../../images/logo_fiui_full.png";
import { IoIosEye } from "react-icons/io";
import smallLogo from "../../images/Mid-Chains-Logo-Symbol.png";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

class Register extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      emailError: "",
      passwordError: "",
      params: {
        first: "",
        last: "",
        email: "",
        password: "",
        role: "",
        phone_number: "",
      },
      roleDescription: "",
      roleList: {
        data: [],
        loaded: false,
        loading: false,
        error: "",
      },
      showPassword: false,
      phoneNumberError: false,
    };
  }

  componentDidMount() {
    this.props.resetRegistrationFields();
    this.props.getRoleListRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        roleList: nextProps.roleList,
      });
    }
    if (
      this.props.applyingAs !== nextProps.applyingAs &&
      nextProps.roleList.loaded
    ) {
      this.handleSelectDefaultRole(nextProps);
    }
  }

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;

    if (name === "role") {
      const { data } = this.state.roleList;
      for (let i = 0; i < data.length; i++) {
        if (value === "") {
          this.setState({
            roleDescription: "",
          });
        }

        if (data[i]._id === value) {
          this.setState({
            roleDescription: data[i].description,
          });
        }
      }
    }

    this.setState({
      params: {
        ...this.state.params,
        [name]: value,
      },
    });
  };

  handleChangePhoneNumber = (e) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          phone_number: e,
        },
      },
      () => this.checkPhoneNumberFormat()
    );
  };

  handleSelectDefaultRole = (nextProps) => {
    if (nextProps.roleList.loaded && nextProps.applyingAs) {
      const { data } = nextProps.roleList;
      let defaultRole = { name: "" };
      if (nextProps.applyingAs === "individual") {
        for (let i = 0; i < data.length; i++) {
          if (data[i].name == "Individual") {
            defaultRole = data[i];
          }
        }

        // Simple signon
        if(!defaultRole.name) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].name == "Retail Tier1") {
              defaultRole = data[i];
            }
          }
        }

      } else if (nextProps.applyingAs === "entity") {
        for (let i = 0; i < data.length; i++) {
          if (data[i].name == "Institutional - General Role") {
            defaultRole = data[i];
          }
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].name === defaultRole.name) {
          this.setState({
            roleDescription: data[i].description,
          });
        }
      }

      this.setState({
        params: {
          ...this.state.params,
          role: defaultRole._id,
          role_name: defaultRole.name,
        },
      });
    }
  };

  handleRegister = () => {
    this.props.registerRequest(this.state.params);
  };

  /****************************************************************************/

  renderRole = (role) => {
    return (
      <option key={role.id} value={role._id}>
        {role.name}
      </option>
    );
  };

  renderDescriptionIcon = () => {
    const { roleDescription } = this.state;

    return (
      <OverlayTrigger
        key="role-description"
        placement="top"
        overlay={<Tooltip>{roleDescription}</Tooltip>}
      >
        <AiOutlineQuestionCircle className="individual-form-icon password-format-icon" />
      </OverlayTrigger>
    );
  };

  renderLogo = () => (
    <div className="login-logo-row">
      <div className="login-logo-box">
        <img src={logo} className="login-logo" />
      </div>
    </div>
  );

  checkEmailFormat = () => {
    this.setState({ emailError: !EMAIL_REGEX.test(this.state.params.email) });
  };

  checkPhoneNumberFormat = () => {
    const { phone_number } = this.state.params;
    if (phone_number) {
      console.log(isValidPhoneNumber(phone_number));
      if (isValidPhoneNumber(phone_number) == false) {
        this.setState({ phoneNumberError: true });
      } else {
        this.setState({ phoneNumberError: false });
      }
    }
  };

  checkPasswordFormat = () => {
    // password must be at least 8 characters long and contain at least 1 number and 1 letter
    let passwordError = false;
    if (
      !/\d/.test(this.state.params.password) ||
      !/[a-zA-Z]/.test(this.state.params.password) ||
      this.state.params.password.length < 8
    ) {
      passwordError = true;
    }

    this.setState({
      passwordError: passwordError,
    });
  };

  renderPasswordFormatIcon = () => {
    return (
      <OverlayTrigger
        key="role-description"
        placement="top"
        overlay={
          <Tooltip>
            Your password must be a minimum of eight characters long and contain
            at least one letter and one number.
          </Tooltip>
        }
      >
        <AiOutlineQuestionCircle className="individual-form-icon password-format-icon" />
      </OverlayTrigger>
    );
  };

  renderRegistration = () => {
    const { roleList, roleDescription, params, phoneNumberError } = this.state;
    const { first, last, email, password, role, phone_number } = params;
    const disabled =
      first && last && email && password && phone_number && !phoneNumberError
        ? false
        : true;

    let selectOptions;
    if (roleList.loaded) {
      selectOptions = roleList.data.map((role) => this.renderRole(role));
    } else if (roleList.loading) {
      selectOptions = this.renderLoading();
    } else {
      selectOptions = "";
    }

    let descriptionIcon;
    if (roleDescription) {
      descriptionIcon = this.renderDescriptionIcon();
    }

    let phoneNumberClassName = "login-formcontrol";
    if (phoneNumberError && phone_number) {
      phoneNumberClassName = "login-formcontrol-error";
    }

    return (
      <Card className="login-box">
        <Col className="login-box-col">
          <h1>Sign Up</h1>

          <FormControl
            size="sm"
            name="first"
            value={first}
            placeholder="First Name"
            onChange={this.handleOnChange}
            className="login-formcontrol"
          />

          <FormControl
            size="sm"
            name="last"
            value={last}
            placeholder="Last Name"
            onChange={this.handleOnChange}
            className="login-formcontrol"
          />

          <FormControl
            size="sm"
            name="email"
            value={email}
            placeholder="Email"
            onBlur={this.checkEmailFormat}
            onChange={this.handleOnChange}
            className="login-formcontrol"
          />

          <PhoneInput
            placeholder="Phone Number"
            value={phone_number}
            onChange={this.handleChangePhoneNumber}
            className={phoneNumberClassName}
          />

          <div className="login-form-div">
            <FormControl
              size="sm"
              name="password"
              type={this.state.showPassword ? "" : "password"}
              value={password}
              placeholder="Password"
              onChange={this.handleOnChange}
              onBlur={this.checkPasswordFormat}
              className="login-formcontrol password-toggler"
            />
            <IoIosEye
              onClick={this.toggleShowPassword}
              className="password-toggle-icon"
              id={this.state.showPassword ? "on" : "off"}
            />
          </div>

          <Row noGutters className="login-button-row">
            <Button
              size="sm"
              disabled={disabled}
              onClick={this.handleRegister}
              className="teal-button"
            >
              Sign Up
            </Button>
          </Row>
        </Col>
      </Card>
    );
  };

  // <Row noGutters className='login-formcontrol-container'>
  //   <div className='login-formcontrol-box'>
  //     <div className='login-formcontrol-text'>
  //       Select a role
  //       {descriptionIcon}
  //     </div>
  //     <FormControl
  //       size='sm'
  //       as='select'
  //       name='role'
  //       value={role}
  //       onChange={this.handleOnChange}
  //       className='login-formcontrol'>
  //       <option value=''>Role...</option>
  //       {selectOptions}
  //     </FormControl>
  //   </div>
  // </Row>

  renderLoading = () => (
    <Row noGutters className="app-spinner">
      <Spinner animation="border" className="common-grey-spinner" />
    </Row>
  );

  renderCannotRegister = () => {
    return (
      <Card className="login-box">
        <Col className="login-box-col">
          <h1>You are not eligible to register</h1>
        </Col>
      </Card>
    );
  };

  renderPreregistration = () => {
    const { preregistration } = this.props;

    let content;
    if (preregistration === "pass") {
      content = this.renderRegistration();
    } else if (preregistration === "fail") {
      content = this.renderCannotRegister();
    } else {
      content = <Preregistration />;
    }

    return content;
  };

  render() {
    return (
      <div className="content-div">
        <header className="login-container-top-row" id="header">
          <Link to={"/"}>
            <Image src={smallLogo} className="login-small-logo-top" />
          </Link>

          <Link to="/login">
            <Button className="btnClearSpecial btnSize3">Sign In</Button>
          </Link>
        </header>

        <div id="content">
          <section noGutters className="prereg-section">
            {this.renderPreregistration()}
          </section>
        </div>
      </div>
    );
  }
}

export default Register;
