import * as CONSTANTS from '../constants/auth';
import * as REGISTER_CONSTANTS from '../constants/register'

const initialState = {
  auth: {
    status: false,
    loading: false,
    error: '',
  },
  account: {
    data: {},
    loading: false,
    loaded: false,
    error: '',
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_CONSTANTS.REGISTER_REQUEST:
      return {
        ...state,
        account: {
          ...state.account,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.AUTH_REQUEST:
      return {
        ...state,
        auth: {
          ...state.auth,
          status: false,
          loading: true,
          error: '',
        },

        account: {
          ...state.account,
          data: {},
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.AUTH_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          status: true,
          loading: false,
        },

        account: {
          ...state.account,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.AUTH_ERROR:
      return {
        ...state,
        auth: {
          ...state.auth,
          status: false,
          loading: false,
          error: action.error
        }
      }

    default:
      return state
  }
}
