import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Container,
  Card,
  Image,
  Button,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import smallLogo from "../../images/Mid-Chains-Logo-Symbol.png";
import { baseUrl } from "../../config";

export default class Approved extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.getNavbarLinksRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    const prevLoading = prevProps.navbarLinks?.loading;
    const { loaded } = this.props.navbarLinks;
    if(prevLoading === true && loaded == true) {
      if(this.props.account?.data?.tier === "2" ) {
        if (baseUrl.includes("mid-r-onboarding")) {
          window.location.replace(
            "https://mid-r-trade.fi-edition.com/"
          )
        }
      }
    }
  }

  renderTradeLinkBtn = () => {

    const { data, loaded, loading } = this.props.navbarLinks;
    
    let tradeLinkBtn = (
      <a href="https://trade.ousoul.com/">
        <Button
          className="special-button"
          style={{
            width: "100%",
            marginBottom: "64px",
            fontSize: "22px",
            fontWeight: "bold",
          }}
        >
          Trade
        </Button>
      </a>
    );

    if (loaded) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].name == "Trading Portal") {
          tradeLinkBtn = (
            <a href={data[i].url}>
              <Button
                className="special-button"
                style={{
                  width: "100%",
                  marginBottom: "64px",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                Trade
              </Button>
            </a>
          );
        }
      }
    } else if (loading) {
      tradeLinkBtn = (
        <Button
          className="special-button"
          style={{
            width: "100%",
            marginBottom: "64px",
            fontSize: "22px",
            fontWeight: "bold",
          }}
        >
          <Spinner animation="border" className="teal-spinner" />
        </Button>
      );
    }

    return tradeLinkBtn;
  };

  render() {
    return (
      <Container fluid className="login-container">
        <Link to={"/"}>
          <Image src={smallLogo} className="login-small-logo" />
        </Link>

        <Button
          className="teal-button"
          onClick={this.props.logoutRequest}
          style={{
            position: "absolute",
            top: "24px",
            right: "16px",
            fontSize: "13px",
          }}
        >
          Logout
        </Button>

        <Row noGutters className="login-row" style={{ height: "100%" }}>
          <Card className="login-box">
            <Col className="login-box-col">
              <h1>You've been approved! Head to the Trading platform below.</h1>
              {this.renderTradeLinkBtn()}
            </Col>
          </Card>
        </Row>
      </Container>
    );
  }
}
