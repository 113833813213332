import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { history } from "../../../configureStore";
import request from "../../../apiRequest";
import * as ACTIONS from "../../actions/register";
import * as CONSTANTS from "../../constants/register";
import * as AUTH_ACTIONS from "../../actions/auth";

function* register(action) {
	try {
		const body = action.data;
		const responseData = yield call(
			request,
			"/account/register",
			"POST",
			body
		);

		yield put(AUTH_ACTIONS.authSuccess(responseData.data));

		if (body.role === "admin") {
			history.push("/admin");
		} else {
			history.push("/confirm");
		}

		window.location.href = window.location.href;
	} catch (err) {
		toast.error(err.reason);
	}
}

function* getRoleList(action) {
	try {
		const responseData = yield call(request, "/account/roleList", "GET");

		yield put(ACTIONS.getRoleListSuccess(responseData));
	} catch (err) {
		toast.error(err.reason);
		yield put(ACTIONS.getRoleListError(err));
	}
}

function* getCheckList(action) {
	try {
		const { fullForms } = action.accountInfo;
		const responseData = yield call(
			request,
			`/user/checkList${fullForms ? "?fullForms=" + fullForms : ""}`,
			"GET"
		);

		// this determines what is the first selected form when a user refreshes the page
		let currentForm;
		// if the user's account hasn't confirmed their email, set the current form to 'confirm'
		if (!action.accountInfo.emailConfirmed) {
			currentForm = "confirm";
			yield put(ACTIONS.updateCurrentForm(currentForm));
		} else {
			// if the user has already confirmed their email, check the list of forms and determine
			// which earliest form has a status of 'pending', then set that form to the current form
			for (let i = 0; i < responseData.length; i++) {
				if (responseData[i].status == "pending") {
					yield put(
						ACTIONS.getSpecificUserFormRequest(responseData[i]._id)
					);
					break;
				}
			}
		}

		yield put(ACTIONS.getCheckListSuccess(responseData));
	} catch (err) {
		if (err.reason == "You do not have permission to do this") {
			return;
		}
		toast.error(err.reason);
	}
}

function* deleteAccount(action) {
	try {
		const body = { _id: action.id };
		const responseData = yield call(
			request,
			"/account/delete",
			"POST",
			body
		);
	} catch (err) {
		toast.error(err.reason);
	}
}

function* getSpecificUserForm(action) {
	try {
		const { id } = action;
		const responseData = yield call(
			request,
			`/user/submission?_id=${id}`,
			"GET"
		);

		yield put(ACTIONS.getSpecificUserFormSuccess(responseData));
	} catch (err) {
		toast.error(err.reason);
	}
}

function* editSpecificUserForm(action) {
	try {
		for (let i = 0; i < action.data.fields.length; i++) {
			if (
				action.data.fields[i].fieldType == "date" &&
				action.data.fields[i].value
			) {
				let date = new Date(action.data.fields[i].value);
				let dateString =
					date.getFullYear() +
					"-" +
					(date.getMonth() + 1 + "").padStart(2, "0") +
					"-" +
					(date.getDate() + "").padStart(2, "0");
				action.data.fields[i].value = dateString;
			}
		}
		const body = { submission: JSON.stringify(action.data) };

		const responseData = yield call(request, "/user/edit", "POST", body);

		yield put(ACTIONS.editSpecificUserFormSuccess());
	} catch (err) {
		toast.error(err.reason);
		yield put(ACTIONS.editSpecificUserFormError(err));
	}
}

function* submitSpecificUserForm(action) {
	try {
		const body = { _id: action.id };
		const responseData = yield call(request, "/user/submit", "POST", body);
		yield put(ACTIONS.getCheckListRequest(action.accountData));
		yield put(ACTIONS.submitSpecificUserFormSuccess());
	} catch (err) {
		toast.error(err.reason);
		yield put(ACTIONS.submitSpecificUserFormError(err));
	}
}

function* uploadFile(action) {
	try {
		const {
			fileData,
			formData,
			fieldIndex,
			subfieldDataIndex,
			subfieldIndex,
		} = action;
		let newFileData = new FormData();
		newFileData.append("file", fileData);

		const responseData = yield call(
			request,
			"/user/uploadFile",
			"POST",
			newFileData,
			false,
			true
		);

		const fileUrl = responseData.url;
		if (subfieldDataIndex >= 0) {
			formData.fields[fieldIndex].subfields[subfieldIndex].data[
				subfieldDataIndex
			].value = fileUrl;
		} else {
			formData.fields[fieldIndex].value = fileUrl;
		}

		yield put(ACTIONS.editSpecificUserFormRequest(formData));
	} catch (err) {
		toast.error(err.reason);
		yield put(ACTIONS.registrationUploadFileError(err));
	}
}

function* deleteUploadedFile(action) {
	try {
		const { url, fieldIndex, formData, subfieldDataIndex, subfieldIndex } =
			action;
		const body = {
			url: url,
		};
		const responseData = yield call(
			request,
			"/user/deleteFile",
			"POST",
			body
		);

		if (subfieldDataIndex >= 0) {
			formData.fields[fieldIndex].subfields[subfieldIndex].data[
				subfieldDataIndex
			].value = "";
		} else {
			formData.fields[fieldIndex].value = "";
		}
		yield put(ACTIONS.deleteUploadedFileSuccess(formData));
		yield put(ACTIONS.editSpecificUserFormRequest(formData));
	} catch (err) {
		toast.error(err.reason);
		if (err.reason == "File not found") {
			const {
				url,
				fieldIndex,
				formData,
				subfieldDataIndex,
				subfieldIndex,
			} = action;
			if (subfieldDataIndex >= 0) {
				formData.fields[fieldIndex].subfields[subfieldIndex].data[
					subfieldDataIndex
				].value = "";
			} else {
				formData.fields[fieldIndex].value = "";
			}

			yield put(ACTIONS.deleteUploadedFileSuccess(formData));
		}

		yield put(ACTIONS.deleteUploadedFileError(err));
	}
}

function* sendWelcomeEmail(action) {
	try {
		const responseData = yield call(request, "/user/welcome", "GET");
	} catch (err) {
		toast.error(err.reason);
	}
}

export default function* registerSaga() {
	yield takeLatest(CONSTANTS.REGISTER_REQUEST, register);
	yield takeLatest(CONSTANTS.GET_ROLE_LIST_REQUEST, getRoleList);
	yield takeLatest(CONSTANTS.DELETE_ACCOUNT_REQUEST, deleteAccount);
	yield takeLatest(CONSTANTS.GET_CHECK_LIST_REQUEST, getCheckList);
	yield takeLatest(
		CONSTANTS.GET_SPECIFIC_USER_FORM_REQUEST,
		getSpecificUserForm
	);
	yield takeLatest(
		CONSTANTS.EDIT_SPECIFIC_USER_FORM_REQUEST,
		editSpecificUserForm
	);
	yield takeLatest(
		CONSTANTS.SUBMIT_SPECIFIC_USER_FORM_REQUEST,
		submitSpecificUserForm
	);
	yield takeLatest(CONSTANTS.REGISTRATION_UPLOAD_FILE_REQUEST, uploadFile);
	yield takeLatest(
		CONSTANTS.DELETE_UPLOADED_FILE_REQUEST,
		deleteUploadedFile
	);
	yield takeLatest(CONSTANTS.SEND_WELCOME_EMAIL, sendWelcomeEmail);
}
