import React, { PureComponent } from "react";
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  OverlayTrigger,
  Tooltip,
  Form,
  Card,
  Image,
} from "react-bootstrap";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import privacyStatementDocument from "./privacy-statement.DOCX.pdf";
import logo from "../../images/logo_fiui_full.png";

class Preregistration extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      applyingAs: "",
      individualUserAnswers: {
        answer1: "",
        answer2: "",
        answer3: "",
      },
      individualRequiredAnswers: {
        answer1: 1,
        answer2: 2,
        answer3: 1,
      },
      entityUserAnswers: {
        answer1: "",
        answer2: "",
        answer3: "",
      },
      entityRequiredAnswers: {
        answer1: 1,
        answer2: 1,
        answer3: 2,
      },
      captcha: false,
      privacyStatement: false,
    };
  }

  componentDidMount() {}

  handleAnswerStarterQuestion = (e) => {
    this.setState({
      applyingAs: e,
    });
  };

  handleAnswerIndividualQuestions = (e, answer) => {
    const { name } = e.target;
    this.setState({
      individualUserAnswers: {
        ...this.state.individualUserAnswers,
        [name]: answer,
      },
    });
  };

  handleAnswerEntityQuestions = (e, answer) => {
    const { name } = e.target;
    this.setState({
      entityUserAnswers: {
        ...this.state.entityUserAnswers,
        [name]: answer,
      },
    });
  };

  handleAnswerPrivacyStatement = (value) => {
    this.setState({
      privacyStatement: value,
    });
  };

  onChangeCaptcha = (value) => {
    if (value) {
      this.setState({ captcha: true });
    } else {
      this.setState({ captcha: false });
    }
  };

  handleCheckIndividualAnswers = () => {
    const { answer1, answer2, answer3 } = this.state.individualUserAnswers;
    if (answer1 === "no" || answer2 === "yes" || answer3 === "yes") {
      // they failed
      this.props.submitPreregistration("fail");
    } else {
      // they continue to register
      this.props.submitPreregistration("pass", "individual");
    }
  };

  handleCheckEntityAnswers = () => {
    const { answer1, answer2, answer3 } = this.state.entityUserAnswers;
    if (answer1 === "no" || answer2 === "oneOrMore" || answer3 === "yes") {
      // they failed
      this.props.submitPreregistration("fail");
    } else {
      // they continue to register
      this.props.submitPreregistration("pass", "entity");
    }
  };

  openPrivacyStatement = () => {
    window.open(privacyStatementDocument);
  };

  /****************************************************************************/

  renderStarterQuestions = () => {
    const { applyingAs } = this.state;

    const individualChecked = applyingAs === "individual" ? true : false;
    const entityChecked = applyingAs === "entity" ? true : false;

    return (
      <div className="prereg-form-group">
        <h3 className="prereg-h3 marg_t2">Are you applying as an:</h3>

        <Button
          onClick={() => this.handleAnswerStarterQuestion("individual")}
          className="prereg-check-box"
          id={individualChecked && "checked"}
        >
          <div className="new-radio" />
          Individual
        </Button>

        <Button
          onClick={() => this.handleAnswerStarterQuestion("entity")}
          className="prereg-check-box"
          id={entityChecked && "checked"}
        >
          <div className="new-radio" />
          Entity*
        </Button>

        <h6 className="sub-text center">
          * <b>Entity Types: </b>Body Corporate, General Partnership, Sovereign
          Wealth Fund, Pension Fund, Trust, Foundation.
        </h6>
      </div>
    );
  };

  renderIndividualQuestions = () => {
    const { answer1, answer2, answer3 } = this.state.individualUserAnswers;

    return (
      <React.Fragment>
        <h3 className="prereg-h3">Individual</h3>
        <Form.Group className="prereg-form-group marg_t3">
          <p className="center">Are you at least 18 years of age?</p>

          <Row noGutters className="prereg-row" id="checkbox-row">
            <div id="shortbox">
              <Form.Check
                label="Yes"
                className="prereg-radio"
                onClick={(e) => this.handleAnswerIndividualQuestions(e, "yes")}
                name="answer1"
                checked={answer1 === "yes" ? true : false}
                type="radio"
              />
              <Form.Check
                label="No"
                className="prereg-radio"
                onClick={(e) => this.handleAnswerIndividualQuestions(e, "no")}
                name="answer1"
                checked={answer1 === "no" ? true : false}
                type="radio"
              />
            </div>
          </Row>

          <p className="center">
            Have you been convicted or found guilty by any court of competent
            jurisdiction in respect of any offence, other than a minor road
            traffic offence?
          </p>

          <Row noGutters className="prereg-row" id="checkbox-row">
            <div id="shortbox">
              <Form.Check
                label="Yes"
                className="prereg-radio"
                onClick={(e) => this.handleAnswerIndividualQuestions(e, "yes")}
                name="answer2"
                checked={answer2 === "yes" ? true : false}
                type="radio"
              />
              <Form.Check
                label="No"
                className="prereg-radio"
                onClick={(e) => this.handleAnswerIndividualQuestions(e, "no")}
                name="answer2"
                checked={answer2 === "no" ? true : false}
                type="radio"
              />
            </div>
          </Row>
        </Form.Group>

        <Form.Group className="prereg-form-group">
          <p className="center">
            Have you been declared bankrupt or made subject to any similar
            insolvency proceeding which has not been discharged?
          </p>

          <Row noGutters className="prereg-row" id="checkbox-row">
            <div id="shortbox">
              <Form.Check
                label="Yes"
                className="prereg-radio"
                onClick={(e) => this.handleAnswerIndividualQuestions(e, "yes")}
                name="answer3"
                checked={answer3 === "yes" ? true : false}
                type="radio"
              />
              <Form.Check
                label="No"
                className="prereg-radio"
                onClick={(e) => this.handleAnswerIndividualQuestions(e, "no")}
                name="answer3"
                checked={answer3 === "no" ? true : false}
                type="radio"
              />
            </div>
          </Row>
        </Form.Group>
      </React.Fragment>
    );
  };

  renderEntityQuestions = () => {
    const { answer1, answer2, answer3 } = this.state.entityUserAnswers;

    return (
      <React.Fragment>
        <h3 className="prereg-h3">Entity</h3>
        <Form.Group className="prereg-form-group marg_t3">
          <p className="center">
            Are you authorized to act on behalf of the applicant in a formal
            capacity (e.g. by Board resolution)?
          </p>

          <Row noGutters className="prereg-row" id="checkbox-row">
            <div id="shortbox">
              <Form.Check
                label="Yes"
                className="prereg-radio"
                onClick={(e) => this.handleAnswerEntityQuestions(e, "yes")}
                name="answer1"
                checked={answer1 === "yes" ? true : false}
                type="radio"
              />
              <Form.Check
                label="No"
                className="prereg-radio"
                onClick={(e) => this.handleAnswerEntityQuestions(e, "no")}
                name="answer1"
                checked={answer1 === "no" ? true : false}
                type="radio"
              />
            </div>
          </Row>

          <p className="center">
            Please confirm how the following statements apply to the entity you
            are acting on behalf of:
          </p>
        </Form.Group>

        <div className="scrollHolder">
          <div className="scrollHolder-content">
            <p>
              The applicant or any member of its group has been made aware,
              whether formally or informally, that it is the subject of a
              current or pending investigation, review or disciplinary procedure
              by any regulatory authority, professional body, Financial Services
              Regulator, self-regulatory organisation, regulated exchange,
              clearing house, government body, agency, or any other officially
              appointed inquiry.
            </p>
            <p>
              The applicant or any member of its group has been convicted
              (including where a conviction has been completed, removed from
              record, or otherwise spent, as the case may be) or found guilty by
              any court of a competent jurisdiction of any criminal offence.
            </p>
            <p>
              The applicant or any member of its group in the last ten years has
              been the subject of disciplinary procedures by a government body
              or agency or any Financial Services Regulator, self-regulatory
              organisation, or other professional body.
            </p>
            <p>
              The applicant or any member of its group in the last ten years has
              contravened any provision of financial services legislation or of
              rules, regulations, statements of principle, or codes of practice
              made under it or made by a self-regulatory organisation, Financial
              Services Regulator, regulated exchange, or clearing house.
            </p>
            <p>
              The applicant or any member of its group in the last ten years has
              been refused or had a restriction placed on the right to carry on
              a trade, business, or profession requiring a licence,
              registration, or other permission.
            </p>
            <p>
              The applicant or any member of its group in the last ten years has
              received an adverse finding or an agreed settlement in a civil
              action by any court or tribunal of competent jurisdiction.
            </p>
            <p>
              The applicant or any member of its group in the last ten years has
              been censured, disciplined, publicly criticised, or the subject of
              any investigation or enquiry by any regulatory authority,
              Financial Services Regulator, or officially appointed inquiry.
            </p>
          </div>
        </div>

        <Form.Group className="prereg-form-group marg_t3">
          <p className="center">Choose one of the following:</p>

          <Row noGutters className="prereg-row flex-start">
            <Form.Check
              label={
                <p>
                  I confirm that <b>NONE</b> of the above statements apply to
                  the applicant's circumstances.
                </p>
              }
              className="prereg-radio"
              onClick={(e) => this.handleAnswerEntityQuestions(e, "none")}
              name="answer2"
              checked={answer2 === "none" ? true : false}
              type="radio"
            />
          </Row>

          <Row noGutters className="prereg-row flex-start">
            <Form.Check
              label={
                <p>
                  I confirm that <b>ONE</b> or <b>MORE</b> of the above
                  statements apply to the applicant's circumstances.
                </p>
              }
              className="prereg-radio"
              onClick={(e) => this.handleAnswerEntityQuestions(e, "oneOrMore")}
              name="answer2"
              checked={answer2 === "oneOrMore" ? true : false}
              type="radio"
            />
          </Row>
        </Form.Group>

        <Form.Group className="prereg-form-group">
          <p className="marg_t3">
            Has the applicant ever been declared insolvent or made subject to
            any insolvency proceedings which have not been discharged?
          </p>

          <Row noGutters className="prereg-row" id="checkbox-row">
            <div id="shortbox">
              <Form.Check
                label="Yes"
                className="prereg-radio"
                onClick={(e) => this.handleAnswerEntityQuestions(e, "yes")}
                name="answer3"
                checked={answer3 === "yes" ? true : false}
                type="radio"
              />
              <Form.Check
                label="No"
                className="prereg-radio"
                onClick={(e) => this.handleAnswerEntityQuestions(e, "no")}
                name="answer3"
                checked={answer3 === "no" ? true : false}
                type="radio"
              />
            </div>
          </Row>
        </Form.Group>
      </React.Fragment>
    );
  };

  renderCaptcha = () => {
    const { siteKey } = this.props;

    return (
      <ReCAPTCHA
        className="recaptcha"
        sitekey={siteKey}
        onChange={this.onChangeCaptcha}
      />
    );
  };

  renderPrivacyStatementQuestion = () => {
    const { privacyStatement } = this.state;
    return (
      <Form.Group className="prereg-form-group marg_t3">
        <p className="privacy-statement-p">
          I have read and agree to the
          <a
            className="privacy-statement-text"
            target="_blank"
            href="https://ousoul.com/privacypolicy/"
          >
            Privacy Statement & Data Protection
          </a>
        </p>

        <Row noGutters className="prereg-row" id="checkbox-row">
          <div id="shortbox">
            <Form.Check
              label="Yes"
              className="prereg-radio"
              onClick={() => this.handleAnswerPrivacyStatement(true)}
              name="privacyStatement"
              checked={privacyStatement === true ? true : false}
              type="radio"
            />
            <Form.Check
              label="No"
              className="prereg-radio"
              onClick={() => this.handleAnswerPrivacyStatement(false)}
              name="privacyStatement"
              checked={privacyStatement === false ? true : false}
              type="radio"
            />
          </div>
        </Row>
      </Form.Group>
    );
  };

  renderNextButton = () => {
    const { applyingAs, captcha, privacyStatement } = this.state;

    let tempCaptcha = true;

    let disabled = true;
    let onClick;
    if (applyingAs === "individual") {
      onClick = this.handleCheckIndividualAnswers;
      const { answer1, answer2, answer3 } = this.state.individualUserAnswers;
      if (answer1 && answer2 && answer3 && tempCaptcha && privacyStatement) {
        disabled = false;
      }
    } else if (applyingAs === "entity") {
      onClick = this.handleCheckEntityAnswers;
      const { answer1, answer2, answer3 } = this.state.entityUserAnswers;
      if (answer1 && answer2 && answer3 && tempCaptcha && privacyStatement) {
        disabled = false;
      }
    }

    return (
      <Button
        className="teal-button btnSizeFull"
        disabled={disabled}
        onClick={onClick}
      >
        Next
      </Button>
    );
  };

  renderLogo = () => (
    <div className="login-logo-row">
      <div className="login-logo-box">
        <img src={logo} className="login-logo" />
      </div>
    </div>
  );

  render() {
    const { applyingAs } = this.state;

    let questions;
    let captcha;
    let nextButton;
    let privacyStatement;
    if (applyingAs === "individual") {
      questions = this.renderIndividualQuestions();
      captcha = "";
      nextButton = this.renderNextButton();
      privacyStatement = this.renderPrivacyStatementQuestion();
    } else if (applyingAs === "entity") {
      questions = this.renderEntityQuestions();
      captcha = "";
      nextButton = this.renderNextButton();
      privacyStatement = this.renderPrivacyStatementQuestion();
    } else {
      questions = "";
      captcha = "";
      privacyStatement = "";
    }

    return (
      <Card className="registration-box">
        <h1 className="login-h1">Sign Up</h1>
        <p className="center">
          Please complete the questionnaire before continuing to registration.
        </p>
        <Row noGutters className="centered">
          <div className="sized-box-380">
            <Form className="preregistration-form">
              {this.renderStarterQuestions()}
            </Form>

            <Form className="preregistration-form bottom-form">
              {questions}
              {privacyStatement}
            </Form>

            <Row noGutters className="prereg-captcha-row">
              {captcha}
            </Row>

            <Row noGutters className="prereg-next-button-row">
              {nextButton}
            </Row>
          </div>
        </Row>
      </Card>
    );
  }
}

// <p className="center">
//   Please confirm how the following statements apply to your own
//   personal circumstances:
// </p>
// </Form.Group>
//
// <div className="scrollHolder">
// <div className="scrollHolder-content">
//   <p>
//     I have been convicted or found guilty by any court of competent
//     jurisdiction in respect of any offence, other than a minor road
//     traffic offence.
//   </p>
//   <p>
//     I have been the subject of disciplinary sanctions by a government
//     body, government agency, self-regulatory organisation or other
//     professional body.
//   </p>
//   <p>
//     I have been concerned with the management of a Body Corporate
//     which has been or is currently the subject of an investigation
//     into an allegation of misconduct or malpractice.
//   </p>
//   <p>
//     Whether in connection with the formation or management of a
//     corporation I have been the subject of an adverse finding in a
//     civil proceeding by any court of competent jurisdiction for any
//     fraud, misfeasance, or other misconduct.
//   </p>
//   <p>
//     I have been the subject an adverse finding or an agreed settlement
//     in a civil action by any court or tribunal of competent
//     jurisdiction resulting in an award against the candidate in excess
//     of $10,000 or awards that total more than $10,000.
//   </p>
//   <p>
//     I have been the subject of an order of disqualification as a
//     director or to act in the management or conduct of the affairs of
//     a corporation by a court of competent jurisdiction or regulator.
//   </p>
// </div>
// </div>
//
// <Form.Group className="prereg-form-group marg_t3">
// <p className="center">Choose one of the following:</p>
//
// <Row noGutters className="prereg-row flex-start">
//   <Form.Check
//     label={
//       <p>
//         I confirm that <b>NONE</b> of the above statements apply to
//         the applicant's circumstances.
//       </p>
//     }
//     className="prereg-radio"
//     onClick={(e) => this.handleAnswerIndividualQuestions(e, "none")}
//     name="answer2"
//     checked={answer2 === "none" ? true : false}
//     type="radio"
//   />
// </Row>
//
// <Row noGutters className="prereg-row flex-start">
//   <Form.Check
//     label={
//       <p>
//         I confirm that <b>ONE</b> or <b>MORE</b> of the above
//         statements apply to the applicant's circumstances.
//       </p>
//     }
//     className="prereg-radio"
//     onClick={(e) =>
//       this.handleAnswerIndividualQuestions(e, "oneOrMore")
//     }
//     name="answer2"
//     checked={answer2 === "oneOrMore" ? true : false}
//     type="radio"
//   />
// </Row>
// </Form.Group>

export default Preregistration;
