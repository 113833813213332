import { connect } from "react-redux"
import AdminManagement from './AdminManagement'
import {
  getListOfAdminsRequest,
  createAdminRequest,
  deleteAdminRequest,
  reviewUserRequest,
  updateUserStatusRequest,
  exportPdfRequest,
  clearPdf,
  editUserRequest,
  editUserReset,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  admins: state.admin.admins,
  adminReview: state.admin.userManagementUserReview,
  editUser: state.admin.editUser,
})

const mapDispatchToProps = {
  getListOfAdminsRequest,
  createAdminRequest,
  deleteAdminRequest,
  reviewUserRequest,
  updateUserStatusRequest,
  exportPdfRequest,
  clearPdf,
  editUserRequest,
  editUserReset,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminManagement)
