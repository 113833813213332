import * as CONSTANTS from "../constants/register";

export const registerRequest = (data) => ({
  type: CONSTANTS.REGISTER_REQUEST,
  data,
});

export const registerSuccess = (data) => ({
  type: CONSTANTS.REGISTER_SUCCESS,
  data,
});

export const registerError = (error) => ({
  type: CONSTANTS.REGISTER_ERROR,
  error,
});

/***************************************************/

export const getRoleListRequest = () => ({
  type: CONSTANTS.GET_ROLE_LIST_REQUEST,
});

export const getRoleListSuccess = (data) => ({
  type: CONSTANTS.GET_ROLE_LIST_SUCCESS,
  data,
});

export const getRoleListError = (error) => ({
  type: CONSTANTS.GET_ROLE_LIST_ERROR,
  error,
});

/***************************************************/

export const getCheckListRequest = (accountInfo) => ({
  type: CONSTANTS.GET_CHECK_LIST_REQUEST,
  accountInfo,
});

export const getCheckListSuccess = (data) => ({
  type: CONSTANTS.GET_CHECK_LIST_SUCCESS,
  data,
});

export const getCheckListError = (error) => ({
  type: CONSTANTS.GET_CHECK_LIST_ERROR,
  error,
});

/***************************************************/

export const deleteAccountRequest = (id) => ({
  type: CONSTANTS.DELETE_ACCOUNT_REQUEST,
  id,
});

export const deleteAccountSuccess = () => ({
  type: CONSTANTS.DELETE_ACCOUNT_SUCCESS,
});

export const deleteAccountError = (error) => ({
  type: CONSTANTS.DELETE_ACCOUNT_ERROR,
  error,
});

/***************************************************/

export const getSpecificUserFormRequest = (id) => ({
  type: CONSTANTS.GET_SPECIFIC_USER_FORM_REQUEST,
  id,
});

export const getSpecificUserFormSuccess = (data) => ({
  type: CONSTANTS.GET_SPECIFIC_USER_FORM_SUCCESS,
  data,
});

export const getSpecificUserFormError = (error) => ({
  type: CONSTANTS.GET_SPECIFIC_USER_FORM_ERROR,
  error,
});

/***************************************************/

export const editSpecificUserFormRequest = (data) => ({
  type: CONSTANTS.EDIT_SPECIFIC_USER_FORM_REQUEST,
  data,
});

export const editSpecificUserFormSuccess = () => ({
  type: CONSTANTS.EDIT_SPECIFIC_USER_FORM_SUCCESS,
});

export const editSpecificUserFormError = (error) => ({
  type: CONSTANTS.EDIT_SPECIFIC_USER_FORM_ERROR,
  error,
});

/***************************************************/

export const submitSpecificUserFormRequest = (id, accountData) => ({
  type: CONSTANTS.SUBMIT_SPECIFIC_USER_FORM_REQUEST,
  id,
  accountData,
});

export const submitSpecificUserFormSuccess = () => ({
  type: CONSTANTS.SUBMIT_SPECIFIC_USER_FORM_SUCCESS,
});

export const submitSpecificUserFormError = (error) => ({
  type: CONSTANTS.SUBMIT_SPECIFIC_USER_FORM_ERROR,
  error,
});

/***************************************************/

export const registrationUploadFileRequest = (
  fileData,
  formData,
  fieldIndex,
  subfieldDataIndex,
  subfieldIndex
) => ({
  type: CONSTANTS.REGISTRATION_UPLOAD_FILE_REQUEST,
  fileData,
  formData,
  fieldIndex,
  subfieldDataIndex,
  subfieldIndex,
});

export const registrationUploadFileSuccess = (fileUrl) => ({
  type: CONSTANTS.REGISTRATION_UPLOAD_FILE_SUCCESS,
  fileUrl,
});

export const registrationUploadFileError = (error) => ({
  type: CONSTANTS.REGISTRATION_UPLOAD_FILE_ERROR,
  error,
});

/***************************************************/

export const deleteUploadedFileRequest = (
  url,
  fieldIndex,
  formData,
  subfieldDataIndex,
  subfieldIndex
) => ({
  type: CONSTANTS.DELETE_UPLOADED_FILE_REQUEST,
  url,
  fieldIndex,
  formData,
  subfieldDataIndex,
  subfieldIndex,
});

export const deleteUploadedFileSuccess = (formData) => ({
  type: CONSTANTS.DELETE_UPLOADED_FILE_SUCCESS,
  formData,
});

export const deleteUploadedFileError = (error) => ({
  type: CONSTANTS.DELETE_UPLOADED_FILE_ERROR,
  error,
});

export const submitPreregistration = (flag, applyingAs) => ({
  type: CONSTANTS.SUBMIT_PREREGISTRATION,
  flag,
  applyingAs,
});

export const resetRegistrationFields = () => ({
  type: CONSTANTS.RESET_REGISTRATION_FIELDS,
});

/***************************************************/

export const updateCurrentForm = (currentForm) => ({
  type: CONSTANTS.UPDATE_CURRENT_FORM,
  currentForm,
});

/***************************************************/

export const sendWelcomeEmail = () => ({
  type: CONSTANTS.SEND_WELCOME_EMAIL,
});
