import React, { PureComponent } from 'react';
import ReactPaginate from 'react-paginate';

class Pagination extends PureComponent {
  render() {
    return (
      <div className="pagination">
        <ReactPaginate
          previousLabel={'<'}
          previousClassName={'pagination__item pagination__arrow'}
          previousLinkClassName={'pagination__icon pagination__icon--prev'}
          nextLabel={'>'}
          nextClassName={'pagination__item pagination__arrow'}
          nextLinkClassName={'pagination__icon pagination__icon--next'}
          breakLabel={'...'}
          breakClassName={'pagination__ellipsis'}
          breakLinkClassName={'pagination__ellipsis-link'}
          pageCount={this.props.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={this.props.handlePageClick}
          containerClassName={'pagination__list'}
          activeLinkClassName={'pagination__link--active'}
          pageClassName={'pagination__item'}
          pageLinkClassName={'pagination__link'}
          forcePage={this.props.dataPageNumber}
        />
      </div>
    );
  }
}

export default Pagination;
