import React from "react";
import { connect } from "react-redux";
import { Row, Spinner, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { AiOutlineDownload, AiOutlineReload } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserJurisdictionRequest,
  clearUserJurisdiction,
} from "../redux/actions/admin";

const mapStateToProps = (state) => ({
  userJurisdiction: state.admin.userJurisdiction,
});

const mapDispatchToProps = {
  getUserJurisdictionRequest,
  clearUserJurisdiction,
};

class CSVExport extends React.Component {
  componentDidMount() {
    this.props.clearUserJurisdiction();
  }

  componentWillUnmount() {
    this.props.clearUserJurisdiction();
  }

  renderGenerate = () => (
    <div
      onClick={this.props.getUserJurisdictionRequest}
      className="csv-refresh"
    >
      Generate Report
    </div>
  );

  renderDownload = () => {
    const { userJurisdiction, text } = this.props;
    const currentDate = this.formatDate(new Date());
    const filename = `User Jurisdiction Report - ${currentDate}.csv`;

    return (
      <Row
        noGutters
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <CSVLink
          data={userJurisdiction.data.data}
          headers={userJurisdiction.data.headers}
          className="csv-link"
          target="_blank"
          filename={filename}
        >
          {text}
        </CSVLink>
        {this.renderRefresh()}
      </Row>
    );
  };

  renderRefresh = () => {
    const tooltip = <Tooltip id="button-tooltip">Refresh</Tooltip>;
    return (
      <OverlayTrigger placement="right" delay={{ show: 250 }} overlay={tooltip}>
        <div
          className="csv-refresh"
          onClick={this.props.getUserJurisdictionRequest}
        >
          <AiOutlineReload />
        </div>
      </OverlayTrigger>
    );
  };

  renderLoading = () => (
    <Row noGutters style={{ alignItems: "center" }}>
      <Spinner
        animation="border"
        className="common-grey-spinner"
        style={{ width: 14, height: 14, marginRight: 8 }}
      />
      Loading Report...
    </Row>
  );

  formatDate = (dateInput) => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return month + " " + day + ", " + year;
  };

  renderContent = () => {
    let content;
    if (this.props.userJurisdiction) {
      const { loading, loaded } = this.props.userJurisdiction;
      if (loading) {
        content = this.renderLoading();
      } else if (loaded) {
        content = this.renderDownload();
      } else {
        content = this.renderGenerate();
      }
    }

    return content;
  };

  render() {
    return this.renderContent();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CSVExport);
