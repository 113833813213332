import { connect } from "react-redux"
import Admin from './Admin'
import Dashboard from './Dashboard'
import Forms from './Forms'
import Roles from './Roles'
import Submissions from './Submissions'
import UserManagement from './UserManagement'
import AdminManagement from './AdminManagement'
import {
  getNavbarLinksRequest
} from '../../redux/actions/admin'
import { logoutRequest } from '../../redux/actions/login'
import { deleteAccountRequest } from '../../redux/actions/register'

const mapStateToProps = state => ({
  account: state.auth.account,
  navbarLinks: state.admin.navbarLinks,
})

const mapDispatchToProps = {
  logoutRequest,
  deleteAccountRequest,
  getNavbarLinksRequest,
}

// these are the pieces that get rendered via routes in the Admin component
export {
  Dashboard,
  Forms,
  Roles,
  Submissions,
  UserManagement,
  AdminManagement,
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
