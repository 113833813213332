import React, { PureComponent } from 'react'

export default class ContactUsWidget extends PureComponent {
  constructor(props) {
    super(props)
  }

  jiraHelpDesk = callback => {
   var jhdScript = document.createElement('script');
   jhdScript.type = 'text/javascript';
   jhdScript.setAttribute('data-jsd-embedded', null);
   jhdScript.setAttribute('data-key', '0b42cd65-4e7f-4694-95db-f99b549d18bb');
   jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
   jhdScript.src='https://jsd-widget.atlassian.com/assets/embed.js';
   if(jhdScript.readyState) { // old IE support
    jhdScript.onreadystatechange = function() {
     if ( jhdScript.readyState === 'loaded' || jhdScript.readyState === 'complete' ) {
      jhdScript.onreadystatechange = null;
      callback();
     }
    };
   } else { //modern browsers
    jhdScript.onload = function() {
     callback();
    };
   }
   document.getElementsByTagName('head')[0].appendChild(jhdScript);
  }

  callBack = () => {
    var DOMContentLoaded_event = document.createEvent('Event');
    DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
    window.document.dispatchEvent(DOMContentLoaded_event);
  }

  render() {
    return (
      <div>{this.jiraHelpDesk(this.callBack)}</div>
    )
  }
}
