export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

/*****************************************************/

export const GET_ROLE_LIST_REQUEST = "GET_ROLE_LIST_REQUEST";
export const GET_ROLE_LIST_SUCCESS = "GET_ROLE_LIST_SUCCESS";
export const GET_ROLE_LIST_ERROR = "GET_ROLE_LIST_ERROR";

/*****************************************************/

export const GET_CHECK_LIST_REQUEST = "GET_CHECK_LIST_REQUEST";
export const GET_CHECK_LIST_SUCCESS = "GET_CHECK_LIST_SUCCESS";
export const GET_CHECK_LIST_ERROR = "GET_CHECK_LIST_ERROR";

/*****************************************************/

export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR";

/*****************************************************/

export const GET_SPECIFIC_USER_FORM_REQUEST = "GET_SPECIFIC_USER_FORM_REQUEST";
export const GET_SPECIFIC_USER_FORM_SUCCESS = "GET_SPECIFIC_USER_FORM_SUCCESS";
export const GET_SPECIFIC_USER_FORM_ERROR = "GET_SPECIFIC_USER_FORM_ERROR";

/*****************************************************/

export const EDIT_SPECIFIC_USER_FORM_REQUEST =
  "EDIT_SPECIFIC_USER_FORM_REQUEST";
export const EDIT_SPECIFIC_USER_FORM_SUCCESS =
  "EDIT_SPECIFIC_USER_FORM_SUCCESS";
export const EDIT_SPECIFIC_USER_FORM_ERROR = "EDIT_SPECIFIC_USER_FORM_ERROR";

/*****************************************************/

export const SUBMIT_SPECIFIC_USER_FORM_REQUEST =
  "SUBMIT_SPECIFIC_USER_FORM_REQUEST";
export const SUBMIT_SPECIFIC_USER_FORM_SUCCESS =
  "SUBMIT_SPECIFIC_USER_FORM_SUCCESS";
export const SUBMIT_SPECIFIC_USER_FORM_ERROR =
  "SUBMIT_SPECIFIC_USER_FORM_ERROR";

/*****************************************************/

export const REGISTRATION_UPLOAD_FILE_REQUEST =
  "REGISTRATION_UPLOAD_FILE_REQUEST";
export const REGISTRATION_UPLOAD_FILE_SUCCESS =
  "REGISTRATION_UPLOAD_FILE_SUCCESS";
export const REGISTRATION_UPLOAD_FILE_ERROR = "REGISTRATION_UPLOAD_FILE_ERROR";

/*****************************************************/

export const DELETE_UPLOADED_FILE_REQUEST = "DELETE_UPLOADED_FILE_REQUEST";
export const DELETE_UPLOADED_FILE_SUCCESS = "DELETE_UPLOADED_FILE_SUCCESS";
export const DELETE_UPLOADED_FILE_ERROR = "DELETE_UPLOADED_FILE_ERROR";

/*****************************************************/

export const SUBMIT_PREREGISTRATION = "SUBMIT_PREREGISTRATION";

/*****************************************************/

export const UPDATE_CURRENT_FORM = "UPDATE_CURRENT_FORM";
export const RESET_REGISTRATION_FIELDS = "RESET_REGISTRATION_FIELDS";
/*****************************************************/

export const SEND_WELCOME_EMAIL = "SEND_WELCOME_EMAIL";

/*****************************************************/
