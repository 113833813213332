import React, { Fragment, PureComponent } from "react";
import { Switch, Route, withRouter, Router, Redirect, HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container, Row, Spinner, Modal } from "react-bootstrap";
import { MetaTagsContainer } from "../Components";
import * as COMPONENT from "../containers";

class Routes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      auth: this.props.auth,
      account: this.props.account,
      session_expired: this.props.session_expired,
    };
  }

  componentDidMount() {
    this.props.authRequest();
  }

  componentDidUpdate() {}

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        auth: nextProps.auth,
        account: nextProps.account,
        session_expired: nextProps.session_expired,
      });
    }
  }

  handleDetermineRoutes = () => {
    const { account, auth } = this.state;
    const emailConfirmed = account.data.emailConfirmed;

    let routes;
    if (auth.loading) {
      routes = this.renderLoading();
    } else if (auth.status) {
      if (account.data.role === "admin") {
        if (emailConfirmed) {
          // console.log("admin routes");
          routes = this.renderAdminRoutes();
        } else {
          // console.log("email unconfirmed routes");
          // && account.data.role !== admin
          routes = this.renderAdminUnconfirmedEmailRoutes();
        }
      } else if (account.data.active == "Y") {
        // Tier 0 is simple user, visit site but cant do anything
        // Tier 1 can deposit and withdrawl up to $1k
        // Tier 2 is a full fledged users
        if (account.data.tier && account.data.tier !== "2") {
          routes = this.renderUserRoutes();
        } else {
          routes = this.renderApprovedRoutes();
        }
      } else {
        if (emailConfirmed) {
          // new routes
          // console.log("user routes");
          routes = this.renderUserRoutes();
        } else {
          routes = this.renderUnconfirmedAccountRoutes();
        }
      }
    } else {
      // console.log('public routes')
      routes = this.renderPublicRoutes();
    }

    return routes;
  };

  handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  renderPublicRoutes = () => (
    <Switch onUpdate={this.handleScrollToTop}>
      <Route
        exact
        path="/session-expired"
        component={COMPONENT.SessionExpired}
      />
      <Route exact path="/login" component={COMPONENT.Login} />
      <Route exact path="/register" component={COMPONENT.Register} />
      <Route exact path="/reset" component={COMPONENT.Reset} />
      <Route exact path="/forgot" component={COMPONENT.Forgot} />
      <Route exact path="/confirm" component={COMPONENT.ConfirmEmail} />
      <Route exact path="/token-expired" component={COMPONENT.TokenExpired} />
      <Route component={COMPONENT.Login} />
    </Switch>
  );

  renderAdminRoutes = () => (
    <Switch onUpdate={this.handleScrollToTop}>
      <Route exact path="/admin" component={COMPONENT.Admin} />
      <Route component={COMPONENT.Admin} />
    </Switch>
  );

  renderAuthRoutes = () => <Switch onUpdate={this.handleScrollToTop}></Switch>;

  renderApprovedRoutes = () => (
    <Switch onUpdate={this.handleScrollToTop}>
      <Route component={COMPONENT.Approved} />
    </Switch>
  );

  renderAdminUnconfirmedEmailRoutes = () => (
    <Switch onUpdate={this.handleScrollToTop}>
      <Route path="/confirm" component={COMPONENT.ConfirmEmail} />
      <Route component={COMPONENT.ConfirmEmail} />
    </Switch>
  );

  renderUnconfirmedAccountRoutes = () => (
    <Switch onUpdate={this.handleScrollToTop}>
      <Route path="/registration" component={COMPONENT.Registration} />
      <Route component={COMPONENT.Registration} />
    </Switch>
  );

  renderUserRoutes = () => (
    <Switch onUpdate={this.handleScrollToTop}>
      <Route path="/" component={COMPONENT.Registration} />
      <Route component={COMPONENT.Registration} />
    </Switch>
  );

  renderLoading = () => (
    <Row noGutters className="app-spinner">
      <Spinner animation="border" variant="secondary" />
    </Row>
  );

  renderSessionExpiredModal = () => {
    return (
      <Modal
        centered
        className="common-modal submission-modal"
        show={this.state.session_expired == "session_expired" ? true : false}
        onHide={
          (this.setState({ session_expired: "" }),
          () => this.props.resetSessionExpired())
        }
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>Your session has expired</Modal.Body>
      </Modal>
    );
  };

  renderMetaTags = () => {
    return <MetaTagsContainer />;
  };

  render() {
    return (
      <Fragment>
        {this.renderMetaTags()}
        <div className="app-container">
          {this.renderSessionExpiredModal()}
          <ToastContainer hideProgressBar={true} autoClose={3000} />
          {this.handleDetermineRoutes()}
        </div>
      </Fragment>
    );
  }
}

export default Routes;
