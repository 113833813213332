import React, { PureComponent } from 'react'
import {
  Row,
  Col,
  Container,
  FormControl,
  Button,
  Table,
  Spinner,
  Modal,
} from 'react-bootstrap'
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineQuestionCircle,
} from 'react-icons/ai'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { NavLink } from "react-router-dom"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

class Roles extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      roles: {
        data: [],
        loading: false,
        loaded: false,
        error: '',
      },
      editingRole: {
        data: {},
        saving: false,
        loading: false,
        loaded: false,
        error: ''
      },
      publishedForms: {
        data: [],
        loading: false,
        loaded: false,
        error: ''
      },
      selectedRole: '',
    }
  }

  componentDidMount() {
    this.handleSearchRoles()
    this.handleClearRoleToEdit()
    this.handleGetListOfPublishedForms()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        roles: nextProps.roles,
        editingRole: nextProps.editingRole,
        publishedForms: nextProps.publishedForms,
      })
    }
  }

  handleGetListOfPublishedForms = () => {
    this.props.getListOfPublishedFormsRequest()
  }

  handleSearchRoles = () => {
    this.props.searchRolesRequest()
  }

  handleCreateRole = () => {
    this.props.createRoleRequest()
  }

  handleSelectRole = e => {
    const { value } = e.target
    let id
    let name

    if (value) {
      const splitValue = value.split('/***/')
      id = splitValue[0]
      name = splitValue[1]
      this.props.getRoleToEditRequest(id)
    } else {
      this.handleClearRoleToEdit()
    }

    this.setState({ selectedRole: name })

    this.handleGetListOfPublishedForms()
  }

  handleClearRoleToEdit = () => {
    this.props.clearRoleToEdit()
  }

  handleUpdateRoleData = () => {
    this.setState({
      editingRole: {
        ...this.state.editingRole,
      }
    })

    const { data } = this.state.editingRole
    this.props.editRoleRequest(data[0])
  }

  handleOnChangeRoleData = e => {
    const { value, name } = e.target
    let newRoleData = this.state.editingRole.data[0]
    newRoleData[name] = value

    if (name === 'name') {
      // this changes the name of the role in the list of roles as it is edited
      let newRolesData = this.state.roles.data
      for (let i = 0; i < newRolesData.length; i++) {
        if (newRolesData[i]._id === newRoleData._id) {
          newRolesData[i].name = value
        }
      }
    }

    this.handleUpdateRoleData()
  }

  handleOnChangeRoleDescription = e => {
    const { value, name } = e.target
    let newRoleData = this.state.editingRole.data[0]
    newRoleData[name] = value

    this.handleUpdateRoleData()
  }

  handlePublishRole = () => {
    const id = this.state.editingRole.data[0]._id
    this.props.publishRoleRequest(id)
  }

  handleUnpublishRole = () => {
    const id = this.state.editingRole.data[0]._id
    this.props.unpublishRoleRequest(id)
  }

  handleDeleteRole = () => {
    const { _id } = this.state.editingRole.data[0]
    this.props.deleteRoleRequest(_id)
  }

  onDragEnd = result => {
    const { destination, source, draggableId } = result

    if (!destination) {
      return
    }

    const startColumn = source.droppableId
    const finishColumn = destination.droppableId

    const startIndex = source.index
    const finishIndex = destination.index

    const editingRoleData = this.state.editingRole.data
    const formsAssignedToThisRole = editingRoleData[0].forms
    const availableForms = this.state.publishedForms.data

    const splitDraggableId = draggableId.split('/***/')
    const formData = {
      name: splitDraggableId[0],
      _id: splitDraggableId[1],
    }

    if ( // if the user drops the form right back where it was
      startColumn === finishColumn &&
      startIndex === finishIndex
    ) {
      return
    } else if ( // if the user reorders within the forms-assigned-to-this-role column
      startColumn === 'forms-assigned-to-this-role' &&
      finishColumn === 'forms-assigned-to-this-role'
    ) {

      formsAssignedToThisRole.splice(startIndex, 1)
      formsAssignedToThisRole.splice(finishIndex, 0, formData)
      editingRoleData[0].forms = formsAssignedToThisRole
      this.handleGetListOfPublishedForms()

    } else if ( // if the user reorders within the available-roles column
      startColumn === 'available-forms' &&
      finishColumn === 'available-forms'
    ) {

      availableForms.splice(startIndex, 1)
      availableForms.splice(finishIndex, 0, formData)

    } else if ( // if the user moves a form from available-forms to forms-assigned-to-this-role
      startColumn === 'available-forms' &&
      finishColumn === 'forms-assigned-to-this-role'
    ) {

      availableForms.splice(source.index, 1)
      formsAssignedToThisRole.splice(finishIndex, 0, formData)
      editingRoleData[0].forms = formsAssignedToThisRole
      this.handleGetListOfPublishedForms()

    } else if ( // if the user moves a form from forms-assigned-to-this-role to available-forms
      startColumn === 'forms-assigned-to-this-role' &&
      finishColumn === 'available-forms'
    ) {

      formsAssignedToThisRole.splice(source.index, 1)
      editingRoleData[0].forms = formsAssignedToThisRole
      this.handleGetListOfPublishedForms()

    }

    this.setState({
      editingRole: {
        ...this.state.editingRole,
        data: editingRoleData
      },
      publishedForms: {
        ...this.state.publishedForms,
        data: availableForms
      }
    })

    this.props.editRoleRequest(editingRoleData[0])
  }

  /**************************************************************************/


  renderSearchBar = () => {
    const { data } = this.state.roles
    const { saving, loaded } = this.state.editingRole

    let savingText
    if (saving) {
      savingText = (
        <div className='form-saving-text'>
          <Spinner animation='border' size='sm' />
          Saving...
        </div>
      )
    } else {
      savingText = ''
    }

    let deleteButton
    if (loaded) {
      const isPublished = this.state.editingRole.data[0].isPublished
      if (isPublished) {
        deleteButton = (
          <AiOutlineDelete
            className='individual-form-icon-disabled role-delete-button' />
        )
      } else {
        deleteButton = (
          <AiOutlineDelete
            onClick={() => this.handleDeleteRole()}
            className='individual-form-icon role-delete-button' />
        )
      }
    } else {
      deleteButton = ''
    }

    return (
      <Row noGutters className='roles-search-bar'>

        <div className='roles-search-bar-container'>
          Pick a role to edit:
          <FormControl
            size='sm'
            className='common-admin-form-control'
            onChange={this.handleSelectRole}
            as='select'>
              <option>Select a role...</option>
              {data.map(role => this.renderIndividualRole(role))}
          </FormControl>

          {deleteButton}
        </div>

        <div>
          {savingText}

          <Button
            onClick={this.handleCreateRole}
            className='teal-button'>
            Define a new role
          </Button>
        </div>

      </Row>
    )
  }

  renderIndividualRole = role => {
    const { name, _id } = role
    const renderName = name ? name : 'Unnamed Role'
    const renderId = _id + '/***/' + name

    return <option value={renderId} key={_id}>{renderName}</option>
  }

  renderEditingRole = () => {
    const { data, loaded, loading } = this.state.editingRole

    let content
    if (loaded) {
      const { name, description } = data[0]
      const disabled = data[0].isPublished ? true : false

      content = (
        <Row noGutters className='editing-role-section'>
          <Col>
            <Row noGutters className='editing-role-form-row'>
              <Col className='editing-role-form-col'>
                <Row noGutters>
                  <Col sm={3}>
                    Role name:
                  </Col>
                  <Col>
                    <FormControl
                      size='sm'
                      value={name}
                      name='name'
                      disabled={disabled}
                      onChange={this.handleOnChangeRoleData}
                      className='common-admin-form-control' />
                  </Col>
                </Row>
              </Col>

              <Col sm={4} className='editing-role-info-col'>
                <AiOutlineQuestionCircle className='roles-info-icon' />
                Your users will see this name when choosing a role during
                registration
              </Col>
            </Row>

            <Row noGutters className='editing-role-form-row'>
              <Col className='editing-role-form-col'>
                <Row noGutters>
                  <Col sm={3}>
                    Role description:
                  </Col>
                  <Col>
                    <FormControl
                      as='textarea'
                      size='sm'
                      name='description'
                      disabled={disabled}
                      value={description}
                      onChange={this.handleOnChangeRoleData}
                      className='common-admin-form-control' />
                  </Col>
                </Row>
              </Col>

              <Col sm={4} className='editing-role-info-col'>
                <AiOutlineQuestionCircle className='roles-info-icon' />
                Please create a detailed description to help your users
                understand if this role applies to them
              </Col>
            </Row>
          </Col>

        </Row>
      )

    } else if (loading) {
      content = this.renderLoading()
    }

    return content
  }

  renderPublishButton = () => {
    const { editingRole } = this.state

    let publishButton
    if (editingRole.data[0].isPublished) {
      publishButton = (
        <Button
          onClick={this.handleUnpublishRole}
          className='teal-button'>
            Unpublish
          </Button>
      )
    } else {
      publishButton = (
        <Button
          onClick={this.handlePublishRole}
          className='teal-button'>
            Publish
          </Button>
      )
    }

    return publishButton
  }

  renderDragAndDrop = () => {
    const { publishedForms, editingRole } = this.state

    if (editingRole.loaded) {
      return (
        <DragDropContext
          onDragEnd={this.onDragEnd}>
          <Row noGutters>
            <Col className='roles-drag-and-drop-context-col'>
              <Row noGutters className='roles-drag-drop-row'>

                <Droppable droppableId={'forms-assigned-to-this-role'}>
                  {provided => {
                    return (
                      <Col
                        className='roles-drag-and-drop-col'
                        ref={provided.innerRef}
                        {...provided.droppableProps}>

                        <h5>Forms assigned to this role:</h5>
                        {editingRole.data[0].forms.map((form, index) => this.renderFormsAssignedToThisRole(form, index))}
                        {provided.placeholder}

                      </Col>
                    )
                  }}
                </Droppable>

                <Col sm={1} />

                <Droppable droppableId={'available-forms'}>
                  {provided => {
                    return (
                      <Col
                        className='roles-drag-and-drop-col'
                        ref={provided.innerRef}
                        {...provided.droppableProps}>

                        <h5>Available forms:</h5>
                        {publishedForms.data.map((form, index) => this.renderAvailableForm(form, index))}
                        {provided.placeholder}

                      </Col>
                    )
                  }}
                </Droppable>

              </Row>

              <Row noGutters className='roles-publish-button-row'>
                {this.renderPublishButton()}
              </Row>

            </Col>
          </Row>
        </DragDropContext>
      )
    }
  }

  renderFormsAssignedToThisRole = (form, index) => {
    const { _id, name } = form
    const draggableId = name + '/***/' + _id + '/***/form-assigned-to-this-role' // draggableIds must be different between columns

    const isPublished = this.state.editingRole.data[0].isPublished
    let isDragDisabled
    let className
    if (isPublished) {
      isDragDisabled = true
      className='roles-draggable disabled'
    } else {
      className='roles-draggable'
    }

    return (
      <Draggable
        isDragDisabled={isDragDisabled}
        draggableId={draggableId}
        index={index}
        key={_id}>
        {provided => {
          return (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              className={className}>
              {name}
            </div>
          )
        }}
      </Draggable>
    )
  }

  renderAvailableForm = (form, index) => {
    const { _id, name } = form
    const draggableId = name + '/***/' + _id + '/***/available-form'// draggableIds must be different between columns

    const formsAssignedToThisRole = this.state.editingRole.data[0].forms

    const isPublished = this.state.editingRole.data[0].isPublished
    let isDragDisabled
    let className
    if (isPublished) {
      isDragDisabled = true
      className='roles-draggable disabled'
    } else {
      className='roles-draggable'
    }
    // hides an available-form if it is present in the forms-assigned-to-this-role
    let hidden
    for (let i = 0; i < formsAssignedToThisRole.length; i++) {
      if (formsAssignedToThisRole[i]._id === _id) {
        hidden = 'hidden'
      }
    }

    return (
      <Draggable
        isDragDisabled={isDragDisabled}
        draggableId={draggableId}
        index={index}
        key={_id}>

        {provided => {
          return (
            <div
              id={hidden}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              className={className}>
              {name}
            </div>
          )
        }}

      </Draggable>
    )
  }

  renderLoading = () => (
    <Row noGutters className='app-spinner'>
      <Spinner animation='border' className='common-grey-spinner' />
    </Row>
  )

  render() {
    return (
      <Container fluid className='admin-content-container'>

        <Row noGutters className='forms-header'>
          <h4>Define Roles</h4>
        </Row>

        <div className='forms-table-box'>
          {this.renderSearchBar()}
          {this.renderEditingRole()}
          {this.renderDragAndDrop()}
        </div>

      </Container>
    )
  }
}

export default Roles
