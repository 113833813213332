import * as CONSTANTS from '../constants/forgot'

export const forgotRequest = data => ({
  type: CONSTANTS.FORGOT_REQUEST,
  data
})

export const forgotSuccess = data => ({
  type: CONSTANTS.FORGOT_SUCCESS,
  data
})

export const forgotError = error => ({
  type: CONSTANTS.FORGOT_ERROR,
  error
})

export const resetForgot = () => ({
  type: CONSTANTS.RESET_FORGOT
})
