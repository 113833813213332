import { connect } from "react-redux"
import Login from './Login'
import SessionExpired from './SessionExpired'
import { loginRequest } from '../../redux/actions/login'

const mapStateToProps = state => ({

})

const mapDispatchToProps = {
  loginRequest,
}

export {
  Login,
  SessionExpired
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
