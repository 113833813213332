import React from "react";
import MetaTags from "react-meta-tags";
import logo from "../images/Mid-Chains-Logo-Symbol.png";

export default function MetaTagsContainer() {
  return (
    <MetaTags>
      <meta property="og:image" content={logo} />
      <meta property="og:type" content="website" />
      <meta property="twitter:image" content={logo} />
    </MetaTags>
  );
}
