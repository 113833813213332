import * as CONSTANTS from "../constants/admin";

const initialState = {
  forms: {
    data: [],
    loading: false,
    loaded: false,
    error: "",
  },
  editingForm: {
    data: {},
    saving: false,
    loading: false,
    loaded: false,
    error: "",
  },

  roles: {
    data: [],
    loading: false,
    loaded: false,
    error: "",
  },
  editingRole: {
    data: {},
    saving: false,
    loading: false,
    loaded: false,
    error: "",
  },

  publishedForms: {
    data: [],
    loading: false,
    loaded: false,
    error: "",
  },

  submissions: {
    data: [],
    loading: false,
    loaded: false,
    error: "",
  },
  submissionUsers: {
    data: [],
    loading: false,
    loaded: false,
    error: "",
  },
  submissionFormTypes: {
    data: [],
    loading: false,
    loaded: false,
    error: "",
  },
  specificSubmission: {
    data: {},
    loading: false,
    loaded: false,
    error: "",
    saving: false,
  },

  userManagementUsers: {
    data: [],
    loading: false,
    loaded: false,
    error: "",
  },
  userManagementRoles: {
    data: [],
    loading: false,
    loaded: false,
    error: "",
  },
  userManagementUserReview: {
    data: {},
    loading: false,
    loaded: false,
    error: "",
  },

  admins: {
    data: {},
    loading: false,
    loaded: false,
    error: "",
  },

  submissionEvents: {
    data: [],
    loaded: false,
    loading: false,
    error: "",
  },

  dashboardStats: {
    data: {},
    loaded: false,
    loading: false,
    error: "",
  },

  graphData: {
    data: {},
    loaded: false,
    loading: false,
    error: "",
  },

  userDataForPdf: {
    data: {},
    user_email: "",
    loading: false,
    loaded: false,
  },

  editUser: {
    loading: false,
  },

  navbarLinks: {
    data: {},
    loading: false,
    loaded: false,
    error: "",
  },

  submissionTypes: {
    data: {},
    loading: false,
    loaded: false,
    error: "",
  },

  identitymindLoading: false,
  submissionFieldLoading: {
    fieldIndex: "",
    subfieldIndex: "",
    loading: false,
  },
  userJurisdiction: {
    data: {},
    loading: false,
    loaded: false,
    error: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.ADMIN_SEARCH_FORMS_REQUEST:
      return {
        ...state,
        forms: {
          ...state.forms,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_SEARCH_FORMS_SUCCESS:
      return {
        ...state,
        forms: {
          ...state.forms,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_SEARCH_FORMS_ERROR:
      return {
        ...state,
        forms: {
          ...state.forms,
          loading: false,
          loaded: false,
          error: action.error.reason,
        },
      };

    /***************************************/

    case CONSTANTS.ADMIN_CREATE_FORM_SUCCESS:
      let newFormsCreate = state.forms.data;
      newFormsCreate.push(action.data);

      return {
        ...state,
        forms: {
          ...state.forms,
          data: newFormsCreate,
        },
      };

    /***************************************/

    case CONSTANTS.ADMIN_DELETE_FORM_SUCCESS:
      let newFormsDelete = state.forms.data;
      for (let i = 0; i < newFormsDelete.length; i++) {
        if (newFormsDelete[i]._id === action.id) {
          newFormsDelete.splice(i, 1);
        }
      }

      return {
        ...state,
        forms: {
          ...state.forms,
          data: newFormsDelete,
        },
      };

    /***************************************/

    case CONSTANTS.ADMIN_GET_FORM_TO_EDIT_REQUEST:
      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_GET_FORM_TO_EDIT_SUCCESS:
      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_GET_FORM_TO_EDIT_ERROR:
      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_CLEAR_FORM_TO_EDIT:
      return {
        ...state,
        editingForm: {
          data: {},
          loading: false,
          loaded: false,
          error: "",
        },
      };

    /******************************************/

    case CONSTANTS.ADMIN_EDIT_FORM_REQUEST:
      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          saving: true,
        },
      };

    case CONSTANTS.ADMIN_EDIT_FORM_SUCCESS:
      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          saving: false,
        },
      };

    case CONSTANTS.ADMIN_EDIT_FORM_ERROR:
      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          saving: false,
          error: action.error,
        },
      };

    /***************************************/

    case CONSTANTS.ADMIN_PUBLISH_FORM_REQUEST:
      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          saving: true,
        },
      };

    case CONSTANTS.ADMIN_PUBLISH_FORM_SUCCESS:
      let newPublishData = state.editingForm.data;
      newPublishData[0].isPublished = true;

      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          saving: false,
          data: newPublishData,
        },
      };

    case CONSTANTS.ADMIN_PUBLISH_FORM_ERROR:
      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          saving: false,
          error: action.error,
        },
      };

    /***************************************/

    case CONSTANTS.ADMIN_UNPUBLISH_FORM_REQUEST:
      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          saving: true,
        },
      };

    case CONSTANTS.ADMIN_UNPUBLISH_FORM_SUCCESS:
      let newUnpublishData = state.editingForm.data;
      newUnpublishData[0].isPublished = false;

      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          saving: false,
          data: newUnpublishData,
        },
      };

    case CONSTANTS.ADMIN_UNPUBLISH_FORM_ERROR:
      return {
        ...state,
        editingForm: {
          ...state.editingForm,
          saving: false,
          error: action.error,
        },
      };

    /*******************************************/

    case CONSTANTS.ADMIN_SEARCH_ROLES_REQUEST:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_SEARCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_SEARCH_ROLES_ERROR:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: false,
          loaded: false,
          error: action.error.reason,
        },
      };

    /***************************************/

    case CONSTANTS.ADMIN_CREATE_ROLE_SUCCESS:
      let newRolesCreate = state.roles.data;
      newRolesCreate.push(action.data);

      return {
        ...state,
        roles: {
          ...state.forms,
          data: newRolesCreate,
        },
      };

    /***************************************/

    case CONSTANTS.ADMIN_GET_ROLE_TO_EDIT_REQUEST:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_GET_ROLE_TO_EDIT_SUCCESS:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_GET_ROLE_TO_EDIT_ERROR:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_CLEAR_ROLE_TO_EDIT:
      return {
        ...state,
        editingRole: {
          data: {},
          loading: false,
          loaded: false,
          error: "",
        },
      };

    /******************************************/

    case CONSTANTS.ADMIN_EDIT_ROLE_REQUEST:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: true,
        },
      };

    case CONSTANTS.ADMIN_EDIT_ROLE_SUCCESS:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: false,
        },
      };

    case CONSTANTS.ADMIN_EDIT_ROLE_ERROR:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: false,
          error: action.error,
        },
      };

    /******************************************/

    case CONSTANTS.ADMIN_GET_LIST_OF_PUBLISHED_FORMS_REQUEST:
      return {
        ...state,
        publishedForms: {
          ...state.publishedForms,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_GET_LIST_OF_PUBLISHED_FORMS_SUCCESS:
      return {
        ...state,
        publishedForms: {
          ...state.publishedForms,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_GET_LIST_OF_PUBLISHED_FORMS_ERROR:
      return {
        ...state,
        publishedForms: {
          ...state.publishedForms,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    /***************************************/

    case CONSTANTS.ADMIN_PUBLISH_ROLE_REQUEST:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: true,
        },
      };

    case CONSTANTS.ADMIN_PUBLISH_ROLE_SUCCESS:
      let newPublishRoleData = state.editingRole.data;
      newPublishRoleData[0].isPublished = true;

      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: false,
          data: newPublishRoleData,
        },
      };

    case CONSTANTS.ADMIN_PUBLISH_ROLE_ERROR:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: false,
          error: action.error,
        },
      };

    /***************************************/

    case CONSTANTS.ADMIN_UNPUBLISH_ROLE_REQUEST:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: true,
        },
      };

    case CONSTANTS.ADMIN_UNPUBLISH_ROLE_SUCCESS:
      let newUnpublishRoleData = state.editingRole.data;
      newUnpublishRoleData[0].isPublished = false;

      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: false,
          data: newUnpublishRoleData,
        },
      };

    case CONSTANTS.ADMIN_UNPUBLISH_ROLE_ERROR:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: false,
          error: action.error,
        },
      };

    /***************************************/

    case CONSTANTS.ADMIN_DELETE_ROLE_REQUEST:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: true,
          loading: true,
        },
      };

    case CONSTANTS.ADMIN_DELETE_ROLE_SUCCESS:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: false,
          data: {},
          loading: false,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_DELETE_ROLE_ERROR:
      return {
        ...state,
        editingRole: {
          ...state.editingRole,
          saving: false,
          loading: false,
          error: action.error,
        },
      };

    /**********************************************************/
    case CONSTANTS.ADMIN_SEARCH_SUBMISSIONS_REQUEST:
      return {
        ...state,
        submissions: {
          ...state.submissions,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_SEARCH_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        submissions: {
          ...state.submissions,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_SEARCH_SUBMISSIONS_ERROR:
      return {
        ...state,
        submissions: {
          ...state.submissions,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_SUBMISSIONS_SEARCH_USERS_REQUEST:
      return {
        ...state,
        submissionUsers: {
          ...state.submissionUsers,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_SUBMISSIONS_SEARCH_USERS_SUCCESS:
      return {
        ...state,
        submissionUsers: {
          ...state.submissionUsers,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_SUBMISSIONS_CLEAR_USERS:
      return {
        ...state,
        submissionUsers: {
          data: [],
          loading: false,
          loaded: false,
          error: "",
        },
      };

    case CONSTANTS.ADMIN_SUBMISSIONS_SEARCH_USERS_ERROR:
      return {
        ...state,
        submissionUsers: {
          ...state.submissionUsers,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_SUBMISSIONS_GET_FORM_TYPES_REQUEST:
      return {
        ...state,
        submissionFormTypes: {
          ...state.submissionFormTypes,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_SUBMISSIONS_GET_FORM_TYPES_SUCCESS:
      return {
        ...state,
        submissionFormTypes: {
          ...state.submissionFormTypes,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_SUBMISSIONS_GET_FORM_TYPES_ERROR:
      return {
        ...state,
        submissionFormTypes: {
          ...state.submissionFormTypes,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_GET_SPECIFIC_SUBMISSION_REQUEST:
      return {
        ...state,
        specificSubmission: {
          ...state.specificSubmission,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_GET_SPECIFIC_SUBMISSION_SUCCESS:
      return {
        ...state,
        specificSubmission: {
          ...state.specificSubmission,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_GET_SPECIFIC_SUBMISSION_ERROR:
      return {
        ...state,
        specificSubmission: {
          ...state.specificSubmission,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_EDIT_USER_SUBMISSION_REQUEST:
      return {
        ...state,
        submissionFieldLoading: {
          ...state.submissionFieldLoading,
          fieldIndex: action.fieldIndex,
          subfieldIndex: action.subfieldIndex,
          loading: true,
        },
      };

    case CONSTANTS.ADMIN_EDIT_USER_SUBMISSION_SUCCESS:
      return {
        ...state,
        submissionFieldLoading: {
          ...state.submissionFieldLoading,
          fieldIndex: "",
          subfieldIndex: "",
          loading: false,
        },
        specificSubmission: {
          ...state.specificSubmission,
          data: {
            ...state.specificSubmission.data,
            fields: action.data.fields,
          },
        },
      };

    case CONSTANTS.ADMIN_EDIT_USER_SUBMISSION_ERROR:
      return {
        submissionFieldLoading: {
          ...state.submissionFieldLoading,
          fieldIndex: "",
          subfieldIndex: "",
          loading: false,
        },
      };

    case CONSTANTS.CLEAR_SPECIFIC_SUBMISSION:
      return {
        ...state,
        specificSubmission: {
          data: {},
          loading: false,
          loaded: false,
          error: "",
          saving: false,
        },
      };

    case CONSTANTS.ADMIN_SET_STATUS_OF_SUBMISSION_SUCCESS:
      const newSpecificSubmissionData = state.specificSubmission.data;
      newSpecificSubmissionData.status = action.status;
      return {
        ...state,
        specificSubmission: {
          ...state.specificSubmission,
          data: newSpecificSubmissionData,
        },
      };

    /****************************************************************************/

    case CONSTANTS.ADMIN_USER_MANAGEMENT_SEARCH_USERS_REQUEST:
      return {
        ...state,
        userManagementUsers: {
          ...state.userManagementUsers,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_USER_MANAGEMENT_SEARCH_USERS_SUCCESS:
      return {
        ...state,
        userManagementUsers: {
          ...state.userManagementUsers,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_USER_MANAGEMENT_SEARCH_USERS_ERROR:
      return {
        ...state,
        userManagementUsers: {
          ...state.userManagementUsers,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    /****************************************************************************/

    case CONSTANTS.ADMIN_GET_LIST_OF_ROLES_REQUEST:
      return {
        ...state,
        userManagementRoles: {
          ...state.userManagementRoles,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_GET_LIST_OF_ROLES_SUCCESS:
      return {
        ...state,
        userManagementRoles: {
          ...state.userManagementRoles,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_GET_LIST_OF_ROLES_ERROR:
      return {
        ...state,
        userManagementRoles: {
          ...state.userManagementRoles,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    /****************************************************************************/

    case CONSTANTS.ADMIN_REVIEW_USER_REQUEST:
      return {
        ...state,
        userManagementUserReview: {
          ...state.userManagementUserReview,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_REVIEW_USER_SUCCESS:
      return {
        ...state,
        userManagementUserReview: {
          ...state.userManagementUserReview,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_REVIEW_USER_ERROR:
      return {
        ...state,
        userManagementUserReview: {
          ...state.userManagementUserReview,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_RESET_REVIEW_USER:
      return {
        ...state,
        userManagementUserReview: {
          data: {},
          loading: false,
          loaded: false,
          error: "",
        },
      };

    /**************************************************************************/

    case CONSTANTS.ADMIN_GET_LIST_OF_ADMINS_REQUEST:
      return {
        ...state,
        admins: {
          ...state.admins,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_GET_LIST_OF_ADMINS_SUCCESS:
      return {
        ...state,
        admins: {
          ...state.admins,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_GET_LIST_OF_ADMINS_ERROR:
      return {
        ...state,
        admins: {
          ...state.admins,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_ADD_SUBMISSION_COMMENT_REQUEST:
      return {
        ...state,
        submissionEvents: {
          ...state.submissionEvents,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_ADD_SUBMISSION_COMMENT_ERROR:
      return {
        ...state,
        submissionEvents: {
          ...state.submissionEvents,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_EMAIL_APPLICANT_REQUEST:
      return {
        ...state,
        submissionEvents: {
          ...state.submissionEvents,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_EMAIL_APPLICANT_ERROR:
      return {
        ...state,
        submissionEvents: {
          ...state.submissionEvents,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_GET_SUBMISSION_EVENTS_REQUEST:
      return {
        ...state,
        submissionEvents: {
          ...state.submissionEvents,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_GET_SUBMISSION_EVENTS_SUCCESS:
      return {
        ...state,
        submissionEvents: {
          ...state.submissionEvents,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_GET_SUBMISSION_EVENTS_ERROR:
      return {
        ...state,
        submissionEvents: {
          ...state.submissionEvents,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_RESET_SUBMISSION_EVENTS:
      return {
        ...state,
        submissionEvents: {
          data: [],
          loaded: false,
          loading: false,
          error: "",
        },
      };

    /**************************************************************************/

    case CONSTANTS.ADMIN_GET_DASHBOARD_STATS_REQUEST:
      return {
        ...state,
        dashboardStats: {
          ...state.dashboardStats,
          data: {},
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_GET_DASHBOARD_STATS_SUCCESS:
      return {
        ...state,
        dashboardStats: {
          ...state.dashboardStats,
          data: action.data,
          loading: false,
          loaded: true,
        },
      };

    case CONSTANTS.ADMIN_GET_DASHBOARD_STATS_ERROR:
      return {
        ...state,
        dashboardStats: {
          ...state.dashboardStats,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_GET_GRAPH_DATA_REQUEST:
      return {
        ...state,
        graphData: {
          ...state.graphData,
          data: {},
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_GET_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        graphData: {
          ...state.graphData,
          data: action.data,
          loading: false,
          loaded: true,
        },
      };

    case CONSTANTS.ADMIN_GET_GRAPH_DATA_ERROR:
      return {
        ...state,
        graphData: {
          ...state.graphData,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    /*************************************************/

    case CONSTANTS.ADMIN_EXPORT_PDF_REQUEST:
      return {
        ...state,
        userDataForPdf: {
          ...state.userDataForPdf,
          data: {},
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_EXPORT_PDF_SUCCESS:
      return {
        ...state,
        userDataForPdf: {
          ...state.userDataForPdf,
          data: action.data.data,
          user_email: action.data.user_email,
          loading: false,
          loaded: true,
        },
      };

    case CONSTANTS.ADMIN_CLEAR_PDF:
      return {
        ...state,
        userDataForPdf: {
          data: {},
          loading: false,
          loaded: false,
        },
      };

    /*************************************************/

    case CONSTANTS.ADMIN_EDIT_USER_REQUEST:
      return {
        ...state,
        editUser: {
          loading: true,
        },
      };

    case CONSTANTS.ADMIN_EDIT_USER_SUCCESS:
      return {
        ...state,
        editUser: {
          loading: false,
        },
      };

    case CONSTANTS.ADMIN_EDIT_USER_ERROR:
      return {
        ...state,
        editUser: {
          loading: false,
        },
      };

    case CONSTANTS.ADMIN_EDIT_USER_RESET:
      return {
        ...state,
        editUser: {
          loading: false,
        },
      };

    /*****************************************************/

    case CONSTANTS.ADMIN_GET_NAVBAR_LINKS_REQUEST:
      return {
        ...state,
        navbarLinks: {
          ...state.navbarLinks,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_GET_NAVBAR_LINKS_SUCCESS:
      return {
        ...state,
        navbarLinks: {
          ...state.navbarLinks,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_GET_NAVBAR_LINKS_ERROR:
      return {
        ...state,
        navbarLinks: {
          ...state.navbarLinks,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    /*****************************************************/

    case CONSTANTS.ADMIN_GET_SUBMISSION_TYPES_REQUEST:
      return {
        ...state,
        submissionTypes: {
          ...state.submissionTypes,
          loading: true,
          loaded: false,
        },
      };

    case CONSTANTS.ADMIN_GET_SUBMISSION_TYPES_SUCCESS:
      return {
        ...state,
        submissionTypes: {
          ...state.submissionTypes,
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_GET_SUBMISSION_TYPES_ERROR:
      return {
        ...state,
        submissionTypes: {
          ...state.submissionTypes,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CONSTANTS.IDENTITYMIND_REQUEST:
      return {
        ...state,
        identitymindLoading: true,
      };

    case CONSTANTS.IDENTITYMIND_SUCCESS:
      return {
        ...state,
        identitymindLoading: false,
      };

    case CONSTANTS.IDENTITYMIND_ERROR:
      return {
        ...state,
        identitymindLoading: false,
      };

    case CONSTANTS.ADMIN_GET_USER_JURISDICTION_REQUEST:
      return {
        ...state,
        userJurisdiction: {
          loading: true,
          loaded: false,
          error: "",
        },
      };

    case CONSTANTS.ADMIN_GET_USER_JURISDICTION_SUCCESS:
      return {
        ...state,
        userJurisdiction: {
          loading: false,
          loaded: true,
          data: action.data,
        },
      };

    case CONSTANTS.ADMIN_GET_USER_JURISDICTION_ERROR:
      return {
        ...state,
        userJurisdiction: {
          loading: false,
          loaded: false,
          data: {},
          error: action.error,
        },
      };

    case CONSTANTS.ADMIN_CLEAR_USER_JURISDICTION:
      return {
        ...state,
        userJurisdiction: {
          loading: false,
          loaded: false,
          data: {},
          error: "",
        },
      };

    default:
      return state;
  }
};
