import { connect } from "react-redux"
import ContactUsWidget from './ContactUsWidget'

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsWidget)
