import { connect } from "react-redux"
import Forms from './Forms'
import Preview from './Preview'
import {
  searchFormsRequest,
  createFormRequest,
  deleteFormRequest,
  getFormToEditRequest,
  clearFormToEdit,
  editFormRequest,
  publishFormRequest,
  unpublishFormRequest,
  copyFormRequest,
  getSubmissionTypesRequest,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  forms: state.admin.forms,
  editingForm: state.admin.editingForm,
  submissionTypes: state.admin.submissionTypes,
})

const mapDispatchToProps = {
  searchFormsRequest,
  createFormRequest,
  deleteFormRequest,
  getFormToEditRequest,
  clearFormToEdit,
  editFormRequest,
  publishFormRequest,
  unpublishFormRequest,
  copyFormRequest,
  getSubmissionTypesRequest,
}

export {
  Preview
}

export default connect(mapStateToProps, mapDispatchToProps)(Forms)
