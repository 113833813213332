import { history } from '../../configureStore'
import * as CONSTANTS from '../constants/login'
import * as AUTH_CONSTANTS from '../constants/auth'

const initialState = {
  session_expired: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.LOGIN_SUCCESS
    && AUTH_CONSTANTS.AUTH_SUCCESS 
    && CONSTANTS.RESET_SESSION_EXPIRED:
      return {
        ...state,
        session_expired: ''
      }

    case CONSTANTS.LOGOUT_SUCCESS:
      return {
        ...state,
        session_expired: action.data
      }

    default:
      return state
  }
}
