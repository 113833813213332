import * as CONSTANTS from '../constants/confirmEmail';

const initialState = {
  emailConfirmed: {
    data: {},
    loading: false,
    confirmed: false,
    error: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case CONSTANTS.CONFIRM_EMAIL_REQUEST:
      return {
        ...state,
        emailConfirmed: {
          ...state.emailConfirmed,
          loading: true,
          confirmed: false,
          error: ''
        }
      }
    case CONSTANTS.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        emailConfirmed: {
          ...state.emailConfirmed,
          loading: false,
          confirmed: true,
          error: ''
        }
      }
    case CONSTANTS.CONFIRM_EMAIL_ERROR:
      return {
        ...state,
        emailConfirmed: {
          ...state.emailConfirmed,
          loading: false,
          confirmed: false,
          error: action.error.reason
        }
      }

    default:
      return state
  }
}
