import { connect } from "react-redux";
import Submissions from "./Submissions";
import {
  searchSubmissionsRequest,
  submissionsSearchUsersRequest,
  submissionsGetFormTypesRequest,
  submissionsClearUsers,
  getSpecificSubmissionRequest,
  clearSpecificSubmission,
  setStatusOfSubmissionRequest,
  getSubmissionEventsRequest,
  addSubmissionCommentRequest,
  emailApplicantRequest,
  resetSubmissionEvents,
  identitymindRequest,
  deleteUserSubmissionFileRequest,
  uploadUserSubmissionFileRequest,
} from "../../../redux/actions/admin";

const mapStateToProps = (state) => ({
  submissions: state.admin.submissions,
  submissionUsers: state.admin.submissionUsers,
  submissionFormTypes: state.admin.submissionFormTypes,
  specificSubmission: state.admin.specificSubmission,
  submissionEvents: state.admin.submissionEvents,
  identitymindLoading: state.admin.identitymindLoading,
  userid: state.auth.account.data._id,
  submissionFieldLoading: state.admin.submissionFieldLoading,
});

const mapDispatchToProps = {
  searchSubmissionsRequest,
  submissionsSearchUsersRequest,
  submissionsGetFormTypesRequest,
  submissionsClearUsers,
  getSpecificSubmissionRequest,
  clearSpecificSubmission,
  setStatusOfSubmissionRequest,
  getSubmissionEventsRequest,
  addSubmissionCommentRequest,
  emailApplicantRequest,
  resetSubmissionEvents,
  identitymindRequest,
  deleteUserSubmissionFileRequest,
  uploadUserSubmissionFileRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Submissions);
