import { connect } from "react-redux"
import UserManagement from './UserManagement'
import {
  userManagementSearchUsersRequest,
  getListOfRolesRequest,
  reviewUserRequest,
  resetReviewUser,
  updateUserStatusRequest,
  deleteUserRequest,
  exportPdfRequest,
  clearPdf,
  editUserRequest,
  editUserReset,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  userManagementUsers: state.admin.userManagementUsers,
  userManagementRoles: state.admin.userManagementRoles,
  userManagementUserReview: state.admin.userManagementUserReview,
  userDataForPdf: state.admin.userDataForPdf,
  editUser: state.admin.editUser,
  account: state.auth.account.data,
})

const mapDispatchToProps = {
  userManagementSearchUsersRequest,
  getListOfRolesRequest,
  reviewUserRequest,
  resetReviewUser,
  updateUserStatusRequest,
  deleteUserRequest,
  exportPdfRequest,
  clearPdf,
  editUserRequest,
  editUserReset,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)
