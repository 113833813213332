let captchaKeys = {}

if (process.env.NODE_ENV == "development") {
  // DEVELOPMENT KEYS localhost:3000
  captchaKeys = {
    siteKey: '6Lf7W-wUAAAAAJn1C-5ld-DNwsoWN9Bh8OpJbRg4',
    secretKey: '6Lf7W-wUAAAAAFYyiDGv1D48sw-EgjeK_TGz_F4B'
  }
}else if(process.env.REACT_APP_ENV == "midchains_staging"){
  captchaKeys = { //http://ec2-15-185-158-22.me-south-1.compute.amazonaws.com:8080
    siteKey: '6LdnIq0ZAAAAADrhE5zSZTUS6n9Dt5iDnJEA_uCF',
    secretKey: '6LdnIq0ZAAAAAHMouZRtWYVQxJLIJ8S_8W_pKcKq'
  }
}else if(process.env.REACT_APP_ENV == "midchains_dev"){
  captchaKeys = { //http://ec2-15-185-158-22.me-south-1.compute.amazonaws.com:8080
    siteKey: '6LeJh8QZAAAAAH_76gY4bCWqYKwSDbvAjQYVu20P',
    secretKey: '6LeJh8QZAAAAANBtCnbtNHLklo3uL4TkbBa_TTG-'
  }
}
 else {
  // PRODUCTION KEYS -
  captchaKeys = { //fi-onboarding.elasticbeanstalk
    siteKey: '6LfC7PIUAAAAALnPMRjPa8qm-wVsG3SNdLX6twEM',
    secretKey: '6LfC7PIUAAAAANcMcB3cifInK4LmEYgU4l1AoMI4'
  }
}

export default captchaKeys
