import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Button,
  Modal,
  FormControl,
} from "react-bootstrap";
import {
  AiOutlineEdit,
  AiOutlineReload,
  AiOutlineUserDelete,
} from "react-icons/ai";
import Pagination from "../../Pagination/Pagination";

class AdminManagement extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      admins: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      createAdminParams: {
        first: "",
        last: "",
        email: "",
        password: "",
        cpassword: "",
      },
      adminReview: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      showCreateAdminModal: false,
      currentPage: 0,
      offset: 0,
      showAdminReviewModal: false,
      editUser: {
        loading: false,
      },
      showConfirmDeleteModal: false,
    };
  }

  componentDidMount() {
    this.handleSearchAdmins();
    this.props.editUserReset();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        admins: nextProps.admins,
        adminReview: nextProps.adminReview,
        editUser: nextProps.editUser,
      });
    }
  }

  handleShowCreateAdminModal = () => {
    this.setState({ showCreateAdminModal: true });
  };

  handleHideCreateAdminModal = () => {
    this.setState({ showCreateAdminModal: false });
  };

  handleClickPage = (number) => {
    this.setState(
      {
        currentPage: number.selected,
        offset: number.selected * 10,
      },
      () => this.handleSearchAdmins()
    );
  };

  handleSearchAdmins = () => {
    this.props.getListOfAdminsRequest(this.state.offset);
  };

  handleChangeParams = (e) => {
    const { value, name } = e.target;
    this.setState({
      createAdminParams: {
        ...this.state.createAdminParams,
        [name]: value,
      },
    });
  };

  handleCreateAdmin = () => {
    this.props.createAdminRequest(this.state.createAdminParams);
  };

  handleShowConfirmDeleteModal = (admin) => {
    this.setState({
      showConfirmDeleteModal: true,
      selectedAdminToDelete: admin,
      showAdminReviewModal: false,
    });
  };

  handleHideConfirmDeleteModal = () => {
    this.setState({
      showConfirmDeleteModal: false,
      selectedAdminToDelete: {},
      showAdminReviewModal: true,
    });
  };

  handleDeleteAdmin = (adminId) => {
    this.handleHideConfirmDeleteModal();
    this.props.deleteAdminRequest(adminId, this.state.offset);
  };

  handleEditUser = (e) => {
    const userReviewDocs = this.state.adminReview.data.docs;

    if (e.target.name == "first") {
      userReviewDocs[0].first_name = e.target.value;
    } else if (e.target.name == "last") {
      userReviewDocs[0].last_name = e.target.value;
    }

    this.setState({
      adminReview: {
        ...this.state.adminReview,
      },
    });
  };

  handleSaveChanges = () => {
    const { _id, first_name, last_name } = this.state.adminReview.data.docs[0];

    const editParams = {
      _id,
      first: first_name,
      last: last_name,
    };

    this.props.editUserRequest(editParams);
  };

  handleHideAdminReviewModal = () => {
    this.setState(
      {
        showAdminReviewModal: false,
      },
      () => this.handleSaveChanges()
    );
  };

  handleShowAdminReviewModal = () => {
    this.setState({
      showAdminReviewModal: true,
    });
  };

  handleGetAdminToReview = (id) => {
    this.handleShowAdminReviewModal();
    this.props.reviewUserRequest(id);
  };

  /****************************************************************************/

  renderTable = () => {
    const { data, loading, loaded } = this.state.admins;

    let content;
    if (loading) {
      content = this.renderLoading();
    } else if (loaded) {
      content = data.docs.map((admin) => this.renderIndividualAdmin(admin));
    }

    return (
      <Table bordered className="submissions-table">
        <thead>
          <tr>
            <th>Email</th>
          </tr>
        </thead>

        <tbody>{content}</tbody>
      </Table>
    );
  };

  renderIndividualAdmin = (admin) => {
    const { status, _id, email, role } = admin;

    return (
      <tr key={_id}>
        <td>
          <Row noGutters className="td-row">
            {email}
            <AiOutlineEdit
              onClick={() => this.handleGetAdminToReview(_id)}
              className="individual-form-icon"
            />
          </Row>
        </td>
      </tr>
    );
  };

  renderLoading = () => (
    <Row noGutters className="app-spinner">
      <Spinner animation="border" className="common-grey-spinner" />
    </Row>
  );

  renderCreateAdminButton = () => {
    return (
      <Row noGutters className="create-admin-button-row">
        <Button
          onClick={this.handleShowCreateAdminModal}
          className="teal-button"
        >
          Create Admin
        </Button>
      </Row>
    );
  };

  renderPagination = () => {
    if (this.state.admins.loaded) {
      const { docs, limit, total } = this.state.admins.data;
      const totalPages = Math.ceil(total / limit) || 1;
      return (
        <Pagination
          totalPages={totalPages}
          handlePageClick={this.handleClickPage}
          dataPageNumber={this.state.currentPage}
        />
      );
    }
  };

  renderCreateAdminModal = () => {
    const { showCreateAdminModal } = this.state;
    return (
      <Modal
        centered
        className="common-modal"
        show={showCreateAdminModal}
        onHide={this.handleHideCreateAdminModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Admin</Modal.Title>
        </Modal.Header>

        {this.renderCreateAdminModalForm()}
      </Modal>
    );
  };

  renderCreateAdminModalForm = () => {
    const {
      first,
      last,
      email,
      password,
      cpassword,
    } = this.state.createAdminParams;
    const disableButton =
      password == cpassword && password && cpassword && email ? false : true;

    return (
      <React.Fragment>
        <Modal.Body>
          <Row noGutters>
            <Col>
              <Row noGutters className="create-admin-form-row">
                <label>First Name:</label>
                <FormControl
                  onChange={this.handleChangeParams}
                  name="first"
                  value={first}
                  size="sm"
                  className="submission-form-control"
                />
              </Row>
              <Row noGutters className="create-admin-form-row">
                <label>Last Name:</label>
                <FormControl
                  onChange={this.handleChangeParams}
                  name="last"
                  value={last}
                  size="sm"
                  className="submission-form-control"
                />
              </Row>
              <Row noGutters className="create-admin-form-row">
                <label>Email:</label>
                <FormControl
                  onChange={this.handleChangeParams}
                  name="email"
                  value={email}
                  size="sm"
                  className="submission-form-control"
                />
              </Row>
              <Row noGutters className="create-admin-form-row">
                <label>Password:</label>
                <FormControl
                  onChange={this.handleChangeParams}
                  name="password"
                  type="password"
                  value={password}
                  size="sm"
                  className="submission-form-control"
                />
              </Row>
              <Row noGutters className="create-admin-form-row">
                <label>Confirm Password:</label>
                <FormControl
                  onChange={this.handleChangeParams}
                  name="cpassword"
                  type="password"
                  value={cpassword}
                  size="sm"
                  className="submission-form-control"
                />
              </Row>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={this.handleCreateAdmin}
            disabled={disableButton}
            className="teal-button"
            size="sm"
          >
            Create
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  };

  renderConfirmDeleteModal = () => {
    const { adminReview, showConfirmDeleteModal } = this.state;

    let adminName;
    let adminId;
    if (adminReview.loaded) {
      adminName = adminReview.data.docs[0].email;
      adminId = adminReview.data.docs[0]._id;
    }
    return (
      <Modal
        centered
        className="common-modal"
        show={showConfirmDeleteModal}
        onHide={this.handleHideConfirmDeleteModal}
      >
        <Modal.Body>
          <div className="delete-modal-body">
            <Row noGutters className="text-row">
              Are you sure you want to delete this admin?
            </Row>
            <Row noGutters className="admin-name-row">
              {adminName}
            </Row>
            <Row noGutters className="button-row">
              <Button
                onClick={() => this.handleDeleteAdmin(adminId)}
                className="delete-button"
                size="sm"
              >
                Delete
              </Button>
              <Button
                onClick={this.handleHideConfirmDeleteModal}
                className="teal-button"
                size="sm"
              >
                Cancel
              </Button>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  renderReviewAdminModal = () => {
    const { showAdminReviewModal, adminReview } = this.state;

    let body;
    if (adminReview.loaded) {
      const {
        _id,
        first_name,
        last_name,
        email,
        role,
        emailConfirmed,
        status,
      } = adminReview.data.docs[0];
      body = (
        <React.Fragment>
          <Modal.Body>
            <Row noGutters className="specific-submission-field">
              <Col>
                <label className="specific-submission-field-label">Id</label>

                <div className="specific-submission-content">{_id}</div>
              </Col>
            </Row>

            <Row noGutters className="specific-submission-field">
              <Col>
                <div className="specific-submission-editable-container">
                  <label className="specific-submission-field-label">
                    First Name
                  </label>
                  <div className="editable-field">(Editable)</div>
                </div>

                <FormControl
                  className="specific-submission-content"
                  size="sm"
                  onChange={this.handleEditUser}
                  name="first"
                  value={first_name}
                />
              </Col>
            </Row>

            <Row noGutters className="specific-submission-field">
              <Col>
                <div className="specific-submission-editable-container">
                  <label className="specific-submission-field-label">
                    Last Name
                  </label>
                  <div className="editable-field">(Editable)</div>
                </div>

                <FormControl
                  className="specific-submission-content"
                  size="sm"
                  onChange={this.handleEditUser}
                  name="last"
                  value={last_name}
                />
              </Col>
            </Row>

            <Row noGutters className="specific-submission-field">
              <Col>
                <label className="specific-submission-field-label">Email</label>

                <div className="specific-submission-content">{email}</div>
              </Col>
            </Row>

            <Row noGutters className="specific-submission-field">
              <Col>
                <label className="specific-submission-field-label">Role</label>

                <div className="specific-submission-content">
                  {role ? role.name : "No role selected"}
                </div>
              </Col>
            </Row>

            <Row noGutters className="specific-submission-field">
              <Col>
                <label className="specific-submission-field-label">
                  Confirmed Email?
                </label>

                <div className="specific-submission-content">
                  {emailConfirmed ? "True" : "False"}
                </div>
              </Col>
            </Row>

            <Row noGutters className="specific-submission-field">
              <Col>
                <label className="specific-submission-field-label">
                  Status
                </label>

                <div className="specific-submission-content">{status}</div>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Col className="submission-modal-column">
              <Row noGutters className="admin-review-modal-footer-row">
                <Button
                  onClick={this.handleSaveChanges}
                  className="teal-button"
                  size="sm"
                >
                  Save
                </Button>

                <Button
                  onClick={this.handleShowConfirmDeleteModal}
                  size="sm"
                  className="delete-button delete-user"
                >
                  Delete User
                </Button>
              </Row>
            </Col>
          </Modal.Footer>
        </React.Fragment>
      );
    } else if (adminReview.loading) {
      body = this.renderLoading();
    }

    return (
      <Modal
        centered
        className="common-modal submission-modal"
        show={showAdminReviewModal}
        onHide={this.handleHideAdminReviewModal}
      >
        <Modal.Header closeButton>Admin Data</Modal.Header>

        {body}
      </Modal>
    );
  };

  render() {
    return (
      <Container fluid className="admin-content-container">
        {this.renderCreateAdminModal()}
        {this.renderConfirmDeleteModal()}
        {this.renderReviewAdminModal()}

        <Row noGutters className="forms-header">
          <h4>Administrator Management</h4>
        </Row>

        <div className="forms-table-box">
          {this.renderCreateAdminButton()}
          {this.renderTable()}
          {this.renderPagination()}
        </div>
      </Container>
    );
  }
}

export default AdminManagement;
