import * as CONSTANTS from '../constants/auth'

export const authRequest = () => ({
  type: CONSTANTS.AUTH_REQUEST
})

export const authSuccess = data => ({
  type: CONSTANTS.AUTH_SUCCESS,
  data
})

export const authError = error => ({
  type: CONSTANTS.AUTH_ERROR,
  error
})

export const clearState = () => ({
  type: CONSTANTS.CLEAR_STATE
})
