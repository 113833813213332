import { connect } from "react-redux";
import Approved from "./Approved";
import { logoutRequest } from "../../redux/actions/login";
import { getNavbarLinksRequest } from "../../redux/actions/admin";

const mapStateToProps = (state) => ({ navbarLinks: state.admin.navbarLinks, account: state.auth.account, });

const mapDispatchToProps = { logoutRequest, getNavbarLinksRequest };

export default connect(mapStateToProps, mapDispatchToProps)(Approved);
