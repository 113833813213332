export const ADMIN_SEARCH_FORMS_REQUEST = "ADMIN_SEARCH_FORMS_REQUEST";
export const ADMIN_SEARCH_FORMS_SUCCESS = "ADMIN_SEARCH_FORMS_SUCCESS";
export const ADMIN_SEARCH_FORMS_ERROR = "ADMIN_SEARCH_FORMS_ERROR";

/****************************************************************/

export const ADMIN_CREATE_FORM_REQUEST = "ADMIN_CREATE_FORM_REQUEST";
export const ADMIN_CREATE_FORM_SUCCESS = "ADMIN_CREATE_FORM_SUCCESS";
export const ADMIN_CREATE_FORM_ERROR = "ADMIN_CREATE_FORM_ERROR";

/****************************************************************/

export const ADMIN_DELETE_FORM_REQUEST = "ADMIN_DELETE_FORM_REQUEST";
export const ADMIN_DELETE_FORM_SUCCESS = "ADMIN_DELETE_FORM_SUCCESS";
export const ADMIN_DELETE_FORM_ERROR = "ADMIN_DELETE_FORM_ERROR";

/****************************************************************/

export const ADMIN_GET_FORM_TO_EDIT_REQUEST = "ADMIN_GET_FORM_TO_EDIT_REQUEST";
export const ADMIN_GET_FORM_TO_EDIT_SUCCESS = "ADMIN_GET_FORM_TO_EDIT_SUCCESS";
export const ADMIN_GET_FORM_TO_EDIT_ERROR = "ADMIN_GET_FORM_TO_EDIT_ERROR";

export const ADMIN_CLEAR_FORM_TO_EDIT = "ADMIN_CLEAR_FORM_TO_EDIT";

/****************************************************************/

export const ADMIN_EDIT_FORM_REQUEST = "ADMIN_EDIT_FORM_REQUEST";
export const ADMIN_EDIT_FORM_SUCCESS = "ADMIN_EDIT_FORM_SUCCESS";
export const ADMIN_EDIT_FORM_ERROR = "ADMIN_EDIT_FORM_ERROR";

/****************************************************************/

export const ADMIN_PUBLISH_FORM_REQUEST = "ADMIN_PUBLISH_FORM_REQUEST";
export const ADMIN_PUBLISH_FORM_SUCCESS = "ADMIN_PUBLISH_FORM_SUCCESS";
export const ADMIN_PUBLISH_FORM_ERROR = "ADMIN_PUBLISH_FORM_ERROR";

/****************************************************************/

export const ADMIN_UNPUBLISH_FORM_REQUEST = "ADMIN_UNPUBLISH_FORM_REQUEST";
export const ADMIN_UNPUBLISH_FORM_SUCCESS = "ADMIN_UNPUBLISH_FORM_SUCCESS";
export const ADMIN_UNPUBLISH_FORM_ERROR = "ADMIN_UNPUBLISH_FORM_ERROR";

/****************************************************************/

export const ADMIN_SEARCH_ROLES_REQUEST = "ADMIN_SEARCH_ROLES_REQUEST";
export const ADMIN_SEARCH_ROLES_SUCCESS = "ADMIN_SEARCH_ROLES_SUCCESS";
export const ADMIN_SEARCH_ROLES_ERROR = "ADMIN_SEARCH_ROLES_ERROR";

/****************************************************************/

export const ADMIN_CREATE_ROLE_REQUEST = "ADMIN_CREATE_ROLE_REQUEST";
export const ADMIN_CREATE_ROLE_SUCCESS = "ADMIN_CREATE_ROLE_SUCCESS";
export const ADMIN_CREATE_ROLE_ERROR = "ADMIN_CREATE_ROLE_ERROR";

/****************************************************************/

export const ADMIN_GET_ROLE_TO_EDIT_REQUEST = "ADMIN_GET_ROLE_TO_EDIT_REQUEST";
export const ADMIN_GET_ROLE_TO_EDIT_SUCCESS = "ADMIN_GET_ROLE_TO_EDIT_SUCCESS";
export const ADMIN_GET_ROLE_TO_EDIT_ERROR = "ADMIN_GET_ROLE_TO_EDIT_ERROR";

export const ADMIN_CLEAR_ROLE_TO_EDIT = "ADMIN_CLEAR_ROLE_TO_EDIT";

/****************************************************************/

export const ADMIN_EDIT_ROLE_REQUEST = "ADMIN_EDIT_ROLE_REQUEST";
export const ADMIN_EDIT_ROLE_SUCCESS = "ADMIN_EDIT_ROLE_SUCCESS";
export const ADMIN_EDIT_ROLE_ERROR = "ADMIN_EDIT_ROLE_ERROR";

/****************************************************************/

export const ADMIN_GET_LIST_OF_PUBLISHED_FORMS_REQUEST =
  "ADMIN_GET_LIST_OF_PUBLISHED_FORMS_REQUEST";
export const ADMIN_GET_LIST_OF_PUBLISHED_FORMS_SUCCESS =
  "ADMIN_GET_LIST_OF_PUBLISHED_FORMS_SUCCESS";
export const ADMIN_GET_LIST_OF_PUBLISHED_FORMS_ERROR =
  "ADMIN_GET_LIST_OF_PUBLISHED_FORMS_ERROR";

/****************************************************************/

export const ADMIN_PUBLISH_ROLE_REQUEST = "ADMIN_PUBLISH_ROLE_REQUEST";
export const ADMIN_PUBLISH_ROLE_SUCCESS = "ADMIN_PUBLISH_ROLE_SUCCESS";
export const ADMIN_PUBLISH_ROLE_ERROR = "ADMIN_PUBLISH_ROLE_ERROR";

/****************************************************************/

export const ADMIN_UNPUBLISH_ROLE_REQUEST = "ADMIN_UNPUBLISH_ROLE_REQUEST";
export const ADMIN_UNPUBLISH_ROLE_SUCCESS = "ADMIN_UNPUBLISH_ROLE_SUCCESS";
export const ADMIN_UNPUBLISH_ROLE_ERROR = "ADMIN_UNPUBLISH_ROLE_ERROR";

/****************************************************************/

export const ADMIN_DELETE_ROLE_REQUEST = "ADMIN_DELETE_ROLE_REQUEST";
export const ADMIN_DELETE_ROLE_SUCCESS = "ADMIN_DELETE_ROLE_SUCCESS";
export const ADMIN_DELETE_ROLE_ERROR = "ADMIN_DELETE_ROLE_ERROR";

/****************************************************************/

export const ADMIN_SEARCH_SUBMISSIONS_REQUEST =
  "ADMIN_SEARCH_SUBMISSIONS_REQUEST";
export const ADMIN_SEARCH_SUBMISSIONS_SUCCESS =
  "ADMIN_SEARCH_SUBMISSIONS_SUCCESS";
export const ADMIN_SEARCH_SUBMISSIONS_ERROR = "ADMIN_SEARCH_SUBMISSIONS_ERROR";

/****************************************************************/

export const ADMIN_SUBMISSIONS_SEARCH_USERS_REQUEST =
  "ADMIN_SUBMISSIONS_SEARCH_USERS_REQUEST";
export const ADMIN_SUBMISSIONS_SEARCH_USERS_SUCCESS =
  "ADMIN_SUBMISSIONS_SEARCH_USERS_SUCCESS";
export const ADMIN_SUBMISSIONS_SEARCH_USERS_ERROR =
  "ADMIN_SUBMISSIONS_SEARCH_USERS_ERROR";

export const ADMIN_SUBMISSIONS_CLEAR_USERS = "ADMIN_SUBMISSIONS_CLEAR_USERS";

/****************************************************************/

export const ADMIN_SUBMISSIONS_GET_FORM_TYPES_REQUEST =
  "ADMIN_SUBMISSIONS_GET_FORM_TYPES_REQUEST";
export const ADMIN_SUBMISSIONS_GET_FORM_TYPES_SUCCESS =
  "ADMIN_SUBMISSIONS_GET_FORM_TYPES_SUCCESS";
export const ADMIN_SUBMISSIONS_GET_FORM_TYPES_ERROR =
  "ADMIN_SUBMISSIONS_GET_FORM_TYPES_ERROR";

/****************************************************************/

export const ADMIN_GET_SPECIFIC_SUBMISSION_REQUEST =
  "ADMIN_GET_SPECIFIC_SUBMISSION_REQUEST";
export const ADMIN_GET_SPECIFIC_SUBMISSION_SUCCESS =
  "ADMIN_GET_SPECIFIC_SUBMISSION_SUCCESS";
export const ADMIN_GET_SPECIFIC_SUBMISSION_ERROR =
  "ADMIN_GET_SPECIFIC_SUBMISSION_ERROR";

export const CLEAR_SPECIFIC_SUBMISSION = "CLEAR_SPECIFIC_SUBMISSION";

/****************************************************************/

export const ADMIN_SET_STATUS_OF_SUBMISSION_REQUEST =
  "ADMIN_SET_STATUS_OF_SUBMISSION_REQUEST";
export const ADMIN_SET_STATUS_OF_SUBMISSION_SUCCESS =
  "ADMIN_SET_STATUS_OF_SUBMISSION_SUCCESS";
export const ADMIN_SET_STATUS_OF_SUBMISSION_ERROR =
  "ADMIN_SET_STATUS_OF_SUBMISSION_ERROR";

/****************************************************************/

export const ADMIN_USER_MANAGEMENT_SEARCH_USERS_REQUEST =
  "ADMIN_USER_MANAGEMENT_SEARCH_USERS_REQUEST";
export const ADMIN_USER_MANAGEMENT_SEARCH_USERS_SUCCESS =
  "ADMIN_USER_MANAGEMENT_SEARCH_USERS_SUCCESS";
export const ADMIN_USER_MANAGEMENT_SEARCH_USERS_ERROR =
  "ADMIN_USER_MANAGEMENT_SEARCH_USERS_ERROR";

/****************************************************************/

export const ADMIN_GET_LIST_OF_ROLES_REQUEST =
  "ADMIN_GET_LIST_OF_ROLES_REQUEST";
export const ADMIN_GET_LIST_OF_ROLES_SUCCESS =
  "ADMIN_GET_LIST_OF_ROLES_SUCCESS";
export const ADMIN_GET_LIST_OF_ROLES_ERROR = "ADMIN_GET_LIST_OF_ROLES_ERROR";

/****************************************************************/

export const ADMIN_REVIEW_USER_REQUEST = "ADMIN_REVIEW_USER_REQUEST";
export const ADMIN_REVIEW_USER_SUCCESS = "ADMIN_REVIEW_USER_SUCCESS";
export const ADMIN_REVIEW_USER_ERROR = "ADMIN_REVIEW_USER_ERROR";

export const ADMIN_RESET_REVIEW_USER = "ADMIN_RESET_REVIEW_USER";

/****************************************************************/

export const ADMIN_UPDATE_USER_STATUS_REQUEST =
  "ADMIN_UPDATE_USER_STATUS_REQUEST";
export const ADMIN_UPDATE_USER_STATUS_SUCCESS =
  "ADMIN_UPDATE_USER_STATUS_SUCCESS";
export const ADMIN_UPDATE_USER_STATUS_ERROR = "ADMIN_UPDATE_USER_STATUS_ERROR";

/****************************************************************/

export const ADMIN_DELETE_USER_REQUEST = "ADMIN_DELETE_USER_REQUEST";
export const ADMIN_DELETE_USER_SUCCESS = "ADMIN_DELETE_USER_SUCCESS";
export const ADMIN_DELETE_USER_ERROR = "ADMIN_DELETE_USER_ERROR";

/****************************************************************/

export const ADMIN_GET_LIST_OF_ADMINS_REQUEST =
  "ADMIN_GET_LIST_OF_ADMINS_REQUEST";
export const ADMIN_GET_LIST_OF_ADMINS_SUCCESS =
  "ADMIN_GET_LIST_OF_ADMINS_SUCCESS";
export const ADMIN_GET_LIST_OF_ADMINS_ERROR = "ADMIN_GET_LIST_OF_ADMINS_ERROR";

export const ADMIN_CREATE_ADMIN_REQUEST = "ADMIN_CREATE_ADMIN_REQUEST";
export const ADMIN_CREATE_ADMIN_SUCCESS = "ADMIN_CREATE_ADMIN_SUCCESS";
export const ADMIN_CREATE_ADMIN_ERROR = "ADMIN_CREATE_ADMIN_ERROR";

/****************************************************************/

export const ADMIN_GET_SUBMISSION_EVENTS_REQUEST =
  "ADMIN_GET_SUBMISSION_EVENTS_REQUEST";
export const ADMIN_GET_SUBMISSION_EVENTS_SUCCESS =
  "ADMIN_GET_SUBMISSION_EVENTS_SUCCESS";
export const ADMIN_GET_SUBMISSION_EVENTS_ERROR =
  "ADMIN_GET_SUBMISSION_EVENTS_ERROR";

export const ADMIN_ADD_SUBMISSION_COMMENT_REQUEST =
  "ADMIN_ADD_SUBMISSION_COMMENT_REQUEST";
export const ADMIN_ADD_SUBMISSION_COMMENT_SUCCESS =
  "ADMIN_ADD_SUBMISSION_COMMENT_SUCCESS";
export const ADMIN_ADD_SUBMISSION_COMMENT_ERROR =
  "ADMIN_ADD_SUBMISSION_COMMENT_ERROR";

export const ADMIN_EMAIL_APPLICANT_REQUEST = "ADMIN_EMAIL_APPLICANT_REQUEST";
export const ADMIN_EMAIL_APPLICANT_SUCCESS = "ADMIN_EMAIL_APPLICANT_SUCCESS";
export const ADMIN_EMAIL_APPLICANT_ERROR = "ADMIN_EMAIL_APPLICANT_ERROR";

export const ADMIN_RESET_SUBMISSION_EVENTS = "ADMIN_RESET_SUBMISSION_EVENTS";

/****************************************************************/

export const ADMIN_GET_DASHBOARD_STATS_REQUEST =
  "ADMIN_GET_DASHBOARD_STATS_REQUEST";
export const ADMIN_GET_DASHBOARD_STATS_SUCCESS =
  "ADMIN_GET_DASHBOARD_STATS_SUCCESS";
export const ADMIN_GET_DASHBOARD_STATS_ERROR =
  "ADMIN_GET_DASHBOARD_STATS_ERROR";

export const ADMIN_GET_GRAPH_DATA_REQUEST = "ADMIN_GET_GRAPH_DATA_REQUEST";
export const ADMIN_GET_GRAPH_DATA_SUCCESS = "ADMIN_GET_GRAPH_DATA_SUCCESS";
export const ADMIN_GET_GRAPH_DATA_ERROR = "ADMIN_GET_GRAPH_DATA_ERROR";

/***********************************************************************/

export const ADMIN_COPY_FORM_REQUEST = "ADMIN_COPY_FORM_REQUEST";
export const ADMIN_COPY_FORM_SUCCESS = "ADMIN_COPY_FORM_SUCCESS";
export const ADMIN_COPY_FORM_ERROR = "ADMIN_COPY_FORM_ERROR";

/*************************************************************************/

export const ADMIN_EXPORT_PDF_REQUEST = "ADMIN_EXPORT_PDF_REQUEST";
export const ADMIN_EXPORT_PDF_SUCCESS = "ADMIN_EXPORT_PDF_SUCCESS";
export const ADMIN_EXPORT_PDF_ERROR = "ADMIN_EXPORT_PDF_ERROR";
export const ADMIN_CLEAR_PDF = "ADMIN_CLEAR_PDF";

/*************************************************************************/

export const ADMIN_DELETE_ADMIN_REQUEST = "ADMIN_DELETE_ADMIN_REQUEST";
export const ADMIN_DELETE_ADMIN_SUCCESS = "ADMIN_DELETE_ADMIN_SUCCESS";
export const ADMIN_DELETE_ADMIN_ERROR = "ADMIN_DELETE_ADMIN_ERROR";

/*************************************************************************/

export const ADMIN_EDIT_USER_REQUEST = "ADMIN_EDIT_USER_REQUEST";
export const ADMIN_EDIT_USER_SUCCESS = "ADMIN_EDIT_USER_SUCCESS";
export const ADMIN_EDIT_USER_ERROR = "ADMIN_EDIT_USER_ERROR";

export const ADMIN_EDIT_USER_RESET = "ADMIN_EDIT_USER_RESET";

/*************************************************************************/

export const ADMIN_GET_NAVBAR_LINKS_REQUEST = "ADMIN_GET_NAVBAR_LINKS_REQUEST";
export const ADMIN_GET_NAVBAR_LINKS_SUCCESS = "ADMIN_GET_NAVBAR_LINKS_SUCCESS";
export const ADMIN_GET_NAVBAR_LINKS_ERROR = "ADMIN_GET_NAVBAR_LINKS_ERROR";

/*************************************************************************/

export const ADMIN_GET_SUBMISSION_TYPES_REQUEST =
  "ADMIN_GET_SUBMISSION_TYPES_REQUEST";
export const ADMIN_GET_SUBMISSION_TYPES_SUCCESS =
  "ADMIN_GET_SUBMISSION_TYPES_SUCCESS";
export const ADMIN_GET_SUBMISSION_TYPES_ERROR =
  "ADMIN_GET_SUBMISSION_TYPES_ERROR";

/*************************************************************************/

export const IDENTITYMIND = "IDENTITYMIND";
export const IDENTITYMIND_REQUEST = "IDENTITYMIND_REQUEST";
export const IDENTITYMIND_SUCCESS = "IDENTITYMIND_SUCCESS";
export const IDENTITYMIND_ERROR = "IDENTITYMIND_ERROR";

export const ADMIN_DELETE_USER_SUBMISSION_FILE_REQUEST =
  "ADMIN_DELETE_USER_SUBMISSION_FILE_REQUEST";
export const ADMIN_DELETE_USER_SUBMISSION_FILE_SUCCESS =
  "ADMIN_DELETE_USER_SUBMISSION_FILE_SUCCESS";
export const ADMIN_DELETE_USER_SUBMISSION_FILE_ERROR =
  "ADMIN_DELETE_USER_SUBMISSION_FILE_ERROR";

export const ADMIN_EDIT_USER_SUBMISSION_REQUEST =
  "ADMIN_EDIT_USER_SUBMISSION_REQUEST";
export const ADMIN_EDIT_USER_SUBMISSION_SUCCESS =
  "ADMIN_EDIT_USER_SUBMISSION_SUCCESS";
export const ADMIN_EDIT_USER_SUBMISSION_ERROR =
  "ADMIN_EDIT_USER_SUBMISSION_ERROR";

export const ADMIN_UPLOAD_USER_SUBMISSION_FILE_REQUEST =
  "ADMIN_UPLOAD_USER_SUBMISSION_FILE_REQUEST";
export const ADMIN_UPLOAD_USER_SUBMISSION_FILE_SUCCESS =
  "ADMIN_UPLOAD_USER_SUBMISSION_FILE_SUCCESS";
export const ADMIN_UPLOAD_USER_SUBMISSION_FILE_ERROR =
  "ADMIN_UPLOAD_USER_SUBMISSION_FILE_ERROR";

export const ADMIN_GET_USER_JURISDICTION_REQUEST =
  "ADMIN_GET_USER_JURISDICTION_REQUEST";
export const ADMIN_GET_USER_JURISDICTION_SUCCESS =
  "ADMIN_GET_USER_JURISDICTION_SUCCESS";
export const ADMIN_GET_USER_JURISDICTION_ERROR =
  "ADMIN_GET_USER_JURISDICTION_ERROR";
export const ADMIN_CLEAR_USER_JURISDICTION = "ADMIN_CLEAR_USER_JURISDICTION";
