import * as CONSTANTS from "../constants/register";

const initialState = {
	roleList: {
		data: [],
		loaded: false,
		loading: false,
		error: "",
	},

	checkList: {
		data: [],
		loaded: false,
		loading: false,
		error: "",
	},

	specificUserForm: {
		data: {},
		loaded: false,
		loading: false,
		error: "",
		saving: false,
		validity: true,
		validated: false,
	},

	currentForm: "confirm",

	preregistration: "",
	applyingAs: "",
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CONSTANTS.GET_ROLE_LIST_REQUEST:
			return {
				...state,
				roleList: {
					...state.roleList,
					loaded: false,
					loading: true,
				},
			};
		case CONSTANTS.GET_ROLE_LIST_SUCCESS:
			return {
				...state,
				roleList: {
					...state.roleList,
					data: action.data,
					loaded: true,
					loading: false,
				},
			};

		case CONSTANTS.GET_CHECK_LIST_REQUEST:
			return {
				...state,
				checkList: {
					...state.checkList,
					loaded: false,
					loading: true,
				},
			};
		case CONSTANTS.GET_CHECK_LIST_SUCCESS:
			return {
				...state,
				checkList: {
					...state.checkList,
					data: action.data,
					loaded: true,
					loading: false,
				},
				// currentForm: action.currentForm,
			};

		case CONSTANTS.UPDATE_CURRENT_FORM: {
			return {
				...state,
				currentForm: action.currentForm,
			};
		}

		case CONSTANTS.GET_SPECIFIC_USER_FORM_REQUEST:
			return {
				...state,
				specificUserForm: {
					...state.specificUserForm,
					loaded: false,
					loading: true,
				},
			};
		case CONSTANTS.GET_SPECIFIC_USER_FORM_SUCCESS:
			return {
				...state,
				specificUserForm: {
					...state.specificUserForm,
					data: action.data,
					loaded: true,
					loading: false,
				},
				currentForm: action.data.name,
			};

		case CONSTANTS.EDIT_SPECIFIC_USER_FORM_REQUEST:
			return {
				...state,
				specificUserForm: {
					...state.specificUserForm,
					saving: true,
				},
			};

		case CONSTANTS.EDIT_SPECIFIC_USER_FORM_SUCCESS:
			return {
				...state,
				specificUserForm: {
					...state.specificUserForm,
					saving: false,
				},
			};

		case CONSTANTS.EDIT_SPECIFIC_USER_FORM_ERROR:
			return {
				...state,
				specificUserForm: {
					...state.specificUserForm,
					saving: false,
				},
			};

		case CONSTANTS.SUBMIT_SPECIFIC_USER_FORM_REQUEST:
			return {
				...state,
				specificUserForm: {
					...state.specificUserForm,
					saving: true,
				},
			};

		case CONSTANTS.SUBMIT_SPECIFIC_USER_FORM_SUCCESS:
			return {
				...state,
				specificUserForm: {
					...state.specificUserForm,
					saving: false,
				},
			};

		case CONSTANTS.SUBMIT_SPECIFIC_USER_FORM_ERROR:
			return {
				...state,
				specificUserForm: {
					...state.specificUserForm,
					saving: false,
				},
			};

		case CONSTANTS.REGISTRATION_UPLOAD_FILE_REQUEST:
			return {
				...state,
				specificUserForm: {
					...state.specificUserForm,
					saving: true,
				},
			};

		case CONSTANTS.DELETE_UPLOADED_FILE_REQUEST:
			return {
				...state,
				specificUserForm: {
					...state.specificUserForm,
					saving: true,
				},
			};

		case CONSTANTS.DELETE_UPLOADED_FILE_SUCCESS:
			return {
				...state,
				specificUserForm: {
					...state.specificUserForm,
					data: action.formData,
				},
			};

		case CONSTANTS.SUBMIT_PREREGISTRATION:
			return {
				...state,
				preregistration: action.flag,
				applyingAs: action.applyingAs,
			};

		case CONSTANTS.RESET_REGISTRATION_FIELDS:
			return {
				...state,
				preregistration: "",
				applyingAs: "",
			};

		default:
			return state;
	}
};
