import * as CONSTANTS from '../constants/confirmEmail'

export const confirmEmailRequest = token => ({
  type: CONSTANTS.CONFIRM_EMAIL_REQUEST,
  token
})

export const confirmEmailSuccess = () => ({
  type: CONSTANTS.CONFIRM_EMAIL_SUCCESS
})

export const confirmEmailError = error => ({
  type: CONSTANTS.CONFIRM_EMAIL_ERROR,
  error
})

export const resendConfirmationEmailRequest = () => ({
  type: CONSTANTS.RESEND_CONFIRMATION_EMAIL_REQUEST
})

export const resendConfirmationEmailSuccess = data => ({
  type: CONSTANTS.RESEND_CONFIRMATION_EMAIL_SUCCESS,
  data
})


export const resendConfirmationEmailError = error => ({
  type: CONSTANTS.RESEND_CONFIRMATION_EMAIL_ERROR,
  error
})
