import React, { PureComponent } from "react";
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  Card,
  Image,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import smallLogo from "../../images/Mid-Chains-Logo-Symbol.png";
import { IoIosEye } from "react-icons/io";
import logo from "../../images/Mid-Chains-Logo-Symbol.png";

class Forgot extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      requestSent: this.props.requestSent,
    };
  }

  componentDidMount() {
    this.props.resetForgot();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        requestSent: nextProps.requestSent,
      });
    }
  }

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleForgot = () => {
    const { email } = this.state;
    const body = { email };

    this.props.forgotRequest(body);
  };

  renderForgotPassword = () => {
    const { email } = this.state;
    const disabled = email ? false : true;

    return (
      <Container fluid className="login-container">
        <Link to={"/"}>
          <Image src={smallLogo} className="login-small-logo" />
        </Link>

        <Row noGutters className="login-row" style={{ height: "100%" }}>
          <Card className="login-box">
            <Col className="login-box-col">
              <h1>
                Please enter the email associated with your account and a reset
                link will be sent to you
              </h1>

              <FormControl
                name="email"
                placeholder="Email"
                value={email}
                onChange={this.handleOnChange}
                className="login-formcontrol"
              />

              <Row noGutters className="login-button-row">
                <Button
                  disabled={disabled}
                  onClick={this.handleForgot}
                  className="teal-button"
                >
                  Send
                </Button>
              </Row>
            </Col>
          </Card>
        </Row>
      </Container>
    );
  };

  renderRequestSent = () => {
    return (
      <Container fluid className="login-container">
        <Link to={"/"}>
          <Image src={smallLogo} className="login-small-logo" />
        </Link>

        <Row noGutters className="login-row" style={{ height: "100%" }}>
          <Card className="login-box">
            <Col className="login-box-col">
              <h1>A reset link has been sent to your email</h1>

              <Link to="/login">
                <Button
                  className="special-button"
                  style={{
                    width: "100%",
                    marginBottom: "64px",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  Sign In
                </Button>
              </Link>
            </Col>
          </Card>
        </Row>
      </Container>
    );
  };

  render() {
    let content;
    if (this.state.requestSent) {
      content = this.renderRequestSent();
    } else {
      content = this.renderForgotPassword();
    }

    return content;
  }
}

export default Forgot;
