import React, { PureComponent } from "react";
import { Row, Col, Container, Card, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import smallLogo from "../../images/Mid-Chains-Logo-Symbol.png";

export default class SessionExpired extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container fluid className="login-container">
        <Link to={"/"}>
          <Image src={smallLogo} className="login-small-logo" />
        </Link>

        <Row noGutters className="login-row" style={{ height: "100%" }}>
          <Card className="login-box">
            <Col className="login-box-col">
              <h1>Your session has expired and you have been logged out.</h1>

              <Link to="/login">
                <Button
                  className="special-button"
                  style={{
                    width: "100%",
                    marginBottom: "64px",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  Login
                </Button>
              </Link>
            </Col>
          </Card>
        </Row>
      </Container>
    );
  }
}
