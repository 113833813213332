import React, { PureComponent } from "react";
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  Spinner,
  Card,
  Image,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { IoIosEye } from "react-icons/io";
import smallLogo from "../../images/Mid-Chains-Logo-Symbol.png";
import logo from "../../images/Mid-Chains-Logo-Symbol.png";

class Reset extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmPassword: "",
      token: "",
      email: "",
      noTokenRecognized: false,
      showPassword: false,
      passwordSuccessfullyReset: this.props.passwordSuccessfullyReset,
    };
  }

  componentDidMount() {
    this.handleGetTokenAndEmailFromUrl();
    this.props.resetReset();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        passwordSuccessfullyReset: nextProps.passwordSuccessfullyReset,
      });
    }
  }

  handleGetTokenAndEmailFromUrl = () => {
    const { search } = this.props.history.location;

    if (search) {
      const splitUrl = search.split("&");
      const token = splitUrl[0].split("=")[1];
      const email = splitUrl[1].split("=")[1];

      this.setState({
        token: token,
        email: email,
      });
    } else {
      // this.props.history.push('/login')
    }
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleReset = () => {
    const { password, token, email } = this.state;
    const body = { password, token, email };

    this.props.resetRequest(body);
  };

  renderReset = () => {
    const { password, confirmPassword } = this.state;
    const disabled =
      password && confirmPassword && password === confirmPassword
        ? false
        : true;

    return (
      <Container fluid className="login-container">
        <Link to={"/"}>
          <Image src={smallLogo} className="login-small-logo" />
        </Link>

        <Row noGutters className="login-row" style={{ height: "100%" }}>
          <Card className="login-box">
            <Col className="login-box-col">
              <h1>Please enter a new password</h1>

              <div className="login-form-div">
                <FormControl
                  name="password"
                  type={this.state.showPassword ? "" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={this.handleOnChange}
                  className="login-formcontrol password-toggler"
                />
                <IoIosEye
                  onClick={this.toggleShowPassword}
                  className="password-toggle-icon"
                  id={this.state.showPassword ? "on" : "off"}
                />
              </div>

              <div className="login-form-div">
                <FormControl
                  name="confirmPassword"
                  type={this.state.showPassword ? "" : "password"}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={this.handleOnChange}
                  className="login-formcontrol password-toggler"
                />
              </div>

              <Row noGutters className="login-button-row height-auto">
                <Button
                  disabled={disabled}
                  onClick={this.handleReset}
                  className="teal-button"
                >
                  Reset Password
                </Button>
              </Row>

              <Row noGutters style={{ justifyContent: "center" }} className="height-auto">
                <Link style={{ color: "#ff4171" }} to="/login">
                  Sign In
                </Link>
              </Row>
            </Col>
          </Card>
        </Row>
      </Container>
    );
  };

  renderPasswordSuccessfullyReset = () => {
    return (
      <Container fluid className="login-container">
        <Link to={"/"}>
          <Image src={smallLogo} className="login-small-logo" />
        </Link>

        <Row noGutters className="login-row" style={{ height: "100%" }}>
          <Card className="login-box">
            <Col className="login-box-col">
              <h1>Your password has been successfully reset</h1>

              <Link to="/login">
                <Button
                  className="special-button"
                  style={{
                    width: "100%",
                    marginBottom: "64px",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  Sign In
                </Button>
              </Link>
            </Col>
          </Card>
        </Row>
      </Container>
    );
  };

  render() {
    let content;
    if (this.state.passwordSuccessfullyReset) {
      content = this.renderPasswordSuccessfullyReset();
    } else {
      content = this.renderReset();
    }
    return content;
  }
}

export default Reset;
