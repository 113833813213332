import React, { PureComponent } from "react";
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  FormCheck,
  Card,
  Image,
  NavLink,
  InputGroup,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosEye } from "react-icons/io";
import logo from "../../images/FI_LOGO.png";
import smallLogo from "../../images/Mid-Chains-Logo-Symbol.png";

export default class TokenExpired extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container fluid className="login-container">
        <Link to={"/"}>
          <Image src={smallLogo} className="login-small-logo" />
        </Link>

        <Row noGutters className="login-row" style={{ height: "100%" }}>
          <Card className="login-box">
            <Col className="login-box-col">
              <h1>
                Email confirmation link has expired. Please login on this device
                and resend the email confirmation.
              </h1>

              <h1>
                Once you received the new link, you must confirm your email
                within 10 minutes.
              </h1>

              <Link to="/login">
                <Button
                  className="special-button"
                  style={{
                    width: "100%",
                    marginBottom: "64px",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  Login
                </Button>
              </Link>

              <Row noGutters style={{ justifyContent: "center" }}>
                If you still need help, please contact{" "}
                <a
                  style={{ color: "#CF962E" }}
                  href="mailto:support@ousoul.com"
                >
                  support@ousoul.com
                </a>
              </Row>
            </Col>
          </Card>
        </Row>
      </Container>
    );
  }
}
