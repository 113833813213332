import { combineReducers } from "redux";
import authReducer from './auth'
import loginReducer from './login'
import confirmEmailReducer from './confirmEmail'
import adminReducer from './admin'
import forgotReducer from './forgot'
import resetReducer from './reset'
import registerReducer from './register'

const appReducer = combineReducers({
  auth: authReducer,
  login: loginReducer,
  confirmEmail: confirmEmailReducer,
  admin: adminReducer,
  forgot: forgotReducer,
  reset: resetReducer,
  register: registerReducer,
})

const rootReducer = (state, action) => {
  // if (action.type === 'LOGOUT_SUCCESS' || action.type === 'CLEAR_STATE') {
  //   state = undefined
  // }
  return appReducer(state, action)
}

export default rootReducer
