import { put, takeLatest, call, delay } from 'redux-saga/effects'
import { history } from '../../../configureStore'
import request from '../../../apiRequest'
import * as ACTIONS from '../../actions/auth'
import * as LOGIN_ACTIONS from '../../actions/login'
import * as CONSTANTS from '../../constants/auth'
import { toast } from 'react-toastify'

function* auth(action) {
  try {
    const responseData = yield call(
      request,
      '/account',
      'GET',
    )

    yield put(ACTIONS.authSuccess(responseData))

    // This will expire the session after a certain amount of time
    if (responseData.session_expires) {
      yield delay(responseData.session_expires)
      yield put(LOGIN_ACTIONS.logoutRequest('session_expired'))
    }

  } catch(err) {
    if (err.reason !== 'User not logged in') {
      toast.error(err.reason)
    }
    yield put(ACTIONS.authError(err))
    yield put(ACTIONS.clearState())
  }
}

export default function* authSaga() {
  yield takeLatest(CONSTANTS.AUTH_REQUEST, auth)
}
