import { connect } from "react-redux"
import ConfirmEmail from './ConfirmEmail'
import { confirmEmailRequest, resendConfirmationEmailRequest } from '../../redux/actions/confirmEmail'

const mapStateToProps = state => ({
  emailConfirmed: state.confirmEmail.emailConfirmed
})

const mapDispatchToProps = {
  confirmEmailRequest,
  resendConfirmationEmailRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)
