import * as CONSTANTS from "../constants/admin";

export const searchFormsRequest = (params) => ({
  type: CONSTANTS.ADMIN_SEARCH_FORMS_REQUEST,
  params,
});

export const searchFormsSuccess = (data) => ({
  type: CONSTANTS.ADMIN_SEARCH_FORMS_SUCCESS,
  data,
});

export const searchFormsError = (error) => ({
  type: CONSTANTS.ADMIN_SEARCH_FORMS_ERROR,
  error,
});

/****************************************/

export const createFormRequest = () => ({
  type: CONSTANTS.ADMIN_CREATE_FORM_REQUEST,
});

export const createFormSuccess = (data) => ({
  type: CONSTANTS.ADMIN_CREATE_FORM_SUCCESS,
  data,
});

export const createFormError = (error) => ({
  type: CONSTANTS.ADMIN_CREATE_FORM_ERROR,
  error,
});

/****************************************/

export const deleteFormRequest = (id) => ({
  type: CONSTANTS.ADMIN_DELETE_FORM_REQUEST,
  id,
});

export const deleteFormSuccess = (id) => ({
  type: CONSTANTS.ADMIN_DELETE_FORM_SUCCESS,
  id,
});

export const deleteFormError = (error) => ({
  type: CONSTANTS.ADMIN_DELETE_FORM_ERROR,
  error,
});

/****************************************/

export const getFormToEditRequest = (id) => ({
  type: CONSTANTS.ADMIN_GET_FORM_TO_EDIT_REQUEST,
  id,
});

export const getFormToEditSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_FORM_TO_EDIT_SUCCESS,
  data,
});

export const getFormToEditError = (error) => ({
  type: CONSTANTS.ADMIN_GET_FORM_TO_EDIT_ERROR,
  error,
});

export const clearFormToEdit = () => ({
  type: CONSTANTS.ADMIN_CLEAR_FORM_TO_EDIT,
});
/****************************************/

export const editFormRequest = (data) => ({
  type: CONSTANTS.ADMIN_EDIT_FORM_REQUEST,
  data,
});

export const editFormSuccess = () => ({
  type: CONSTANTS.ADMIN_EDIT_FORM_SUCCESS,
});

export const editFormError = (error) => ({
  type: CONSTANTS.ADMIN_EDIT_FORM_ERROR,
  error,
});

/****************************************/

export const publishFormRequest = (data) => ({
  type: CONSTANTS.ADMIN_PUBLISH_FORM_REQUEST,
  data,
});

export const publishFormSuccess = () => ({
  type: CONSTANTS.ADMIN_PUBLISH_FORM_SUCCESS,
});

export const publishFormError = (error) => ({
  type: CONSTANTS.ADMIN_PUBLISH_FORM_ERROR,
  error,
});

/****************************************/

export const unpublishFormRequest = (data) => ({
  type: CONSTANTS.ADMIN_UNPUBLISH_FORM_REQUEST,
  data,
});

export const unpublishFormSuccess = () => ({
  type: CONSTANTS.ADMIN_UNPUBLISH_FORM_SUCCESS,
});

export const unpublishFormError = (error) => ({
  type: CONSTANTS.ADMIN_UNPUBLISH_FORM_ERROR,
  error,
});

/****************************************/

export const searchRolesRequest = (params) => ({
  type: CONSTANTS.ADMIN_SEARCH_ROLES_REQUEST,
  params,
});

export const searchRolesSuccess = (data) => ({
  type: CONSTANTS.ADMIN_SEARCH_ROLES_SUCCESS,
  data,
});

export const searchRolesError = (error) => ({
  type: CONSTANTS.ADMIN_SEARCH_ROLES_ERROR,
  error,
});

/****************************************/

export const createRoleRequest = () => ({
  type: CONSTANTS.ADMIN_CREATE_ROLE_REQUEST,
});

export const createRoleSuccess = (data) => ({
  type: CONSTANTS.ADMIN_CREATE_ROLE_SUCCESS,
  data,
});

export const createRoleError = (error) => ({
  type: CONSTANTS.ADMIN_CREATE_ROLE_ERROR,
  error,
});

/****************************************/

export const getRoleToEditRequest = (id) => ({
  type: CONSTANTS.ADMIN_GET_ROLE_TO_EDIT_REQUEST,
  id,
});

export const getRoleToEditSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_ROLE_TO_EDIT_SUCCESS,
  data,
});

export const getRoleToEditError = (error) => ({
  type: CONSTANTS.ADMIN_GET_ROLE_TO_EDIT_ERROR,
  error,
});

export const clearRoleToEdit = () => ({
  type: CONSTANTS.ADMIN_CLEAR_ROLE_TO_EDIT,
});

/****************************************/

export const editRoleRequest = (data) => ({
  type: CONSTANTS.ADMIN_EDIT_ROLE_REQUEST,
  data,
});

export const editRoleSuccess = () => ({
  type: CONSTANTS.ADMIN_EDIT_ROLE_SUCCESS,
});

export const editRoleError = (error) => ({
  type: CONSTANTS.ADMIN_EDIT_ROLE_ERROR,
  error,
});

/****************************************/

export const getListOfPublishedFormsRequest = () => ({
  type: CONSTANTS.ADMIN_GET_LIST_OF_PUBLISHED_FORMS_REQUEST,
});

export const getListOfPublishedFormsSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_LIST_OF_PUBLISHED_FORMS_SUCCESS,
  data,
});

export const getListOfPublishedFormsError = (error) => ({
  type: CONSTANTS.ADMIN_GET_LIST_OF_PUBLISHED_FORMS_ERROR,
  error,
});

/****************************************/

export const publishRoleRequest = (id) => ({
  type: CONSTANTS.ADMIN_PUBLISH_ROLE_REQUEST,
  id,
});

export const publishRoleSuccess = () => ({
  type: CONSTANTS.ADMIN_PUBLISH_ROLE_SUCCESS,
});

export const publishRoleError = (error) => ({
  type: CONSTANTS.ADMIN_PUBLISH_ROLE_ERROR,
  error,
});

/****************************************/

export const unpublishRoleRequest = (id) => ({
  type: CONSTANTS.ADMIN_UNPUBLISH_ROLE_REQUEST,
  id,
});

export const unpublishRoleSuccess = () => ({
  type: CONSTANTS.ADMIN_UNPUBLISH_ROLE_SUCCESS,
});

export const unpublishRoleError = (error) => ({
  type: CONSTANTS.ADMIN_UNPUBLISH_ROLE_ERROR,
  error,
});

/****************************************/

export const deleteRoleRequest = (id) => ({
  type: CONSTANTS.ADMIN_DELETE_ROLE_REQUEST,
  id,
});

export const deleteRoleSuccess = () => ({
  type: CONSTANTS.ADMIN_DELETE_ROLE_SUCCESS,
});

export const deleteRoleError = (error) => ({
  type: CONSTANTS.ADMIN_DELETE_ROLE_ERROR,
  error,
});

/****************************************/

export const searchSubmissionsRequest = (params) => ({
  type: CONSTANTS.ADMIN_SEARCH_SUBMISSIONS_REQUEST,
  params,
});

export const searchSubmissionsSuccess = (data) => ({
  type: CONSTANTS.ADMIN_SEARCH_SUBMISSIONS_SUCCESS,
  data,
});

export const searchSubmissionsError = (error) => ({
  type: CONSTANTS.ADMIN_SEARCH_SUBMISSIONS_ERROR,
  error,
});

/****************************************/

export const submissionsSearchUsersRequest = (query) => ({
  type: CONSTANTS.ADMIN_SUBMISSIONS_SEARCH_USERS_REQUEST,
  query,
});

export const submissionsSearchUsersSuccess = (data) => ({
  type: CONSTANTS.ADMIN_SUBMISSIONS_SEARCH_USERS_SUCCESS,
  data,
});

export const submissionsSearchUsersError = (error) => ({
  type: CONSTANTS.ADMIN_SUBMISSIONS_SEARCH_USERS_ERROR,
  error,
});

export const submissionsClearUsers = () => ({
  type: CONSTANTS.ADMIN_SUBMISSIONS_CLEAR_USERS,
});

/****************************************/

export const submissionsGetFormTypesRequest = () => ({
  type: CONSTANTS.ADMIN_SUBMISSIONS_GET_FORM_TYPES_REQUEST,
});

export const submissionsGetFormTypesSuccess = (data) => ({
  type: CONSTANTS.ADMIN_SUBMISSIONS_GET_FORM_TYPES_SUCCESS,
  data,
});

export const submissionsGetFormTypesError = (error) => ({
  type: CONSTANTS.ADMIN_SUBMISSIONS_GET_FORM_TYPES_ERROR,
  error,
});

/****************************************/

export const getSpecificSubmissionRequest = (id) => ({
  type: CONSTANTS.ADMIN_GET_SPECIFIC_SUBMISSION_REQUEST,
  id,
});

export const getSpecificSubmissionSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_SPECIFIC_SUBMISSION_SUCCESS,
  data,
});

export const getSpecificSubmissionError = (error) => ({
  type: CONSTANTS.ADMIN_GET_SPECIFIC_SUBMISSION_ERROR,
  error,
});

export const clearSpecificSubmission = () => ({
  type: CONSTANTS.CLEAR_SPECIFIC_SUBMISSION,
});

/****************************************/

export const setStatusOfSubmissionRequest = (data, params) => ({
  type: CONSTANTS.ADMIN_SET_STATUS_OF_SUBMISSION_REQUEST,
  data,
  params,
});

export const setStatusOfSubmissionSuccess = (status) => ({
  type: CONSTANTS.ADMIN_SET_STATUS_OF_SUBMISSION_SUCCESS,
  status,
});

export const setStatusOfSubmissionError = (error) => ({
  type: CONSTANTS.ADMIN_SET_STATUS_OF_SUBMISSION_ERROR,
  error,
});

/****************************************/

export const userManagementSearchUsersRequest = (params) => ({
  type: CONSTANTS.ADMIN_USER_MANAGEMENT_SEARCH_USERS_REQUEST,
  params,
});

export const userManagementSearchUsersSuccess = (data) => ({
  type: CONSTANTS.ADMIN_USER_MANAGEMENT_SEARCH_USERS_SUCCESS,
  data,
});

export const userManagementSearchUsersError = (error) => ({
  type: CONSTANTS.ADMIN_USER_MANAGEMENT_SEARCH_USERS_ERROR,
  error,
});

/****************************************/

export const getListOfRolesRequest = () => ({
  type: CONSTANTS.ADMIN_GET_LIST_OF_ROLES_REQUEST,
});

export const getListOfRolesSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_LIST_OF_ROLES_SUCCESS,
  data,
});

export const getListOfRolesError = (error) => ({
  type: CONSTANTS.ADMIN_GET_LIST_OF_ROLES_ERROR,
  error,
});

/****************************************/

export const reviewUserRequest = (id) => ({
  type: CONSTANTS.ADMIN_REVIEW_USER_REQUEST,
  id,
});

export const reviewUserSuccess = (data) => ({
  type: CONSTANTS.ADMIN_REVIEW_USER_SUCCESS,
  data,
});

export const reviewUserError = (error) => ({
  type: CONSTANTS.ADMIN_REVIEW_USER_ERROR,
  error,
});

export const resetReviewUser = () => ({
  type: CONSTANTS.ADMIN_RESET_REVIEW_USER,
});

/****************************************/

export const updateUserStatusRequest = (data, params) => ({
  type: CONSTANTS.ADMIN_UPDATE_USER_STATUS_REQUEST,
  data,
  params,
});

export const updateUserStatusSuccess = (data) => ({
  type: CONSTANTS.ADMIN_UPDATE_USER_STATUS_SUCCESS,
  data,
});

export const updateUserStatusError = (error) => ({
  type: CONSTANTS.ADMIN_UPDATE_USER_STATUS_ERROR,
  error,
});

/****************************************/

export const deleteUserRequest = (id) => ({
  type: CONSTANTS.ADMIN_DELETE_USER_REQUEST,
  id,
});

export const deleteUserError = (error) => ({
  type: CONSTANTS.ADMIN_DELETE_USER_ERROR,
  error,
});

/****************************************/

export const getListOfAdminsRequest = (offset) => ({
  type: CONSTANTS.ADMIN_GET_LIST_OF_ADMINS_REQUEST,
  offset,
});

export const getListOfAdminsSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_LIST_OF_ADMINS_SUCCESS,
  data,
});

export const getListOfAdminsError = (error) => ({
  type: CONSTANTS.ADMIN_GET_LIST_OF_ADMINS_ERROR,
  error,
});

export const createAdminRequest = (data) => ({
  type: CONSTANTS.ADMIN_CREATE_ADMIN_REQUEST,
  data,
});

export const createAdminSuccess = (data) => ({
  type: CONSTANTS.ADMIN_CREATE_ADMIN_SUCCESS,
  data,
});

export const createAdminError = (error) => ({
  type: CONSTANTS.ADMIN_CREATE_ADMIN_ERROR,
  error,
});

/****************************************/

export const getSubmissionEventsRequest = (id, offset) => ({
  type: CONSTANTS.ADMIN_GET_SUBMISSION_EVENTS_REQUEST,
  id,
  offset,
});

export const getSubmissionEventsSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_SUBMISSION_EVENTS_SUCCESS,
  data,
});

export const getSubmissionEventsError = (error) => ({
  type: CONSTANTS.ADMIN_GET_SUBMISSION_EVENTS_ERROR,
  error,
});

/*************************************************************/

export const addSubmissionCommentRequest = (id, data) => ({
  type: CONSTANTS.ADMIN_ADD_SUBMISSION_COMMENT_REQUEST,
  id,
  data,
});

export const addSubmissionCommentSuccess = (data) => ({
  type: CONSTANTS.ADMIN_ADD_SUBMISSION_COMMENT_SUCCESS,
  data,
});

export const addSubmissionCommentError = (error) => ({
  type: CONSTANTS.ADMIN_ADD_SUBMISSION_COMMENT_ERROR,
  error,
});

/*************************************************************/

export const emailApplicantRequest = (data) => ({
  type: CONSTANTS.ADMIN_EMAIL_APPLICANT_REQUEST,
  data,
});

export const emailApplicantSuccess = () => ({
  type: CONSTANTS.ADMIN_EMAIL_APPLICANT_SUCCESS,
});

export const emailApplicantError = (error) => ({
  type: CONSTANTS.ADMIN_EMAIL_APPLICANT_ERROR,
  error,
});

export const resetSubmissionEvents = () => ({
  type: CONSTANTS.ADMIN_RESET_SUBMISSION_EVENTS,
});

/*************************************************************/

export const getDashboardStatsRequest = () => ({
  type: CONSTANTS.ADMIN_GET_DASHBOARD_STATS_REQUEST,
});

export const getDashboardStatsSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_DASHBOARD_STATS_SUCCESS,
  data,
});

export const getDashboardStatsError = (error) => ({
  type: CONSTANTS.ADMIN_GET_DASHBOARD_STATS_ERROR,
  error,
});

export const getGraphDataRequest = () => ({
  type: CONSTANTS.ADMIN_GET_GRAPH_DATA_REQUEST,
});

export const getGraphDataSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_GRAPH_DATA_SUCCESS,
  data,
});

export const getGraphDataError = (error) => ({
  type: CONSTANTS.ADMIN_GET_GRAPH_DATA_ERROR,
  error,
});

/*******************************************************************/

export const copyFormRequest = (id) => ({
  type: CONSTANTS.ADMIN_COPY_FORM_REQUEST,
  id,
});

export const copyFormSuccess = () => ({
  type: CONSTANTS.ADMIN_COPY_FORM_SUCCESS,
});

export const copyFormError = (error) => ({
  type: CONSTANTS.ADMIN_COPY_FORM_ERROR,
  error,
});

/*******************************************************************/

export const exportPdfRequest = (data) => ({
  type: CONSTANTS.ADMIN_EXPORT_PDF_REQUEST,
  data,
});

export const exportPdfSuccess = (data) => ({
  type: CONSTANTS.ADMIN_EXPORT_PDF_SUCCESS,
  data,
});

export const exportPdfError = (error) => ({
  type: CONSTANTS.ADMIN_EXPORT_PDF_ERROR,
  error,
});

export const clearPdf = () => ({
  type: CONSTANTS.ADMIN_CLEAR_PDF,
});

/*******************************************************************/

export const deleteAdminRequest = (id, offset) => ({
  type: CONSTANTS.ADMIN_DELETE_ADMIN_REQUEST,
  id,
  offset,
});

export const deleteAdminSuccess = () => ({
  type: CONSTANTS.ADMIN_DELETE_ADMIN_SUCCESS,
});

export const deleteAdminError = (error) => ({
  type: CONSTANTS.ADMIN_DELETE_ADMIN_ERROR,
  error,
});

/*******************************************************************/

export const editUserRequest = (params, oldStatus, searchParams) => ({
  type: CONSTANTS.ADMIN_EDIT_USER_REQUEST,
  params,
  oldStatus,
  searchParams,
});

export const editUserSuccess = () => ({
  type: CONSTANTS.ADMIN_EDIT_USER_SUCCESS,
});

export const editUserError = (error) => ({
  type: CONSTANTS.ADMIN_EDIT_USER_ERROR,
  error,
});

export const editUserReset = () => ({
  type: CONSTANTS.ADMIN_EDIT_USER_RESET,
});

/*******************************************************************/

export const getNavbarLinksRequest = () => ({
  type: CONSTANTS.ADMIN_GET_NAVBAR_LINKS_REQUEST,
});

export const getNavbarLinksSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_NAVBAR_LINKS_SUCCESS,
  data,
});

export const getNavbarLinksError = () => ({
  type: CONSTANTS.ADMIN_GET_NAVBAR_LINKS_ERROR,
});

/*******************************************************************/

export const getSubmissionTypesRequest = () => ({
  type: CONSTANTS.ADMIN_GET_SUBMISSION_TYPES_REQUEST,
});

export const getSubmissionTypesSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_SUBMISSION_TYPES_SUCCESS,
  data,
});

export const getSubmissionTypesError = (error) => ({
  type: CONSTANTS.ADMIN_GET_SUBMISSION_TYPES_ERROR,
  error,
});

/*******************************************************************/

export const identitymind = (_id) => ({
  type: CONSTANTS.IDENTITYMIND,
  _id,
});

export const identitymindRequest = (_id) => ({
  type: CONSTANTS.IDENTITYMIND_REQUEST,
  _id,
});

export const identitymindSuccess = () => ({
  type: CONSTANTS.IDENTITYMIND_SUCCESS,
});

export const identitymindError = (err) => ({
  type: CONSTANTS.IDENTITYMIND_ERROR,
  err,
});

/*******************************************************************/

export const deleteUserSubmissionFileRequest = (
  fieldChanged,
  url,
  fieldIndex,
  formData,
  subfieldDataIndex,
  subfieldIndex
) => ({
  type: CONSTANTS.ADMIN_DELETE_USER_SUBMISSION_FILE_REQUEST,
  fieldChanged,
  url,
  fieldIndex,
  formData,
  subfieldDataIndex,
  subfieldIndex,
});

export const deleteUserSubmissionFileSuccess = () => ({
  type: CONSTANTS.ADMIN_DELETE_USER_SUBMISSION_FILE_SUCCESS,
});

export const deleteUserSubmissionFileError = (err) => ({
  type: CONSTANTS.ADMIN_DELETE_USER_SUBMISSION_FILE_ERROR,
  err,
});

export const editUserSubmissionRequest = (
  data,
  fieldChanged,
  fieldAction,
  fieldIndex,
  subfieldIndex
) => ({
  type: CONSTANTS.ADMIN_EDIT_USER_SUBMISSION_REQUEST,
  data,
  fieldChanged,
  fieldAction,
  fieldIndex,
  subfieldIndex,
});

export const editUserSubmissionSuccess = (data) => ({
  type: CONSTANTS.ADMIN_EDIT_USER_SUBMISSION_SUCCESS,
  data,
});

export const editUserSubmissionError = (error) => ({
  type: CONSTANTS.ADMIN_EDIT_USER_SUBMISSION_ERROR,
  error,
});

export const uploadUserSubmissionFileRequest = (
  fieldChanged,
  fileData,
  formData,
  fieldIndex,
  subfieldDataIndex,
  subfieldIndex
) => ({
  type: CONSTANTS.ADMIN_UPLOAD_USER_SUBMISSION_FILE_REQUEST,
  fieldChanged,
  fileData,
  formData,
  fieldIndex,
  subfieldDataIndex,
  subfieldIndex,
});

export const uploadUserSubmissionFileSuccess = (fileUrl) => ({
  type: CONSTANTS.ADMIN_UPLOAD_USER_SUBMISSION_FILE_SUCCESS,
  fileUrl,
});

export const uploadUserSubmissionFileError = (error) => ({
  type: CONSTANTS.ADMIN_UPLOAD_USER_SUBMISSION_FILE_ERROR,
  error,
});

export const getUserJurisdictionRequest = () => ({
  type: CONSTANTS.ADMIN_GET_USER_JURISDICTION_REQUEST,
});

export const getUserJurisdictionSuccess = (data) => ({
  type: CONSTANTS.ADMIN_GET_USER_JURISDICTION_SUCCESS,
  data,
});

export const getUserJurisdictionError = (err) => ({
  type: CONSTANTS.ADMIN_GET_USER_JURISDICTION_ERROR,
  err,
});

export const clearUserJurisdiction = () => ({
  type: CONSTANTS.ADMIN_CLEAR_USER_JURISDICTION,
});
