import React, { PureComponent } from "react";
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  FormCheck,
  Card,
  Image,
  NavLink,
  InputGroup,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosEye } from "react-icons/io";
import logo from "../../images/FI_LOGO.png";
import smallLogo from "../../images/Mid-Chains-Logo-Symbol.png";

class Login extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      keepMeLoggedIn: true,
      showPassword: false,
    };
  }

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleLogin = (e) => {
    e.preventDefault();
    const { email, password, keepMeLoggedIn } = this.state;
    const body = { email, password, keepMeLoggedIn };

    this.props.loginRequest(body);
  };

  handleKeepMeLoggedIn = () => {
    this.setState({
      keepMeLoggedIn: !this.state.keepMeLoggedIn,
    });
  };

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  renderLogo = () => (
    <div className="login-logo-row">
      <div className="login-logo-box">
        <img src={logo} className="login-logo" />
      </div>
    </div>
  );

  render() {
    const { email, password } = this.state;
    const disabled = email && password ? false : true;

    return (
      <Container fluid className="login-container">
        <Link to={"/"}>
          <Image src={smallLogo} className="login-small-logo" />
        </Link>

        <Row noGutters className="login-row" style={{ height: "100%" }}>
          <Card className="login-box">
            <Col className="login-box-col">
              <Link to="/register">
                <Button
                  className="special-button"
                  style={{
                    width: "100%",
                    marginBottom: "64px",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  New client?
                  <br />
                  Sign up here
                </Button>
              </Link>
              <h1>
                Already have an account? <br /> Sign in below
              </h1>

              <FormControl
                name="email"
                placeholder="Email"
                value={email}
                onChange={this.handleOnChange}
                className="login-formcontrol"
              />

              <div className="login-form-div">
                <FormControl
                  name="password"
                  type={this.state.showPassword ? "" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={this.handleOnChange}
                  className="login-formcontrol password-toggler"
                />
                <IoIosEye
                  onClick={this.toggleShowPassword}
                  className="password-toggle-icon"
                  id={this.state.showPassword ? "on" : "off"}
                />
              </div>

              <Row noGutters className="login-button-row height-auto">
                <Button
                  disabled={disabled}
                  onClick={this.handleLogin}
                  className="teal-button"
                >
                  Sign In
                </Button>
              </Row>

              <Row noGutters style={{ justifyContent: "center" }} className="height-auto">
                <Link style={{ color: "#ff4171" }} to="/forgot">
                  Forgot Password?
                </Link>
              </Row>
            </Col>
          </Card>
        </Row>
      </Container>
    );
  }
}

// <div className='login-navlinks'>
//   <NavLink to='/forgot'>Forgot Password</NavLink>
// </div>

export default Login;
