import { connect } from "react-redux"
import Roles from './Roles'
import {
  searchRolesRequest,
  createRoleRequest,
  getRoleToEditRequest,
  clearRoleToEdit,
  editRoleRequest,
  getListOfPublishedFormsRequest,
  publishRoleRequest,
  unpublishRoleRequest,
  deleteRoleRequest,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  roles: state.admin.roles,
  editingRole: state.admin.editingRole,
  publishedForms: state.admin.publishedForms,
})

const mapDispatchToProps = {
  searchRolesRequest,
  createRoleRequest,
  getRoleToEditRequest,
  clearRoleToEdit,
  editRoleRequest,
  getListOfPublishedFormsRequest,
  publishRoleRequest,
  unpublishRoleRequest,
  deleteRoleRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Roles)
