import { put, takeLatest, call, delay } from "redux-saga/effects";
import { history } from "../../../configureStore";
import request from "../../../apiRequest";
import * as ACTIONS from "../../actions/admin";
import * as CONSTANTS from "../../constants/admin";
import { toast } from "react-toastify";

function* searchForms(action) {
  try {
    const { query, status } = action.params;

    let pathname = "/admin/forms/search?";
    if (query) pathname = pathname + `q=${query}&`;
    if (status) {
      const published = status === "published" ? true : false;
      pathname = pathname + `published=${published}`;
    }

    const responseData = yield call(request, pathname, "GET");

    yield put(ACTIONS.searchFormsSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
  }
}

function* createForm(action) {
  try {
    const responseData = yield call(request, "/admin/forms/create", "POST");

    yield put(ACTIONS.createFormSuccess(responseData.data));
  } catch (err) {
    toast.error(err.reason);
  }
}

function* copyForm(action) {
  try {
    const body = {
      _id: action.id,
    };
    const responseData = yield call(request, "/admin/forms/copy", "POST", body);

    yield put(ACTIONS.createFormSuccess(responseData.data));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.copyFormError(err));
  }
}

function* deleteForm(action) {
  try {
    const body = { _id: action.id };
    const responseData = yield call(
      request,
      "/admin/forms/delete",
      "POST",
      body
    );

    yield put(ACTIONS.deleteFormSuccess(action.id));
  } catch (err) {
    toast.error(err.reason);
  }
}

function* getFormToEdit(action) {
  try {
    const { id } = action;
    const responseData = yield call(
      request,
      `/admin/forms/search?_id=${id}`,
      "GET"
    );

    yield put(ACTIONS.getFormToEditSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
  }
}

function* editForm(action) {
  try {
    const body = { form: JSON.stringify(action.data) };

    const responseData = yield call(request, "/admin/forms/edit", "POST", body);

    yield put(ACTIONS.editFormSuccess());
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.editFormError(err.reason));
  }
}

function* publishForm(action) {
  try {
    const body = { _id: action.data[0]._id };
    const responseData = yield call(
      request,
      "/admin/forms/publish",
      "POST",
      body
    );

    yield put(ACTIONS.publishFormSuccess());
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.publishFormError(err.reason));
  }
}

function* unpublishForm(action) {
  try {
    const body = { _id: action.data[0]._id };
    const responseData = yield call(
      request,
      "/admin/forms/unpublish",
      "POST",
      body
    );

    yield put(ACTIONS.unpublishFormSuccess());
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.unpublishFormError(err.reason));
  }
}

/***************************************************************************/

function* searchRoles(action) {
  try {
    const responseData = yield call(request, "/admin/roles/search", "GET");

    yield put(ACTIONS.searchRolesSuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.searchRolesError(err.reason));
  }
}

function* createRole(action) {
  try {
    const responseData = yield call(request, "/admin/roles/create", "POST");

    yield put(ACTIONS.searchRolesRequest());
    yield put(ACTIONS.getRoleToEditRequest(responseData.data._id));
  } catch (err) {
    toast.error(err.reason);
  }
}

function* getRoleToEdit(action) {
  try {
    const { id } = action;
    const responseData = yield call(
      request,
      `/admin/roles/search?_id=${id}`,
      "GET"
    );

    yield put(ACTIONS.getRoleToEditSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
  }
}

function* editRole(action) {
  try {
    const body = { role: JSON.stringify(action.data) };
    const responseData = yield call(request, "/admin/roles/edit", "POST", body);

    yield put(ACTIONS.editRoleSuccess());
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.editRoleError(err.reason));
  }
}

/***************************************************************************/

function* getListOfPublishedForms(action) {
  try {
    const responseData = yield call(
      request,
      "/admin/forms/search?published=true",
      "GET"
    );

    yield put(ACTIONS.getListOfPublishedFormsSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
  }
}

function* publishRole(action) {
  try {
    const body = { _id: action.id };
    const responseData = yield call(
      request,
      "/admin/roles/publish",
      "POST",
      body
    );

    yield put(ACTIONS.publishRoleSuccess());
  } catch (err) {
    toast.error(err.reason);
  }
}

function* unpublishRole(action) {
  try {
    const body = { _id: action.id };
    const responseData = yield call(
      request,
      "/admin/roles/unpublish",
      "POST",
      body
    );

    yield put(ACTIONS.unpublishRoleSuccess());
  } catch (err) {
    toast.error(err.reason);
  }
}

function* deleteRole(action) {
  try {
    const body = { _id: action.id };
    const responseData = yield call(
      request,
      "/admin/roles/delete",
      "POST",
      body
    );

    yield put(ACTIONS.deleteRoleSuccess());
    yield put(ACTIONS.searchRolesRequest());
  } catch (err) {
    toast.error(err.reason);
  }
}

/******************************************************************************/

function* searchSubmissions(action) {
  try {
    let url = "/admin/submissions/search?";
    if (action.params) {
      const { userQuery, formTypeQuery, statusQuery, offset } = action.params;
      const encodedUriUserQuery = encodeURIComponent(userQuery);
      if (userQuery) url += `user_id=${encodedUriUserQuery}&`;
      if (formTypeQuery) url += `form_id=${formTypeQuery}&`;
      if (statusQuery) url += `status=${statusQuery}&`;
      if (offset) url += `offset=${offset}`;
    }

    const responseData = yield call(request, url, "GET");

    yield put(ACTIONS.searchSubmissionsSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.searchSubmissionsError(err));
  }
}

function* submissionsSearchUsers(action) {
  try {
    const encodedQuery = encodeURIComponent(action.query);
    const responseData = yield call(
      request,
      `/admin/users/search?q=${encodedQuery}`,
      "GET"
    );

    yield put(ACTIONS.submissionsSearchUsersSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.submissionsSearchUsersError(err));
  }
}

function* submissionsGetFormTypes(action) {
  try {
    const responseData = yield call(
      request,
      "/admin/forms/search?published=true",
      "GET"
    );

    yield put(ACTIONS.submissionsGetFormTypesSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.submissionsGetFormTypesError(err));
  }
}

function* getSpecificSubmission(action) {
  try {
    const responseData = yield call(
      request,
      `/admin/submissions/search?_id=${action.id}`,
      "GET"
    );

    yield put(ACTIONS.getSpecificSubmissionSuccess(responseData.docs[0]));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getSpecificSubmissionError(err));
  }
}

function* setStatusOfSubmission(action) {
  try {
    const body = {
      _id: action.data._id,
      status: action.data.status,
    };
    const responseData = yield call(
      request,
      "/admin/submissions/setStatus",
      "POST",
      body
    );

    yield put(ACTIONS.setStatusOfSubmissionSuccess(action.data.status));
    yield put(ACTIONS.searchSubmissionsRequest(action.params));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.setStatusOfSubmissionError(err));
  }
}

/******************************************************************************/

function* userManagementSearchUsers(action) {
  try {
    let url = "/admin/users/search?";
    if (action.params) {
      if (action.params.email)
        url = url + `q=${encodeURIComponent(action.params.email)}&`;
      if (action.params.role) url = url + `role=${action.params.role}&`;
      if (action.params.status) url = url + `status=${action.params.status}&`;
      if (action.params.offset) url = url + `offset=${action.params.offset}`;
    }

    const responseData = yield call(request, url, "GET");

    yield put(ACTIONS.userManagementSearchUsersSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.userManagementSearchUsersError(err));
  }
}

function* getListOfRoles(action) {
  try {
    const responseData = yield call(request, "/admin/users/roleList", "GET");

    yield put(ACTIONS.getListOfRolesSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getListOfRolesError(err));
  }
}

function* reviewUser(action) {
  try {
    const encodedUriUserId = encodeURIComponent(action.id);
    const responseData = yield call(
      request,
      `/admin/users/search?_id=${encodedUriUserId}`,
      "GET"
    );

    yield put(ACTIONS.reviewUserSuccess(responseData));
    if (responseData.docs[0].role._id !== "admin") {
      yield put(ACTIONS.exportPdfRequest(action.id));
    }
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.reviewUserError(err));
  }
}

function* updateUserStatus(action) {
  try {
    const body = action.data;
    const responseData = yield call(
      request,
      "/admin/users/update",
      "POST",
      body
    );

    yield put(ACTIONS.userManagementSearchUsersRequest(action.params));
  } catch (err) {
    toast.error(err.reason);
  }
}

function* deleteUser(action) {
  try {
    const body = {
      _id: action.id,
    };
    const responseData = yield call(
      request,
      "/admin/users/delete",
      "POST",
      body
    );

    yield put(ACTIONS.userManagementSearchUsersRequest());
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.deleteUserError(err));
  }
}

function* deleteAdmin(action) {
  try {
    const body = {
      _id: action.id,
    };
    const responseData = yield call(
      request,
      "/admin/users/delete",
      "POST",
      body
    );

    yield put(ACTIONS.getListOfAdminsRequest(action.offset));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.deleteUserError(err));
  }
}

function* getListOfAdmins(action) {
  try {
    let url = "/admin/users/search?role=admin";
    if (action.offset) url += `&offset=${action.offset}`;

    const responseData = yield call(request, url, "GET");

    yield put(ACTIONS.getListOfAdminsSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getListOfAdminsError(err));
  }
}

function* createAdmin(action) {
  try {
    const responseData = yield call(
      request,
      "/admin/users/createAdmin",
      "POST",
      action.data
    );

    yield put(ACTIONS.getListOfAdminsRequest());
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.createAdminError(err));
  }
}

function* getSubmissionEvents(action) {
  try {
    let url = `/admin/events/search?submission_id=${action.id}`;
    if (action.offset) url += `&offset=${action.offset}`;
    const responseData = yield call(request, url, "GET");

    yield put(ACTIONS.getSubmissionEventsSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getSubmissionEventsError(err));
  }
}

function* addSubmissionComment(action) {
  try {
    const body = {
      submission_id: action.id,
      data: action.data,
    };

    const responseData = yield call(
      request,
      "/admin/submissions/addComment",
      "POST",
      body
    );

    toast.info("Your comment has been posted");
    yield delay(2000);
    yield put(ACTIONS.getSubmissionEventsRequest(action.id));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.addSubmissionCommentError(err));
  }
}

function* emailApplicant(action) {
  try {
    const responseData = yield call(
      request,
      "/admin/submissions/email",
      "POST",
      action.data
    );

    toast.info("Your email has been sent");
    yield delay(2000);
    yield put(ACTIONS.getSubmissionEventsRequest(action.data.submission_id));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.emailApplicantError(err));
  }
}

function* getDashboardStats(action) {
  try {
    const responseData = yield call(request, "/admin/dashboard/stats", "GET");

    yield put(ACTIONS.getDashboardStatsSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getDashboardStatsError(err));
  }
}

function* getGraphData(action) {
  try {
    const responseData = yield call(request, "/admin/dashboard/graph", "GET");

    yield put(ACTIONS.getGraphDataSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getGraphDataError(err));
  }
}

function* exportPdf(action) {
  try {
    const body = {
      user_id: action.data,
    };
    const responseData = yield call(
      request,
      "/admin/users/generatePDFData",
      "POST",
      body
    );

    yield put(ACTIONS.exportPdfSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.exportPdfError(err));
  }
}

function* editUser(action) {
  try {
    const responseData = yield call(
      request,
      "/admin/users/edit",
      "POST",
      action.params
    );

    if (action.oldStatus) {
      const data = {
        status: action.params.status,
        _id: action.params._id,
      };
      yield put(ACTIONS.updateUserStatusRequest(data, action.searchParams));
    }

    yield put(ACTIONS.editUserSuccess());
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.editUserError(err));
  }
}

function* getNavbarLinks(action) {
  try {
    const responseData = yield call(request, "/links", "GET");
    console.log(responseData);
    yield put(ACTIONS.getNavbarLinksSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getNavbarLinksError(err));
  }
}

function* getSubmissionTypes(action) {
  try {
    const responseData = yield call(
      request,
      "/admin/forms/submissionTypes",
      "GET"
    );

    yield put(ACTIONS.getSubmissionTypesSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getSubmissionTypesError(err));
  }
}

function* identitymind(action) {
  try {
    const responseData = yield call(
      request,
      "/admin/submissions/identitymind",
      "POST",
      { _id: action._id }
    );

    toast.info("This form has been submitted to IDM.");
    yield put(ACTIONS.identitymindSuccess());
  } catch (err) {
    toast.error(`Identity Mind error: ${err.reason}`);
    yield put(ACTIONS.identitymindError());
  }
}

function* deleteUserSubmissionFile(action) {
  try {
    const {
      url,
      fieldIndex,
      formData,
      subfieldDataIndex,
      subfieldIndex,
    } = action;

    const body = {
      url: url,
    };

    const responseData = yield call(
      request,
      "/admin/submissions/deleteFile",
      "POST",
      body
    );

    if (subfieldDataIndex >= 0) {
      formData.fields[fieldIndex].subfields[subfieldIndex].data[
        subfieldDataIndex
      ].value = "";
    } else {
      formData.fields[fieldIndex].value = "";
    }
    yield put(ACTIONS.deleteUserSubmissionFileSuccess(formData));
    yield put(
      ACTIONS.editUserSubmissionRequest(
        formData,
        action.fieldChanged,
        "deleted"
      )
    );
  } catch (err) {
    toast.error(err.reason);
    if (err.reason == "File not found") {
      const {
        url,
        fieldIndex,
        formData,
        subfieldDataIndex,
        subfieldIndex,
      } = action;
      if (subfieldDataIndex >= 0) {
        formData.fields[fieldIndex].subfields[subfieldIndex].data[
          subfieldDataIndex
        ].value = "";
      } else {
        formData.fields[fieldIndex].value = "";
      }

      yield put(ACTIONS.deleteUserSubmissionFileSuccess(formData));
      yield put(
        ACTIONS.editUserSubmissionRequest(formData, action.fieldChanged)
      );
    }

    yield put(ACTIONS.deleteUserSubmissionFileError(err));
  }
}

function* editUserSubmission(action) {
  try {
    const body = {
      submission: JSON.stringify(action.data),
      fieldChanged: action.fieldChanged,
      fieldAction: action.fieldAction,
    };

    const responseData = yield call(
      request,
      "/admin/submissions/edit",
      "POST",
      body
    );

    yield put(ACTIONS.editUserSubmissionSuccess(action.data));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.editUserSubmissionError(err.reason));
  }
}

function* uploadFile(action) {
  try {
    const {
      fileData,
      formData,
      fieldIndex,
      subfieldDataIndex,
      subfieldIndex,
    } = action;
    let newFileData = new FormData();
    newFileData.append("file", fileData);

    const responseData = yield call(
      request,
      "/admin/submissions/uploadFile",
      "POST",
      newFileData,
      false,
      true
    );

    const fileUrl = responseData.url;
    if (subfieldDataIndex >= 0) {
      formData.fields[fieldIndex].subfields[subfieldIndex].data[
        subfieldDataIndex
      ].value = fileUrl;
    } else {
      formData.fields[fieldIndex].value = fileUrl;
    }

    yield put(
      ACTIONS.editUserSubmissionRequest(
        formData,
        action.fieldChanged,
        "uploaded"
      )
    );
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.uploadUserSubmissionFileError(err));
  }
}

function* getUserJurisdiction(action) {
  try {
    const responseData = yield call(
      request,
      "/admin/users/reports/jurisdiction",
      "GET"
    );

    yield put(ACTIONS.getUserJurisdictionSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getUserJurisdictionError(err));
  }
}

export default function* adminSaga() {
  yield takeLatest(CONSTANTS.ADMIN_SEARCH_FORMS_REQUEST, searchForms);
  yield takeLatest(CONSTANTS.ADMIN_CREATE_FORM_REQUEST, createForm);
  yield takeLatest(CONSTANTS.ADMIN_DELETE_FORM_REQUEST, deleteForm);
  yield takeLatest(CONSTANTS.ADMIN_GET_FORM_TO_EDIT_REQUEST, getFormToEdit);
  yield takeLatest(CONSTANTS.ADMIN_EDIT_FORM_REQUEST, editForm);
  yield takeLatest(CONSTANTS.ADMIN_PUBLISH_FORM_REQUEST, publishForm);
  yield takeLatest(CONSTANTS.ADMIN_UNPUBLISH_FORM_REQUEST, unpublishForm);
  yield takeLatest(CONSTANTS.ADMIN_COPY_FORM_REQUEST, copyForm);
  yield takeLatest(CONSTANTS.ADMIN_SEARCH_ROLES_REQUEST, searchRoles);
  yield takeLatest(CONSTANTS.ADMIN_CREATE_ROLE_REQUEST, createRole);
  yield takeLatest(CONSTANTS.ADMIN_GET_ROLE_TO_EDIT_REQUEST, getRoleToEdit);
  yield takeLatest(CONSTANTS.ADMIN_EDIT_ROLE_REQUEST, editRole);
  yield takeLatest(
    CONSTANTS.ADMIN_GET_LIST_OF_PUBLISHED_FORMS_REQUEST,
    getListOfPublishedForms
  );
  yield takeLatest(CONSTANTS.ADMIN_PUBLISH_ROLE_REQUEST, publishRole);
  yield takeLatest(CONSTANTS.ADMIN_UNPUBLISH_ROLE_REQUEST, unpublishRole);
  yield takeLatest(CONSTANTS.ADMIN_DELETE_ROLE_REQUEST, deleteRole);
  yield takeLatest(
    CONSTANTS.ADMIN_SEARCH_SUBMISSIONS_REQUEST,
    searchSubmissions
  );
  yield takeLatest(
    CONSTANTS.ADMIN_SUBMISSIONS_SEARCH_USERS_REQUEST,
    submissionsSearchUsers
  );
  yield takeLatest(
    CONSTANTS.ADMIN_SUBMISSIONS_GET_FORM_TYPES_REQUEST,
    submissionsGetFormTypes
  );
  yield takeLatest(
    CONSTANTS.ADMIN_GET_SPECIFIC_SUBMISSION_REQUEST,
    getSpecificSubmission
  );
  yield takeLatest(
    CONSTANTS.ADMIN_SET_STATUS_OF_SUBMISSION_REQUEST,
    setStatusOfSubmission
  );
  yield takeLatest(
    CONSTANTS.ADMIN_USER_MANAGEMENT_SEARCH_USERS_REQUEST,
    userManagementSearchUsers
  );
  yield takeLatest(CONSTANTS.ADMIN_GET_LIST_OF_ROLES_REQUEST, getListOfRoles);
  yield takeLatest(CONSTANTS.ADMIN_REVIEW_USER_REQUEST, reviewUser);
  yield takeLatest(
    CONSTANTS.ADMIN_UPDATE_USER_STATUS_REQUEST,
    updateUserStatus
  );
  yield takeLatest(CONSTANTS.ADMIN_DELETE_USER_REQUEST, deleteUser);
  yield takeLatest(CONSTANTS.ADMIN_GET_LIST_OF_ADMINS_REQUEST, getListOfAdmins);
  yield takeLatest(CONSTANTS.ADMIN_CREATE_ADMIN_REQUEST, createAdmin);
  yield takeLatest(
    CONSTANTS.ADMIN_GET_SUBMISSION_EVENTS_REQUEST,
    getSubmissionEvents
  );
  yield takeLatest(
    CONSTANTS.ADMIN_ADD_SUBMISSION_COMMENT_REQUEST,
    addSubmissionComment
  );
  yield takeLatest(CONSTANTS.ADMIN_EMAIL_APPLICANT_REQUEST, emailApplicant);
  yield takeLatest(
    CONSTANTS.ADMIN_GET_DASHBOARD_STATS_REQUEST,
    getDashboardStats
  );
  yield takeLatest(CONSTANTS.ADMIN_GET_GRAPH_DATA_REQUEST, getGraphData);
  yield takeLatest(CONSTANTS.ADMIN_EXPORT_PDF_REQUEST, exportPdf);
  yield takeLatest(CONSTANTS.ADMIN_DELETE_ADMIN_REQUEST, deleteAdmin);
  yield takeLatest(CONSTANTS.ADMIN_EDIT_USER_REQUEST, editUser);
  yield takeLatest(CONSTANTS.ADMIN_GET_NAVBAR_LINKS_REQUEST, getNavbarLinks);
  yield takeLatest(
    CONSTANTS.ADMIN_GET_SUBMISSION_TYPES_REQUEST,
    getSubmissionTypes
  );
  yield takeLatest(CONSTANTS.IDENTITYMIND_REQUEST, identitymind);
  yield takeLatest(
    CONSTANTS.ADMIN_DELETE_USER_SUBMISSION_FILE_REQUEST,
    deleteUserSubmissionFile
  );
  yield takeLatest(
    CONSTANTS.ADMIN_EDIT_USER_SUBMISSION_REQUEST,
    editUserSubmission
  );
  yield takeLatest(
    CONSTANTS.ADMIN_UPLOAD_USER_SUBMISSION_FILE_REQUEST,
    uploadFile
  );

  yield takeLatest(
    CONSTANTS.ADMIN_GET_USER_JURISDICTION_REQUEST,
    getUserJurisdiction
  );
}
