import React, { PureComponent } from "react";
import {
	Row,
	Col,
	Container,
	FormControl,
	Button,
	Table,
	Spinner,
	Modal,
	FormCheck,
	Accordion,
	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";
import {
	AiOutlineSearch,
	AiOutlineEdit,
	AiOutlineDelete,
	AiOutlineCopy,
	AiOutlineUp,
	AiOutlineDown,
} from "react-icons/ai";
import { IoIosArrowRoundBack } from "react-icons/io";
import { NavLink } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ReactHtmlParser from "react-html-parser";
import * as COMPONENT from "./";
import placeholder from "../../../images/placeholder.png";
const arrayMove = require("array-move");

class Forms extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			query: "",
			status: "",
			forms: {
				data: {},
				loading: false,
				loaded: false,
				error: "",
			},
			editingForm: {
				data: {},
				saving: false,
				loading: false,
				loaded: false,
				error: "",
			},
			currentDropdownIndex: "",
			showDropdownOptionsModal: false,

			currentNumberIndex: "",
			showNumberModal: false,

			showBranchModal: false,
			currentBranchIndex: "",
			newBranchSubfield: {
				show: false,
				name: "",
			},
			showSubfieldDropdownOptionsModal: false,
			currentSubfieldDropdownIndex: "",
			currentBranchSubfieldIndex: "",
			showSubfieldNumberModal: false,
			currentSubfieldNumberIndex: "",
			showPreviewModal: false,
			currentUrlIndex: "",
			showUrlModal: false,
			submissionTypes: this.props.submissionTypes,
		};
	}

	modules = {
		toolbar: {
			container: [
				["bold", "italic", "underline"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" },
				],
				["link", "image"],
			],
			handlers: {
				image: this.imageHandler,
			},
		},
	};

	formats = ["bold", "italic", "underline", "link", "image", "align"];

	componentDidMount() {
		this.handleSearchForms();
		this.handleClearFormToEdit();
		this.props.getSubmissionTypesRequest();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				forms: nextProps.forms,
				editingForm: nextProps.editingForm,
				submissionTypes: nextProps.submissionTypes,
			});
		}
	}

	/***************************************************************************/

	imageHandler() {
		var range = this.quill.getSelection();
		var value = prompt("Insert the image url here.");
		if (value) {
			let change = this.quill.insertEmbed(
				range.index,
				"image",
				value,
				"api"
			);
			this.quill.setSelection(range.index + 1);
		}
	}

	handleOnChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value }, () => this.handleSearchForms());
	};

	handleOnChangeFormData = (e, fieldIndex) => {
		const { name, value } = e.target;
		let newFormData = this.state.editingForm.data[0].fields[fieldIndex];
		newFormData[name] = value;

		this.handleUpdateFormData();
	};

	handleOnChangeFieldDescription = (data) => {
		const { value, fieldIndex } = data;
		let newFormData = this.state.editingForm.data[0].fields[fieldIndex];
		newFormData.fieldDescription = value;

		this.handleUpdateFormData();
	};

	handleOnChangeFieldType = (e, fieldIndex) => {
		const fieldType = e.target.value;
		let newFields = this.state.editingForm.data[0].fields;
		newFields[fieldIndex].fieldType = fieldType;

		/*
      this is necessary for allowing dropdowns to have arrays of content
    */
		if (fieldType === "dropdown") {
			newFields[fieldIndex].options = [""];
			newFields[fieldIndex].subfields = [];
		} else if (fieldType === "branch") {
			newFields[fieldIndex].options = [""];
			newFields[fieldIndex].subfields = [{ name: "", data: [] }];
		} else if (fieldType == "idmplugin") {
			newFields[fieldIndex].url = "";
		} else if (fieldType === "checkbox") {
			newFields[fieldIndex].options = [""];
			newFields[fieldIndex].subfields = [
				{ fieldName: "", fieldType: "string" },
			];
		} else {
			newFields[fieldIndex].options = [];
			newFields[fieldIndex].subfields = [];
		}

		this.handleUpdateFormData();
	};

	handleOnChangeRequired = (fieldIndex) => {
		let newFormData = this.state.editingForm.data[0].fields[fieldIndex];
		newFormData.required = !newFormData.required;

		this.handleUpdateFormData();
	};

	handleOnChangeReject = (fieldIndex, option) => {
		let newFormData = this.state.editingForm.data[0].fields[fieldIndex];
		newFormData.reject.push(option);

		this.handleUpdateFormData();
	};

	handleUpdateFormData = () => {
		this.setState(
			{
				editingForm: {
					...this.state.editingForm,
				},
			},
			() => {
				const { data } = this.state.editingForm;

				this.props.editFormRequest(data[0]);
			}
		);
	};

	handleOnChangeFormName = (e) => {
		const { value } = e.target;
		let newFormData = this.state.editingForm.data[0];
		newFormData.name = value;

		this.handleUpdateFormData();
	};

	handleOnChangeFormDescription = (value) => {
		let newFormData = this.state.editingForm.data[0];
		newFormData.description = value;

		this.handleUpdateFormData();
	};

	handleOnChangeFormOnSubmit = (e) => {
		const { value } = e.target;
		let newFormData = this.state.editingForm.data[0];
		newFormData.onSubmit = value;

		this.handleUpdateFormData();
	};

	handleSearchForms = () => {
		const { query, status } = this.state;
		const params = { query, status };

		this.props.searchFormsRequest(params);
	};

	handleCreateForm = () => {
		this.props.createFormRequest();
	};

	handleDeleteForm = (id) => {
		this.props.deleteFormRequest(id);
	};

	handleGetFormToEdit = (id) => {
		this.props.getFormToEditRequest(id);
	};

	handleClearFormToEdit = () => {
		this.props.clearFormToEdit();
		this.handleSearchForms();
	};

	handleAddField = () => {
		const singleField = {
			fieldName: "",
			fieldType: "string",
			required: false,
		};
		let newFields = this.state.editingForm.data[0].fields;
		newFields.push(singleField);

		this.handleUpdateFormData();
	};

	handleDeleteField = (fieldIndex) => {
		let newFields = this.state.editingForm.data[0].fields;
		newFields.splice(fieldIndex, 1);

		this.handleUpdateFormData();
	};

	handlePublishForm = () => {
		const { data } = this.state.editingForm;
		this.props.publishFormRequest(data);
	};

	handleUnpublishForm = () => {
		const { data } = this.state.editingForm;
		this.props.unpublishFormRequest(data);
	};

	handleToggleDropdownOptionsModal = () => {
		this.setState({
			showDropdownOptionsModal: !this.state.showDropdownOptionsModal,
		});
	};

	handleCloseDropdownModal = () => {
		this.setState({
			currentDropdownIndex: "",
			showDropdownOptionsModal: false,
		});
	};

	handleCloseBranchModal = () => {
		this.setState({
			currentBranchIndex: "",
			showBranchModal: false,
		});
	};

	handleCloseUrlModal = () => {
		this.setState({
			currentUrlIndex: "",
			showUrlModal: false,
		});
	};

	handleShowDropdownModal = (fieldIndex) => {
		this.setState({
			currentDropdownIndex: fieldIndex,
			showDropdownOptionsModal: true,
		});
	};

	handleShowNumberModal = (fieldIndex) => {
		this.setState({
			currentNumberIndex: fieldIndex,
			showNumberModal: true,
		});
	};

	handleShowUrlModal = (fieldIndex) => {
		this.setState({
			currentUrlIndex: fieldIndex,
			showUrlModal: true,
		});
	};

	handleShowBranchModal = (fieldIndex) => {
		this.setState({
			currentBranchIndex: fieldIndex,
			showBranchModal: true,
		});
	};

	handleShowCheckboxModal = (fieldIndex) => {
		this.setState({
			currentCheckboxIndex: fieldIndex,
			showCheckboxModal: true,
		});
	};

	handleAddDropdownOption = () => {
		const { currentDropdownIndex } = this.state;
		const options =
			this.state.editingForm.data[0].fields[currentDropdownIndex].options;
		options.push("");

		this.handleUpdateFormData();
	};

	handleAddSubfieldDropdownOption = () => {
		const {
			currentSubfieldDropdownIndex,
			currentBranchSubfieldIndex,
			currentBranchIndex,
		} = this.state;
		const options =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields[
				currentBranchSubfieldIndex
			].data[currentSubfieldDropdownIndex].options;
		options.push("");

		this.handleUpdateFormData();
	};

	handleAddBranch = () => {
		const { currentBranchIndex } = this.state;
		const subfields =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields;
		subfields.push({ name: "", data: [] });

		this.handleUpdateFormData();
	};

	handleAddSubfieldToBranch = (subfieldIndex) => {
		const { currentBranchIndex } = this.state;
		const subfields =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields;
		subfields[subfieldIndex].data.push({
			fieldName: "",
			fieldType: "string",
		});

		this.handleUpdateFormData();
	};

	handleChangeBranchName = (e, subfieldIndex) => {
		const { currentBranchIndex } = this.state;
		const subfields =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields;

		subfields[subfieldIndex].name = e.target.value;

		/*
      check if the current value of the dropdown option exists in the reject Array.
      If it is, remove it from the array and THEN proceed to edit.
    */
		// const rejectArray = this.state.editingForm.data[0].fields[currentBranchIndex].reject
		// if (rejectArray.includes(subfields[e.target.value])) {
		//   const index = rejectArray.indexOf(subfields[e.target.value])
		//   rejectArray.splice(index, 1)
		// }

		this.handleUpdateFormData();
	};

	handleChangeSubfieldContent = (e, subfieldIndex, branchIndex) => {
		const { currentBranchIndex } = this.state;
		const subfields =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields;
		if (e.target.name == "required") {
			subfields[branchIndex].data[subfieldIndex].required =
				!subfields[branchIndex].data[subfieldIndex].required;
		} else {
			subfields[branchIndex].data[subfieldIndex][e.target.name] =
				e.target.value;
		}

		if (e.target.name == "fieldType" && e.target.value == "dropdown") {
			subfields[branchIndex].data[subfieldIndex].options = [""];
		} else {
			subfields[branchIndex].data[subfieldIndex].options = [];
		}

		this.handleUpdateFormData();
	};

	handleAddCheckboxOption = () => {
		const { currentCheckboxIndex } = this.state;
		const options =
			this.state.editingForm.data[0].fields[currentCheckboxIndex].options;

		if (options.length < 7) {
			options.push("");
			this.handleUpdateFormData();
		}
	};

	handleAddCheckboxSubfield = () => {
		const { currentCheckboxIndex } = this.state;
		const subfields =
			this.state.editingForm.data[0].fields[currentCheckboxIndex]
				.subfields;
		subfields.push({ fieldName: "", fieldType: "string" });

		this.handleUpdateFormData();
	};

	handleDeleteCheckboxOption = (optionIndex) => {
		const { currentCheckboxIndex } = this.state;
		const options =
			this.state.editingForm.data[0].fields[currentCheckboxIndex].options;
		options.splice(optionIndex, 1);

		this.handleUpdateFormData();
	};

	handleDeleteCheckboxSubfield = (subfieldIndex) => {
		const { currentCheckboxIndex } = this.state;
		const subfields =
			this.state.editingForm.data[0].fields[currentCheckboxIndex]
				.subfields;
		subfields.splice(subfieldIndex, 1);

		this.handleUpdateFormData();
	};

	handleEditCheckboxOption = (e, optionIndex) => {
		const { currentCheckboxIndex } = this.state;
		const options =
			this.state.editingForm.data[0].fields[currentCheckboxIndex].options;

		if (e.target.value.length < 155) {
			options[optionIndex] = e.target.value;
			this.handleUpdateFormData();
		}
	};

	handleEditCheckboxSubfield = (e, subfieldIndex) => {
		const { currentCheckboxIndex } = this.state;
		const subfields =
			this.state.editingForm.data[0].fields[currentCheckboxIndex]
				.subfields;

		if (e.target.value.length < 255) {
			subfields[subfieldIndex].fieldName = e.target.value;
			this.handleUpdateFormData();
		}
	};

	handleChangeDropdownOptionValue = (e, optionIndex) => {
		const { currentDropdownIndex } = this.state;
		const options =
			this.state.editingForm.data[0].fields[currentDropdownIndex].options;

		/*
      check if the current value of the dropdown option exists in the reject Array.
      If it is, remove it from the array and THEN proceed to edit.
    */
		const rejectArray =
			this.state.editingForm.data[0].fields[currentDropdownIndex].reject;
		if (rejectArray.includes(options[optionIndex])) {
			const index = rejectArray.indexOf(options[optionIndex]);
			rejectArray.splice(index, 1);
		}

		options[optionIndex] = e.target.value;

		this.handleUpdateFormData();
	};

	handleChangeSubfieldDropdownOptionValue = (e, optionIndex) => {
		const {
			currentSubfieldDropdownIndex,
			currentBranchSubfieldIndex,
			currentBranchIndex,
		} = this.state;
		const currentDropdown =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields[
				currentBranchSubfieldIndex
			].data[currentSubfieldDropdownIndex];
		const options = currentDropdown.options;

		/*
      check if the current value of the dropdown option exists in the reject Array.
      If it is, remove it from the array and THEN proceed to edit.
    */
		const rejectArray = currentDropdown.reject;
		if (rejectArray.includes(options[optionIndex])) {
			const index = rejectArray.indexOf(options[optionIndex]);
			rejectArray.splice(index, 1);
		}

		options[optionIndex] = e.target.value;

		this.handleUpdateFormData();
	};

	handleDeleteBranch = (branchIndex) => {
		const { currentBranchIndex } = this.state;
		const subfields =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields;
		const branchName = subfields[branchIndex].name;
		subfields.splice(branchIndex, 1);

		this.handleRemoveOptionFromReject(branchName, currentBranchIndex);
		// this.handleUpdateFormData()
	};

	handleDeleteSubfield = (subfieldIndex, branchIndex) => {
		const { currentBranchIndex } = this.state;
		const subfields =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields;
		subfields[branchIndex].data.splice(subfieldIndex, 1);

		this.handleUpdateFormData();
	};

	handleDeleteDropdownOption = (optionIndex) => {
		const { currentDropdownIndex } = this.state;
		const options =
			this.state.editingForm.data[0].fields[currentDropdownIndex].options;
		options.splice(optionIndex, 1);

		this.handleUpdateFormData();
	};

	handleAddOptionToReject = (option, optionIndex) => {
		const rejectArray =
			this.state.editingForm.data[0].fields[optionIndex].reject;

		if (option) {
			rejectArray.push(option);
			this.handleUpdateFormData();
		}
	};

	handleRemoveOptionFromReject = (option, optionIndex) => {
		const rejectArray =
			this.state.editingForm.data[0].fields[optionIndex].reject;
		for (let i = 0; i < rejectArray.length; i++) {
			if (rejectArray[i] == option) {
				rejectArray.splice(i, 1);
			}
		}

		this.handleUpdateFormData();
	};

	handleDeleteSubfieldDropdownOption = (optionIndex) => {
		const {
			currentBranchIndex,
			currentBranchSubfieldIndex,
			currentSubfieldDropdownIndex,
		} = this.state;
		const options =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields[
				currentBranchSubfieldIndex
			].data[currentSubfieldDropdownIndex].options;
		const option = options[optionIndex];
		options.splice(optionIndex, 1);

		this.handleRemoveOptionFromSubfieldDropdownReject(option, optionIndex);
	};

	handleAddOptionToSubfieldDropdownReject = (option, optionIndex) => {
		const {
			currentBranchIndex,
			currentBranchSubfieldIndex,
			currentSubfieldDropdownIndex,
		} = this.state;
		const rejectArray =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields[
				currentBranchSubfieldIndex
			].data[currentSubfieldDropdownIndex].reject;

		if (option) {
			rejectArray.push(option);
			this.handleUpdateFormData();
		}
	};

	handleRemoveOptionFromSubfieldDropdownReject = (option, optionIndex) => {
		const {
			currentBranchIndex,
			currentBranchSubfieldIndex,
			currentSubfieldDropdownIndex,
		} = this.state;
		const rejectArray =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields[
				currentBranchSubfieldIndex
			].data[currentSubfieldDropdownIndex].reject;
		for (let i = 0; i < rejectArray.length; i++) {
			if (rejectArray[i] == option) {
				rejectArray.splice(i, 1);
			}
		}

		this.handleUpdateFormData();
	};

	handleChangeRejectGreaterThan = (e) => {
		const { currentNumberIndex } = this.state;
		const numberObject =
			this.state.editingForm.data[0].fields[currentNumberIndex];
		numberObject.reject_greater_than = e.target.value;

		this.handleUpdateFormData();
	};

	handleChangeUrl = (e) => {
		const { currentUrlIndex } = this.state;
		const field = this.state.editingForm.data[0].fields[currentUrlIndex];
		field.url = e.target.value;

		this.handleUpdateFormData();
	};

	handleChangeRejectLessThan = (e) => {
		const { currentNumberIndex } = this.state;
		const numberObject =
			this.state.editingForm.data[0].fields[currentNumberIndex];
		numberObject.reject_less_than = e.target.value;

		this.handleUpdateFormData();
	};

	handleChangeSubfieldRejectGreaterThan = (e) => {
		const {
			currentBranchIndex,
			currentSubfieldNumberIndex,
			currentBranchSubfieldIndex,
		} = this.state;
		const numberObject =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields[
				currentBranchSubfieldIndex
			].data[currentSubfieldNumberIndex];
		numberObject.reject_greater_than = e.target.value;

		this.handleUpdateFormData();
	};

	handleChangeSubfieldRejectLessThan = (e) => {
		const {
			currentBranchIndex,
			currentSubfieldNumberIndex,
			currentBranchSubfieldIndex,
		} = this.state;
		const numberObject =
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields[
				currentBranchSubfieldIndex
			].data[currentSubfieldNumberIndex];
		numberObject.reject_less_than = e.target.value;

		this.handleUpdateFormData();
	};

	handleCloseNumberModal = () => {
		this.setState({
			showNumberModal: false,
		});
	};

	handleToggleSubfieldDropdownOptionsModal = (subfieldIndex, branchIndex) => {
		if (this.state.showSubfieldDropdownOptionsModal) {
			this.setState({
				showBranchModal: true,
				showSubfieldDropdownOptionsModal: false,
				currentSubfieldDropdownIndex: "",
				currentBranchSubfieldIndex: "",
			});
		} else {
			this.setState({
				showBranchModal: false,
				showSubfieldDropdownOptionsModal: true,
				currentSubfieldDropdownIndex: subfieldIndex,
				currentBranchSubfieldIndex: branchIndex,
			});
		}
	};

	handleToggleSubfieldNumberModal = (subfieldIndex, branchIndex) => {
		if (this.state.showSubfieldNumberModal) {
			this.setState({
				showBranchModal: true,
				showSubfieldNumberModal: false,
				currentSubfieldNumberIndex: "",
				currentBranchSubfieldIndex: "",
			});
		} else {
			this.setState({
				showBranchModal: false,
				showSubfieldNumberModal: true,
				currentSubfieldNumberIndex: subfieldIndex,
				currentBranchSubfieldIndex: branchIndex,
			});
		}
	};

	handleCopyField = (field) => {
		let newField = { ...field };
		newField.fieldName = newField.fieldName + " - copy";
		let newFormData = this.state.editingForm.data[0].fields;
		newFormData.push(newField);

		this.handleUpdateFormData();
	};

	/***************************************************************************/

	renderFormList = () => {
		return (
			<React.Fragment>
				<Row noGutters className="forms-header">
					<h4>Form List</h4>
				</Row>

				<div className="forms-table-box">
					<Row noGutters className="forms-search">
						<Col sm={4}>{this.renderSearchBar()}</Col>

						<Col sm={4}>{this.renderDropdown()}</Col>
					</Row>

					<Row noGutters className="forms-table-row">
						{this.renderTable()}
					</Row>

					<Row noGutters className="forms-create-button-row">
						<Button
							onClick={this.handleCreateForm}
							className="teal-button"
						>
							Create Form
						</Button>
					</Row>
				</div>
			</React.Fragment>
		);
	};

	renderSearchBar = () => {
		const { query } = this.state;
		return (
			<div className="form-search-container">
				Search:
				<FormControl
					placeholder="Search by form name..."
					name="query"
					onChange={this.handleOnChange}
					value={query}
					size="sm"
				/>
			</div>
		);
	};

	renderDropdown = () => {
		const { status } = this.state;
		return (
			<FormControl
				as="select"
				name="status"
				className="form-dropdown"
				size="sm"
				value={status}
				onChange={this.handleOnChange}
			>
				<option value="">Status</option>
				<option value="published">Published</option>
				<option value="un-published">Unpublished</option>
			</FormControl>
		);
	};

	renderTable = () => {
		const { forms } = this.state;

		let body;
		if (forms.loaded) {
			if (forms.data.length > 0) {
				body = forms.data.map((form) =>
					this.renderIndividualForm(form)
				);
			} else if (forms.data.length === 0) {
				body = (
					<tr>
						<td colSpan="2" className="no-forms-table">
							No forms
						</td>
					</tr>
				);
			}
		} else if (forms.loading) {
			body = (
				<tr>
					<td colSpan="2">{this.renderLoading()}</td>
				</tr>
			);
		} else if (forms.error) {
			body = (
				<tr>
					<td colSpan="2" className="no-forms-table no-forms-error">
						{this.forms.error.reason}
					</td>
				</tr>
			);
		}

		return (
			<Table bordered className="forms-table">
				<thead>
					<tr>
						<th>Form Name</th>
						<th>Status</th>
					</tr>
				</thead>

				<tbody>{body}</tbody>
			</Table>
		);
	};

	renderIndividualForm = (form) => {
		const { _id, isPublished, canEdit, name } = form;
		const displayName = name || "Unnamed Form";
		const displayStatus = isPublished ? "Published" : "Unpublished";

		let deleteButton;
		if (isPublished) {
			const tooltip = <Tooltip id="button-tooltip">Delete form</Tooltip>;
			deleteButton = (
				<OverlayTrigger
					placement="left"
					delay={{ show: 500 }}
					overlay={tooltip}
				>
					<AiOutlineDelete className="individual-form-icon-disabled" />
				</OverlayTrigger>
			);
		} else {
			const tooltip = <Tooltip id="button-tooltip">Delete form</Tooltip>;
			deleteButton = (
				<OverlayTrigger
					placement="left"
					delay={{ show: 250 }}
					overlay={tooltip}
				>
					<AiOutlineDelete
						onClick={() => this.handleDeleteForm(_id)}
						className="individual-form-icon"
					/>
				</OverlayTrigger>
			);
		}

		const editTooltip = <Tooltip id="button-tooltip">Edit form</Tooltip>;
		const editButton = (
			<OverlayTrigger
				placement="left"
				delay={{ show: 250 }}
				overlay={editTooltip}
			>
				<AiOutlineEdit
					onClick={() => this.handleGetFormToEdit(_id)}
					className="individual-form-icon"
				/>
			</OverlayTrigger>
		);

		const copyTooltip = (
			<Tooltip id="button-tooltip">Make a copy of this form</Tooltip>
		);
		const copyButton = (
			<OverlayTrigger
				placement="left"
				delay={{ show: 250 }}
				overlay={copyTooltip}
			>
				<AiOutlineCopy
					onClick={() => this.props.copyFormRequest(_id)}
					className="individual-form-icon"
				/>
			</OverlayTrigger>
		);

		return (
			<tr key={_id}>
				<td>{displayName}</td>
				<td>
					<Row noGutters className="individual-form-status-row">
						{displayStatus}

						<div>
							{editButton}
							{copyButton}
							{deleteButton}
						</div>
					</Row>
				</td>
			</tr>
		);
	};

	handleTogglePreviewModal = () => {
		this.setState({
			showPreviewModal: !this.state.showPreviewModal,
		});
	};

	renderEditingForm = () => {
		const { data, loading, loaded, error, saving } = this.state.editingForm;

		let content;
		if (loaded) {
			const { name, description, fields, isPublished, onSubmit } =
				data[0];

			let fieldsToRender;
			if (fields.length > 0) {
				fieldsToRender = fields.map((field, fieldIndex) =>
					this.renderIndividualField(field, fieldIndex)
				);
			} else {
				fieldsToRender = (
					<div className="no-fields">This form has no fields</div>
				);
			}

			let publishButton;
			let disabled;
			if (isPublished) {
				disabled = true;
				publishButton = (
					<Button
						size="sm"
						onClick={this.handleUnpublishForm}
						className="teal-button"
					>
						Unpublish
					</Button>
				);
			} else {
				disabled = false;
				publishButton = (
					<Button
						size="sm"
						onClick={this.handlePublishForm}
						className="teal-button"
					>
						Publish
					</Button>
				);
			}

			let savingText;
			if (saving) {
				savingText = (
					<div className="form-saving-new-text">
						<Spinner animation="border" size="sm" />
						Saving...
					</div>
				);
			} else {
				savingText = "";
			}

			let previewButton;
			previewButton = (
				<Button
					onClick={this.handleTogglePreviewModal}
					size="sm"
					className="preview-button teal-button"
				>
					Preview
				</Button>
			);

			let richTextEditor;
			if (disabled) {
				const convertedFieldDescription = ReactHtmlParser(description);
				richTextEditor = (
					<div className="disabled-react-quill">
						{convertedFieldDescription}
					</div>
				);
			} else {
				richTextEditor = (
					<ReactQuill
						ref={(el) => {
							this.quill = el;
						}}
						modules={this.modules}
						formats={this.formats}
						enabled={!disabled}
						defaultValue={description}
						onChange={(value) =>
							this.handleOnChangeFormDescription(value)
						}
						className="field-description-react-quill"
					/>
				);
			}

			content = (
				<React.Fragment>
					<Row noGutters className="forms-header edit-forms-header">
						<h4 onClick={this.handleClearFormToEdit}>
							<IoIosArrowRoundBack /> Return to form list
						</h4>
					</Row>

					<div className="forms-table-box">
						<Row noGutters className="individual-field">
							<Col sm={8} className="individual-field-column">
								<Row noGutters className="individual-field-row">
									<Col className="individual-field-sub-column">
										<label className="individual-field-label">
											Form Name:
										</label>
										<FormControl
											size="sm"
											disabled={disabled}
											value={name}
											onChange={
												this.handleOnChangeFormName
											}
										/>
									</Col>

									<Col className="individual-field-sub-column">
										<label className="individual-field-label">
											Set manual or automatic:
										</label>
										<FormControl
											size="sm"
											as="select"
											value={onSubmit}
											onChange={
												this.handleOnChangeFormOnSubmit
											}
											disabled={disabled}
										>
											<option value="">
												Select an option...
											</option>
											{this.props.submissionTypes.loaded
												? this.props.submissionTypes.data.map(
														(option) =>
															this.renderOption(
																option
															)
												  )
												: ""}
										</FormControl>
									</Col>
								</Row>

								<Row noGutters>
									<Col className="individual-field-sub-column">
										<Accordion
											className="field-description-accordion"
											defaultActiveKey={
												description ? "0" : ""
											}
										>
											<Accordion.Toggle eventKey="0">
												Click here to show/hide the Form
												Description
											</Accordion.Toggle>

											<Accordion.Collapse eventKey="0">
												<div>{richTextEditor}</div>
											</Accordion.Collapse>
										</Accordion>
									</Col>
								</Row>
							</Col>

							<Col className="individual-field-buttons-column">
								<Row
									noGutters
									className="individual-form-buttons-row"
								>
									{savingText}
									{previewButton}
									{publishButton}
								</Row>
							</Col>
						</Row>

						<div className="edit-form-fields-container">
							{fieldsToRender}
						</div>

						<Row noGutters className="add-fields-button-row">
							<Button
								disabled={disabled}
								onClick={this.handleAddField}
								className="teal-button"
							>
								+ Add Field
							</Button>
						</Row>
					</div>
				</React.Fragment>
			);
		} else if (loading) {
			content = this.renderLoading();
		}

		return content;
	};

	renderOption = (option) => {
		return (
			<option key={option.display} value={option.search}>
				{option.display}
			</option>
		);
	};

	renderPreviewModal = () => {
		const { data } = this.state.editingForm;

		let preview;
		if (this.state.showPreviewModal) {
			preview = <COMPONENT.Preview formData={data} />;
		}

		return (
			<Modal
				centered
				className="common-modal submission-modal"
				show={this.state.showPreviewModal}
				onHide={this.handleTogglePreviewModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>Preview</Modal.Title>
				</Modal.Header>

				<Modal.Body>{preview}</Modal.Body>
			</Modal>
		);
	};

	renderIndividualField = (field, fieldIndex) => {
		const { fieldName, fieldType, required, fieldDescription } = field;
		const { isPublished, onSubmit, fields } =
			this.state.editingForm.data[0];

		let deleteButton;
		let disabled;
		if (isPublished) {
			disabled = true;
			deleteButton = (
				<AiOutlineDelete className="individual-form-icon-disabled" />
			);
		} else {
			disabled = false;
			deleteButton = (
				<AiOutlineDelete
					onClick={() => this.handleDeleteField(fieldIndex)}
					className="individual-form-icon"
				/>
			);
		}

		let requiredDisabled;
		if (isPublished || fieldType == "display") {
			requiredDisabled = true;
		} else {
			requiredDisabled = false;
		}

		let configureButton = <div className="empty-dropdownLink" />;
		if (fieldType === "dropdown") {
			if (!field.reject) {
				field.reject = [];
			}

			configureButton = (
				<Button
					onClick={() => this.handleShowDropdownModal(fieldIndex)}
					className="teal-button"
					size="sm"
				>
					Configure
				</Button>
			);
		} else if (fieldType === "number") {
			configureButton = (
				<Button
					disabled={onSubmit == "manual" ? true : false}
					onClick={() => this.handleShowNumberModal(fieldIndex)}
					className="teal-button"
					size="sm"
				>
					Configure
				</Button>
			);
		} else if (fieldType === "branch") {
			if (!field.reject) {
				field.reject = [];
			}

			configureButton = (
				<Button
					onClick={() => this.handleShowBranchModal(fieldIndex)}
					className="teal-button"
					size="sm"
				>
					Configure
				</Button>
			);
		} else if (fieldType === "idmplugin") {
			configureButton = (
				<Button
					onClick={() => this.handleShowUrlModal(fieldIndex)}
					className="teal-button"
					size="sm"
				>
					Configure
				</Button>
			);
		}

		let richTextEditor;
		if (disabled) {
			const convertedFieldDescription = ReactHtmlParser(fieldDescription);
			richTextEditor = (
				<div className="disabled-react-quill">
					{convertedFieldDescription}
				</div>
			);
		} else {
			richTextEditor = (
				<ReactQuill
					ref={(el) => {
						this.quill = el;
					}}
					modules={this.modules}
					formats={this.formats}
					enabled={!disabled}
					defaultValue={fieldDescription}
					onChange={(value) =>
						this.handleOnChangeFieldDescription({
							value,
							fieldIndex,
						})
					}
					className="field-description-react-quill"
				/>
			);
		}

		let upButton;
		if (fieldIndex !== 0) {
			upButton = (
				<AiOutlineUp
					onClick={() => this.handleMoveFieldUp(fieldIndex)}
					className="individual-form-icon up-button"
				/>
			);
		}

		let downButton;
		if (fieldIndex !== fields.length - 1 && fields.length > 1) {
			downButton = (
				<AiOutlineDown
					onClick={() => this.handleMoveFieldDown(fieldIndex)}
					className="individual-form-icon down-button"
				/>
			);
		}

		const copyTooltip = (
			<Tooltip id="button-tooltip">Make a copy of this field</Tooltip>
		);
		const copyButton = (
			<OverlayTrigger
				placement="left"
				delay={{ show: 250 }}
				overlay={copyTooltip}
			>
				<AiOutlineCopy
					onClick={() => this.handleCopyField(field, fieldIndex)}
					className="individual-form-icon"
				/>
			</OverlayTrigger>
		);

		return (
			<Row noGutters className="individual-field" key={fieldIndex}>
				<Col sm={8} className="individual-field-column">
					<Row noGutters className="individual-field-row">
						<Col className="individual-field-sub-column">
							<label className="individual-field-label">
								Field Name:
							</label>
							<FormControl
								name="fieldName"
								value={fieldName || ""}
								disabled={disabled}
								onChange={(e) =>
									this.handleOnChangeFormData(e, fieldIndex)
								}
								size="sm"
							/>
						</Col>

						<Col className="individual-field-sub-column">
							<label className="individual-field-label">
								Field Type:
							</label>
							<FormControl
								name="fieldType"
								value={fieldType || "string"}
								disabled={disabled}
								onChange={(e) =>
									this.handleOnChangeFieldType(e, fieldIndex)
								}
								size="sm"
								as="select"
							>
								<option value="string">String</option>
								<option value="paragraph">Paragraph</option>
								<option value="dropdown">Dropdown</option>
								<option value="file">File</option>
								<option value="image">Image</option>
								<option value="location">Location</option>
								<option value="date">Date</option>
								<option value="number">Number</option>
								<option value="e-signature">e-Signature</option>
								<option value="branch">Branch</option>
								<option value="display">Display</option>
								<option value="idmplugin">IDM-Plugin</option>
								<option value="checkbox">Checkbox</option>
							</FormControl>
						</Col>
					</Row>

					<Row noGutters className="individual-field-row">
						<Col className="individual-field-sub-column">
							<Accordion
								className="field-description-accordion"
								defaultActiveKey={fieldDescription ? "0" : ""}
							>
								<Accordion.Toggle eventKey="0">
									Click here to show/hide the Field
									Description
								</Accordion.Toggle>

								<Accordion.Collapse eventKey="0">
									<div>{richTextEditor}</div>
								</Accordion.Collapse>
							</Accordion>
						</Col>
					</Row>
				</Col>

				<Col className="individual-field-buttons-column">
					<Row noGutters className="individual-field-buttons-row">
						{configureButton}

						<FormCheck
							checked={required}
							onChange={() =>
								this.handleOnChangeRequired(fieldIndex)
							}
							disabled={requiredDisabled}
							className="dropdown-checkbox"
							size="sm"
							label="Required?"
						/>

						<div>
							{upButton}
							{downButton}
						</div>

						<div>
							{copyButton}
							{deleteButton}
						</div>
					</Row>
				</Col>
			</Row>
		);
	};

	renderFormListOrEditingForm = () => {
		const { loaded } = this.state.editingForm;

		if (loaded) {
			return (
				<React.Fragment>
					{this.renderUrlModal()}
					{this.renderNumberModal()}
					{this.renderDropdownOptionsModal()}
					{this.renderEditingForm()}
					{this.renderBranchModal()}
					{this.renderSubfieldDropdownOptionsModal()}
					{this.renderSubfieldNumberModal()}
					{this.renderPreviewModal()}
					{this.renderCheckboxModal()}
				</React.Fragment>
			);
		} else {
			return this.renderFormList();
		}
	};

	handleMoveFieldUp = (fieldIndex) => {
		const { fields } = this.state.editingForm.data[0];
		const nextFieldIndex = fieldIndex - 1;
		const newFields = arrayMove(fields, fieldIndex, nextFieldIndex);

		this.state.editingForm.data[0].fields = newFields;

		this.handleUpdateFormData();
	};

	handleMoveFieldDown = (fieldIndex) => {
		const { fields } = this.state.editingForm.data[0];
		const nextFieldIndex = fieldIndex + 1;
		const newFields = arrayMove(fields, fieldIndex, nextFieldIndex);

		this.state.editingForm.data[0].fields = newFields;

		this.handleUpdateFormData();
	};

	renderBranchModal = () => {
		const { currentBranchIndex, showBranchModal } = this.state;
		const { data } = this.state.editingForm;
		const isPublished = data[0].isPublished;
		const onSubmit = data[0].onSubmit;

		if (showBranchModal) {
			const branchSubfields =
				data[0].fields[currentBranchIndex].subfields;

			let disabled;
			if (isPublished) {
				disabled = true;
			} else {
				disabled = false;
			}

			return (
				<Modal
					centered
					className="common-modal submission-modal"
					show={true}
					onHide={this.handleCloseBranchModal}
				>
					<Modal.Header closeButton>
						<Modal.Title>Branch Options</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						{branchSubfields.map((subfield, subfieldIndex) =>
							this.renderIndividualBranch(subfield, subfieldIndex)
						)}

						<Row noGutters className="common-right-justified">
							<Button
								onClick={this.handleAddBranch}
								className="teal-button"
								disabled={disabled}
								size="sm"
							>
								+ Add Branch
							</Button>
						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Button
							className="teal-button"
							disabled={disabled}
							onClick={this.handleCloseBranchModal}
						>
							Save Changes
						</Button>
					</Modal.Footer>
				</Modal>
			);
		}
	};

	renderIndividualBranch = (branch, branchIndex) => {
		const isPublished = this.state.editingForm.data[0].isPublished;
		const { currentBranchIndex } = this.state;

		let deleteButton;
		let disabled;
		if (isPublished) {
			disabled = true;
			deleteButton = (
				<AiOutlineDelete className="individual-form-icon-disabled" />
			);
		} else {
			disabled = false;
			deleteButton = (
				<AiOutlineDelete
					onClick={() => this.handleDeleteBranch(branchIndex)}
					className="individual-form-icon"
				/>
			);
		}

		let checked;
		let onChangeCheckbox;
		if (
			this.state.editingForm.data[0].fields[
				currentBranchIndex
			].reject.includes(branch.name)
		) {
			checked = true;
			onChangeCheckbox = () =>
				this.handleRemoveOptionFromReject(
					branch.name,
					currentBranchIndex
				);
		} else {
			checked = false;
			onChangeCheckbox = () =>
				this.handleAddOptionToReject(branch.name, currentBranchIndex);
		}

		let branchData = [];
		if (!branch.data) {
			branch.data = []; // this creates the data array in branch if none exists (on render)
		} else {
			branchData = branch.data;
		}

		return (
			<div className="individual-subfield-container" key={branchIndex}>
				<Row noGutters className="individual-subfield-option">
					<Col>
						<label className="individual-subfield-label">
							Branch Name
						</label>
						<Row noGutters className="individual-subfield-option">
							<FormControl
								size="sm"
								value={branch.name}
								required
								onChange={(e) =>
									this.handleChangeBranchName(e, branchIndex)
								}
								placeholder="Dropdown value..."
								disabled={disabled}
							/>

							<FormCheck
								disabled={disabled}
								checked={checked}
								onChange={onChangeCheckbox}
								className="dropdown-checkbox"
								size="sm"
								label="Reject this value"
							/>

							{deleteButton}
						</Row>
					</Col>
				</Row>

				<Row noGutters className="individual-subfields-data">
					<Row noGutters className="individual-subfields-header">
						<label className="individual-branch-label">
							Subfields
						</label>
						<Button
							onClick={() =>
								this.handleAddSubfieldToBranch(branchIndex)
							}
							className="teal-button xs-button"
						>
							+ Add Subfield
						</Button>
					</Row>

					{branchData.map((subfield, subfieldIndex) =>
						this.renderIndividualSubfield(
							subfield,
							subfieldIndex,
							branchIndex
						)
					)}
				</Row>
			</div>
		);
	};

	renderIndividualSubfield = (subfield, subfieldIndex, branchIndex) => {
		const { fieldName, fieldType, required } = subfield;
		const { isPublished, onSubmit } = this.state.editingForm.data[0];

		let deleteButton;
		let disabled;
		if (isPublished) {
			disabled = true;
			deleteButton = (
				<AiOutlineDelete className="individual-form-icon-disabled" />
			);
		} else {
			disabled = false;
			deleteButton = (
				<AiOutlineDelete
					onClick={() =>
						this.handleDeleteSubfield(subfieldIndex, branchIndex)
					}
					className="individual-form-icon"
				/>
			);
		}

		let configureButton = <div className="empty-subfield-dropdownLink" />;
		if (fieldType === "dropdown") {
			if (!subfield.reject) {
				subfield.reject = [];
			}
			configureButton = (
				<Button
					onClick={() =>
						this.handleToggleSubfieldDropdownOptionsModal(
							subfieldIndex,
							branchIndex
						)
					}
					className="teal-button xs-button"
					size="sm"
				>
					Configure
				</Button>
			);
		} else if (fieldType === "number") {
			configureButton = (
				<Button
					onClick={() =>
						this.handleToggleSubfieldNumberModal(
							subfieldIndex,
							branchIndex
						)
					}
					disabled={onSubmit == "manual" ? true : false}
					className="teal-button xs-button"
					size="sm"
				>
					Configure
				</Button>
			);
		}

		return (
			<Row
				noGutters
				className="individual-subfield"
				key={"subfield-option: " + subfieldIndex}
			>
				<Col sm={8} className="individual-field-column">
					<Row noGutters className="individual-field-row">
						<Col className="individual-field-sub-column">
							<label className="individual-subfield-label">
								Field Name:
							</label>
							<FormControl
								value={fieldName}
								name="fieldName"
								onChange={(e) =>
									this.handleChangeSubfieldContent(
										e,
										subfieldIndex,
										branchIndex
									)
								}
								className="common-admin-formControl subfield-formControl"
								disabled={disabled}
								size="sm"
							/>
						</Col>

						<Col className="individual-field-sub-column">
							<label className="individual-subfield-label">
								Field Type:
							</label>
							<FormControl
								value={fieldType}
								onChange={(e) =>
									this.handleChangeSubfieldContent(
										e,
										subfieldIndex,
										branchIndex
									)
								}
								className="common-admin-formControl subfield-formControl"
								name="fieldType"
								disabled={disabled}
								size="sm"
								as="select"
							>
								<option value="string">String</option>
								<option value="paragraph">Paragraph</option>
								<option value="dropdown">Dropdown</option>
								<option value="file">File</option>
								<option value="image">Image</option>
								<option value="location">Location</option>
								<option value="date">Date</option>
								<option value="number">Number</option>
								<option value="e-signature">e-Signature</option>
							</FormControl>
						</Col>
					</Row>
				</Col>

				<Col>
					<Row noGutters className="individual-subfield-buttons-row">
						{configureButton}

						<FormCheck
							onChange={(e) =>
								this.handleChangeSubfieldContent(
									e,
									subfieldIndex,
									branchIndex
								)
							}
							checked={required}
							disabled={disabled}
							name="required"
							className="dropdown-checkbox"
							size="sm"
							label="Required?"
						/>

						{deleteButton}
					</Row>
				</Col>
			</Row>
		);
	};

	renderDropdownOptionsModal = () => {
		const { currentDropdownIndex, showDropdownOptionsModal } = this.state;
		const { data } = this.state.editingForm;
		const isPublished = data[0].isPublished;
		const onSubmit = data[0].onSubmit;

		if (showDropdownOptionsModal) {
			const dropdownOptions =
				data[0].fields[currentDropdownIndex].options;

			let disabled;
			if (isPublished) {
				disabled = true;
			} else {
				disabled = false;
			}

			return (
				<Modal
					centered
					className="common-modal"
					show={true}
					onHide={this.handleCloseDropdownModal}
				>
					<Modal.Header closeButton>
						<Modal.Title>Dropdown Options</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						{dropdownOptions.map((option, optionIndex) =>
							this.renderIndividualDropdownOption(
								option,
								optionIndex
							)
						)}

						<Row noGutters className="common-right-justified">
							<Button
								onClick={this.handleAddDropdownOption}
								className="teal-button"
								disabled={disabled}
								size="sm"
							>
								+ Add Option
							</Button>
						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Button
							className="teal-button"
							disabled={disabled}
							onClick={this.handleCloseDropdownModal}
						>
							Save Changes
						</Button>
					</Modal.Footer>
				</Modal>
			);
		}
	};

	renderIndividualDropdownOption = (option, optionIndex) => {
		const isPublished = this.state.editingForm.data[0].isPublished;
		const { currentDropdownIndex } = this.state;

		let deleteButton;
		let disabled;
		if (isPublished) {
			disabled = true;
			deleteButton = (
				<AiOutlineDelete className="individual-form-icon-disabled" />
			);
		} else {
			disabled = false;
			deleteButton = (
				<AiOutlineDelete
					onClick={() => this.handleDeleteDropdownOption(optionIndex)}
					className="individual-form-icon"
				/>
			);
		}

		let checked;
		let onChangeCheckbox;
		if (
			this.state.editingForm.data[0].fields[
				currentDropdownIndex
			].reject.includes(option)
		) {
			checked = true;
			onChangeCheckbox = () =>
				this.handleRemoveOptionFromReject(option, currentDropdownIndex);
		} else {
			checked = false;
			onChangeCheckbox = () =>
				this.handleAddOptionToReject(option, currentDropdownIndex);
		}

		return (
			<Row
				noGutters
				className="individual-dropdown-option"
				key={optionIndex}
			>
				<FormControl
					size="sm"
					value={option}
					required
					placeholder="Dropdown value..."
					disabled={disabled}
					onChange={(e) =>
						this.handleChangeDropdownOptionValue(e, optionIndex)
					}
				/>

				<FormCheck
					disabled={disabled}
					checked={checked}
					onChange={onChangeCheckbox}
					className="dropdown-checkbox"
					size="sm"
					label="Reject this value"
				/>

				{deleteButton}
			</Row>
		);
	};

	renderNumberModal = () => {
		const { currentNumberIndex, showNumberModal } = this.state;
		const { data } = this.state.editingForm;
		const isPublished = data[0].isPublished;
		const onSubmit = data[0].onSubmit;

		if (showNumberModal) {
			const numberOptions = data[0].fields[currentNumberIndex];
			if (!numberOptions.reject_greater_than)
				numberOptions.reject_greater_than = "";
			if (!numberOptions.reject_less_than)
				numberOptions.reject_less_than = "";

			return (
				<Modal
					centered
					className="common-modal"
					show={true}
					onHide={this.handleCloseNumberModal}
				>
					<Modal.Header closeButton>
						<Modal.Title>Approval Parameters</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Row noGutters className="individual-dropdown-option">
							Reject greater than
							<FormControl
								type="number"
								value={numberOptions.reject_greater_than}
								onChange={this.handleChangeRejectGreaterThan}
								size="sm"
							/>
						</Row>

						<Row noGutters className="individual-dropdown-option">
							Reject less than
							<FormControl
								type="number"
								value={numberOptions.reject_less_than}
								onChange={this.handleChangeRejectLessThan}
								size="sm"
							/>
						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Button
							className="teal-button"
							onClick={this.handleCloseNumberModal}
						>
							Save Changes
						</Button>
					</Modal.Footer>
				</Modal>
			);
		}
	};

	renderUrlModal = () => {
		const { currentUrlIndex, showUrlModal } = this.state;
		const { data } = this.state.editingForm;
		const isPublished = data[0].isPublished;
		const onSubmit = data[0].onSubmit;

		if (showUrlModal) {
			const url = data[0].fields[currentUrlIndex].url;
			const disabled = isPublished ? true : false;
			return (
				<Modal
					centered
					className="common-modal"
					show={true}
					onHide={this.handleCloseUrlModal}
				>
					<Modal.Header closeButton>
						<Modal.Title>Configure URL</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Row noGutters className="individual-dropdown-option">
							Url
							<FormControl
								type="url"
								value={url}
								disabled={disabled}
								onChange={this.handleChangeUrl}
								size="sm"
							/>
						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Button
							className="teal-button"
							onClick={this.handleCloseUrlModal}
						>
							Save Changes
						</Button>
					</Modal.Footer>
				</Modal>
			);
		}
	};

	renderSubfieldNumberModal = () => {
		const {
			currentBranchIndex,
			showSubfieldNumberModal,
			currentSubfieldNumberIndex,
			currentBranchSubfieldIndex,
		} = this.state;
		const { data } = this.state.editingForm;
		const isPublished = data[0].isPublished;
		const onSubmit = data[0].onSubmit;

		let numberOptions = [];
		if (showSubfieldNumberModal) {
			numberOptions =
				data[0].fields[currentBranchIndex].subfields[
					currentBranchSubfieldIndex
				].data[currentSubfieldNumberIndex];
		}

		let disabled;
		if (isPublished) {
			disabled = true;
		} else {
			disabled = false;
		}

		return (
			<Modal
				centered
				className="common-modal"
				show={showSubfieldNumberModal}
				onHide={this.handleToggleSubfieldNumberModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>Subfield Dropdown Options</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Row noGutters className="individual-dropdown-option">
						Reject greater than
						<FormControl
							type="number"
							value={numberOptions.reject_greater_than}
							onChange={
								this.handleChangeSubfieldRejectGreaterThan
							}
							size="sm"
						/>
					</Row>

					<Row noGutters className="individual-dropdown-option">
						Reject less than
						<FormControl
							type="number"
							value={numberOptions.reject_less_than}
							onChange={this.handleChangeSubfieldRejectLessThan}
							size="sm"
						/>
					</Row>
				</Modal.Body>

				<Modal.Footer>
					<Button
						onClick={this.handleToggleSubfieldNumberModal}
						disabled={disabled}
						className="teal-button"
					>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderSubfieldDropdownOptionsModal = () => {
		const {
			currentBranchIndex,
			showSubfieldDropdownOptionsModal,
			currentSubfieldDropdownIndex,
			currentBranchSubfieldIndex,
		} = this.state;
		const { data } = this.state.editingForm;
		const isPublished = data[0].isPublished;
		const onSubmit = data[0].onSubmit;

		let dropdownOptions = [];
		if (showSubfieldDropdownOptionsModal) {
			dropdownOptions =
				data[0].fields[currentBranchIndex].subfields[
					currentBranchSubfieldIndex
				].data[currentSubfieldDropdownIndex].options;
		}

		let disabled;
		if (isPublished) {
			disabled = true;
		} else {
			disabled = false;
		}

		return (
			<Modal
				centered
				className="common-modal"
				show={showSubfieldDropdownOptionsModal}
				onHide={this.handleToggleSubfieldDropdownOptionsModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>Subfield Dropdown Options</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{dropdownOptions.map((option, optionIndex) =>
						this.renderIndividualSubfieldDropdownOption(
							option,
							optionIndex
						)
					)}

					<Row noGutters className="common-right-justified">
						<Button
							onClick={this.handleAddSubfieldDropdownOption}
							disabled={disabled}
							className="teal-button"
							size="sm"
						>
							+ Add Option
						</Button>
					</Row>
				</Modal.Body>

				<Modal.Footer>
					<Button
						onClick={this.handleToggleSubfieldDropdownOptionsModal}
						disabled={disabled}
						className="teal-button"
					>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderIndividualSubfieldDropdownOption = (option, optionIndex) => {
		const isPublished = this.state.editingForm.data[0].isPublished;
		const {
			currentBranchIndex,
			currentSubfieldDropdownIndex,
			currentBranchSubfieldIndex,
		} = this.state;

		let deleteButton;
		let disabled;
		if (isPublished) {
			disabled = true;
			deleteButton = (
				<AiOutlineDelete className="individual-form-icon-disabled" />
			);
		} else {
			disabled = false;
			deleteButton = (
				<AiOutlineDelete
					onClick={() =>
						this.handleDeleteSubfieldDropdownOption(optionIndex)
					}
					className="individual-form-icon"
				/>
			);
		}

		let checked;
		let onChangeCheckbox;
		if (
			this.state.editingForm.data[0].fields[currentBranchIndex].subfields[
				currentBranchSubfieldIndex
			].data[currentSubfieldDropdownIndex].reject.includes(option)
		) {
			checked = true;
			onChangeCheckbox = () =>
				this.handleRemoveOptionFromSubfieldDropdownReject(
					option,
					optionIndex
				);
		} else {
			checked = false;
			onChangeCheckbox = () =>
				this.handleAddOptionToSubfieldDropdownReject(
					option,
					optionIndex
				);
		}

		return (
			<Row
				noGutters
				className="individual-dropdown-option"
				key={("subfield-dropdown-option: ", optionIndex)}
			>
				<FormControl
					size="sm"
					value={option}
					onChange={(e) =>
						this.handleChangeSubfieldDropdownOptionValue(
							e,
							optionIndex
						)
					}
					required
					placeholder="Dropdown value..."
					disabled={disabled}
				/>

				<FormCheck
					checked={checked}
					onChange={onChangeCheckbox}
					disabled={disabled}
					className="dropdown-checkbox"
					size="sm"
					label="Reject this value"
				/>

				{deleteButton}
			</Row>
		);
	};

	renderCheckboxModal = () => {
		const { currentCheckboxIndex, showCheckboxModal } = this.state;
		const { data } = this.state.editingForm;
		const isPublished = data[0].isPublished;
		const onSubmit = data[0].onSubmit;

		if (showCheckboxModal) {
			let disabled;
			if (isPublished) {
				disabled = true;
			} else {
				disabled = false;
			}

			const { options, subfields } = data[0].fields[currentCheckboxIndex];
			let renderOptions;
			let renderSubfields;

			if (options.length > 0) {
				renderOptions = options.map((option, optionIndex) =>
					this.renderCheckboxOption(option, optionIndex, disabled)
				);
			}

			if (subfields.length > 0) {
				renderSubfields = subfields.map((subfield, subfieldIndex) =>
					this.renderCheckboxSubfield(
						subfield,
						subfieldIndex,
						disabled
					)
				);
			}

			return (
				<Modal
					centered
					className="common-modal"
					show={true}
					onHide={this.handleCloseCheckboxModal}
				>
					<Modal.Header closeButton>
						<Modal.Title>Checkbox Options</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Row noGutters className="checkbox-modal-options-row">
							<Col>
								<Row
									noGutters
									className="checkbox-modal-header-row"
								>
									Options:
									<Button
										disabled={disabled}
										onClick={this.handleAddCheckboxOption}
										className="teal-button"
										size="sm"
									>
										+ Add Option
									</Button>
								</Row>

								{renderOptions}
							</Col>
						</Row>

						<Row noGutters className="checkbox-modal-options-row">
							<Col>
								<Row
									noGutters
									className="checkbox-modal-header-row"
								>
									Subfields:
									<Button
										disabled={disabled}
										onClick={this.handleAddCheckboxSubfield}
										className="teal-button"
										size="sm"
									>
										+ Add Subfield
									</Button>
								</Row>
								{renderSubfields}
							</Col>
						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Button
							className="teal-button"
							disabled={disabled}
							onClick={this.handleCloseCheckboxModal}
						>
							Save Changes
						</Button>
					</Modal.Footer>
				</Modal>
			);
		}
	};

	renderCheckboxOption = (option, optionIndex, disabled) => {
		const { currentCheckboxIndex } = this.state;

		let deleteButton;
		if (!disabled) {
			deleteButton = (
				<AiOutlineDelete
					onClick={() => this.handleDeleteCheckboxOption(optionIndex)}
					className="individual-form-icon"
				/>
			);
		} else {
			deleteButton = (
				<AiOutlineDelete className="individual-form-icon-disabled" />
			);
		}

		return (
			<Row noGutters className="checkbox-option-row">
				<FormControl
					disabled={disabled}
					onChange={(e) =>
						this.handleEditCheckboxOption(e, optionIndex)
					}
					key={`checkboxOption ${optionIndex}, ${currentCheckboxIndex}`}
					value={option}
					className="checkbox-modal-formControl"
					size="sm"
				/>
				{deleteButton}
			</Row>
		);
	};

	renderCheckboxSubfield = (subfield, subfieldIndex, disabled) => {
		const { currentCheckboxIndex } = this.state;

		let deleteButton;
		if (!disabled) {
			deleteButton = (
				<AiOutlineDelete
					onClick={() =>
						this.handleDeleteCheckboxSubfield(subfieldIndex)
					}
					className="individual-form-icon"
				/>
			);
		} else {
			deleteButton = (
				<AiOutlineDelete className="individual-form-icon-disabled" />
			);
		}

		return (
			<Row noGutters>
				<Col>
					<label className="checkbox-subfield-label">
						Field Name:
					</label>
					<Row noGutters className="checkbox-option-row">
						<FormControl
							disabled={disabled}
							onChange={(e) =>
								this.handleEditCheckboxSubfield(
									e,
									subfieldIndex
								)
							}
							key={`checkboxSubfield ${subfieldIndex}, ${currentCheckboxIndex}`}
							value={subfield.fieldName}
							className="checkbox-modal-formControl"
							size="sm"
						/>
						{deleteButton}
					</Row>
				</Col>
			</Row>
		);
	};

	renderLoading = () => (
		<Row noGutters className="app-spinner">
			<Spinner animation="border" className="common-grey-spinner" />
		</Row>
	);

	render() {
		return (
			<Container fluid className="admin-content-container">
				{this.renderFormListOrEditingForm()}
			</Container>
		);
	}
}

export default Forms;
