import queryString from "query-string";
import { baseUrl } from "./config";

async function request(
	endpoint,
	method = "GET",
	data = {},
	isToken = false,
	formData = false
) {
	return new Promise((resolve, reject) => {
		let qs = "";
		let body;
		let headers;
		if (["GET", "DELETE"].indexOf(method) > -1) {
			qs = `${queryString.stringify(data)}`;
		} else if (formData) {
			body = data;
		} else {
			body = queryString.stringify(data);
		}

		if (formData) {
			headers = {
				Accept: "application/json",
			};
		} else {
			headers = {
				Accept: "application/json",
				"Content-Type": "application/x-www-form-urlencoded",
			};
		}

		const requestUrl = `${baseUrl}${endpoint}${qs}`;
		const options = {
			method,
			headers,
			credentials: "include",
			body,
		};

		if (isToken) {
			options.headers.Authorization = `Bearer ${localStorage.getItem(
				"accessToken"
			)}`;
		}

		fetch(requestUrl, options)
			.then(async (resp) => {
				const contentType = resp.headers.get("content-type");
				let formattedResp;

				if (contentType && contentType.includes("application/json")) {
					formattedResp = await resp.json();
					if (formattedResp.message === "error")
						reject(formattedResp);
				} else {
					formattedResp = await resp.text();
				}

				resolve(formattedResp);
			})
			.catch((err) => reject(err));
	});
}

export default request;
