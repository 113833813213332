export let baseUrl = ''

if(process.env.REACT_APP_BACKEND){
    baseUrl = process.env.REACT_APP_BACKEND
}
else if (process.env.NODE_ENV == "development") {
    // baseUrl = `http://onboarding-dev.us-east-2.elasticbeanstalk.com/api`
    baseUrl = `https://mid-onboarding.fi-edition.com/api`
} else {
    // baseUrl = `http://fi-onboarding.us-east-2.elasticbeanstalk.com/api`
    baseUrl = `https://mid-onboarding.fi-edition.com/api`
}
