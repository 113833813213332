import { put, takeLatest, call } from "redux-saga/effects";
import { history } from "../../../configureStore";
import request from "../../../apiRequest";
import { toast } from "react-toastify";
import * as ACTIONS from "../../actions/confirmEmail";
import * as CONSTANTS from "../../constants/confirmEmail";

function* confirmEmail(action) {
  try {
    const { token } = action;
    const responseData = yield call(request, `/account/confirm?token=${token}`);

    if (responseData.data.role === "admin") {
      history.push("/admin");
    } else {
      history.push("/");
    }

    window.location.href = window.location.href;
  } catch (err) {
    if (err.reason == "Token has expired") {
      history.push("/token-expired");
      toast.error(err.reason);
    } else {
      toast.error(err.reason);
    }
  }
}

function* resendConfirmationEmail(action) {
  try {
    const responseData = yield call(request, "/account/resend", "POST");
    toast.info(
      "A new confirmation has been sent to your email. Please check again"
    );
  } catch (err) {
    toast.error(err.reason);
  }
}

export default function* authSaga() {
  yield takeLatest(CONSTANTS.CONFIRM_EMAIL_REQUEST, confirmEmail);
  yield takeLatest(
    CONSTANTS.RESEND_CONFIRMATION_EMAIL_REQUEST,
    resendConfirmationEmail
  );
}
