import { connect } from "react-redux"
import Dashboard from './Dashboard'
import {
  getDashboardStatsRequest,
  getGraphDataRequest,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  dashboardStats: state.admin.dashboardStats,
  graphData: state.admin.graphData,
})

const mapDispatchToProps = {
  getDashboardStatsRequest,
  getGraphDataRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
