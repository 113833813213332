import { all, fork } from 'redux-saga/effects';
import loginSaga from './watchers/login'
import authSaga from './watchers/auth'
import registerSaga from './watchers/register'
import forgotSaga from './watchers/forgot'
import resetSaga from './watchers/reset'
import confirmEmailSaga from './watchers/confirmEmail'
import adminSaga from './watchers/admin'
import utilitiesSaga from './watchers/utilities'

export default function* root() {
  yield all([
    fork(loginSaga),
    fork(authSaga),
    fork(registerSaga),
    fork(forgotSaga),
    fork(resetSaga),
    fork(confirmEmailSaga),
    fork(adminSaga),
    fork(utilitiesSaga),
  ])
}
