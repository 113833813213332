import * as CONSTANTS from '../constants/login'

export const loginRequest = data => ({
  type: CONSTANTS.LOGIN_REQUEST,
  data
})

export const loginSuccess = data => ({
  type: CONSTANTS.LOGIN_SUCCESS,
  data
})

export const loginError = error => ({
  type: CONSTANTS.LOGIN_ERROR,
  error
})

/**************************************/

export const logoutRequest = data => ({
  type: CONSTANTS.LOGOUT_REQUEST,
  data
})

export const logoutSuccess = data => ({
  type: CONSTANTS.LOGOUT_SUCCESS,
  data
})

export const logoutError = error => ({
  type: CONSTANTS.LOGOUT_ERROR,
  error
})

/**************************************/

export const resetSessionExpired = () => ({
  type: CONSTANTS.RESET_SESSION_EXPIRED
})
