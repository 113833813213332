import { history } from '../../configureStore';
import * as CONSTANTS from '../constants/reset';

const initialState = {
  passwordSuccessfullyReset: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.RESET_SUCCESS:
      return {
        ...state,
        passwordSuccessfullyReset: true
      }

    case CONSTANTS.RESET_RESET:
      return {
        ...state,
        passwordSuccessfullyReset: false
      }

    default:
      return state
  }
}
