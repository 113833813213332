import { put, takeLatest, call, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { history } from '../../../configureStore'
import { toast } from 'react-toastify'
import request from '../../../apiRequest'
import * as ACTIONS from '../../actions/utilities'
import * as CONSTANTS from '../../constants/utilities'

function createEventChannel(mySocket, data) {
  const object = {
    type: 'login',
    token: data.backendToken
  }
  const stringifiedObject = JSON.stringify(object)

  return eventChannel(emitter => {
    
    mySocket.onopen = () => {
      console.log('opening...')
      mySocket.send(stringifiedObject)
    }

    mySocket.onmessage = e => {
      const message = JSON.parse(e.data) || ''

      if (message) {
        if (message.type === 'login' && message.result === 'OK') {
          console.log('ws login successful')
        }
      }
    }

    return () => {
      mySocket.close()
      console.log('socket off')
    }
  })
}

function* initializeWebsocketConnection(action) {
  try {
    const mySocket = new WebSocket('wss://ec2-3-211-33-254.compute-1.amazonaws.com:8081')
    const { data } = action

    createEventChannel(mySocket, data)

  } catch (err) {
    console.log(err)
  }
}

export default function* utilitiesSaga() {
  yield takeLatest(CONSTANTS.INITIALIZE_WEBSOCKET_CONNECTION, initializeWebsocketConnection)
}
