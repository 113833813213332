import React, { PureComponent } from "react";
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  Spinner,
  Card,
  Image,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import smallLogo from "../../images/Mid-Chains-Logo-Symbol.png";

class ConfirmEmail extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      emailConfirmed: {
        data: {},
        loading: false,
        confirmed: false,
        error: "",
      },
    };
  }

  componentDidMount() {
    this.handleCheckUrlForToken();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        emailConfirmed: nextProps.emailConfirmed,
      });
    }
  }

  handleCheckUrlForToken = () => {
    const { search } = this.props.history.location;

    if (search) {
      const searchBreakdown = search.split("=");
      const tokenParameter = searchBreakdown[0];
      const token = searchBreakdown[1];

      if (tokenParameter === "?token" && token) {
        console.log("test");
        this.props.confirmEmailRequest(token);
      }
    }
  };

  handleResendConfirmationEmail = () =>
    this.props.resendConfirmationEmailRequest();

  renderLoading = () => (
    <Row noGutters className="app-spinner">
      <Spinner animation="border" variant="secondary" />
    </Row>
  );

  renderNormalContent = () => (
    <Container fluid className="login-container">
      <Link to={"/"}>
        <Image src={smallLogo} className="login-small-logo" />
      </Link>

      <Row noGutters className="login-row" style={{ height: "100%" }}>
        <Card className="login-box">
          <Col className="login-box-col">
            <p style={{ textAlign: "center" }}>
              Before you can do anything else, you must confirm your email
              address using the link we emailed you when you completed
              registration.
            </p>

            <p style={{ textAlign: "center" }}>
              Didn't get anything? Please check your spam folder or
              <a
                style={{ color: "#ff4171", marginLeft: "4px" }}
                onClick={this.handleResendConfirmationEmail}
              >
                resend your confirmation email
              </a>
            </p>
          </Col>
        </Card>
      </Row>
    </Container>
  );

  renderErrorContent = () => (
    <Container fluid className="login-container">
      <Link to={"/"}>
        <Image src={smallLogo} className="login-small-logo" />
      </Link>

      <Row noGutters className="login-row" style={{ height: "100%" }}>
        <Card className="login-box">
          <Col className="login-box-col">
            <h1>Your email confirmation link may have expired...</h1>

            <Button
              onClick={this.handleResendConfirmationEmail}
              className="special-button"
              style={{
                width: "100%",
                marginBottom: "64px",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              Click here to resend the confirmation email
            </Button>
          </Col>
        </Card>
      </Row>
    </Container>
  );

  render() {
    const { emailConfirmed } = this.state;

    let content;
    if (emailConfirmed.loading) {
      content = this.renderLoading();
    } else if (emailConfirmed.error) {
      content = this.renderErrorContent();
    } else {
      content = this.renderNormalContent();
    }

    return (
      <Container fluid className="login-container">
        {this.renderNormalContent()}
      </Container>
    );
  }
}

export default ConfirmEmail;
